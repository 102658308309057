import React, {useState, useEffect, useMemo} from 'react';
import {Alert} from 'react-bootstrap';
import './style.scss';
import Api from '../../helpers/axios';
import formatCurrency from "../../helpers/formatCurrency";
import FetchState from "../../components/ui/fetchState";
import userHaveRole from "../../helpers/userHaveRole";
import {Link} from "react-router-dom";

export default () => {
    const PI = 3.14;
    const [error, setError] = useState(null);
    const [fetch, setFetch] = useState(false);
    const [calcProps, setCalcProps] = useState(null);
    
    const [loadOption, setLoadOption] = useState({});
    
    const onLoadOptionHandle = (key, callback) => {
        if (!loadOption[key]) {
            setLoadOption({...loadOption, [key]: true});
            Api.post('/api/option/GetOption', {key: key})
                .then((res) => {
                    if (res.data.body) {
                        callback(res.data.body.value);
                    }
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoadOption({...loadOption, [key]: false});
                });
        }
    };
    
    const isAdmin = userHaveRole("Administrator");
    const isSales = userHaveRole("Sales");

    const access = {
        rollsLength: userHaveRole("Administrator")
    };

    const onLoadCalcPropsHandle = () => {
        if (!fetch) {
            setFetch(true);
            setError(null);

            Api.post('/api/calculator/GetCalcProps', {})
                .then(res => {
                    setCalcProps(res.data.body);
                })
                .catch(e => setError(e.response.data.message))
                .finally(() => {
                    setFetch(false);
                });
        }
    }

    useEffect(() => {
        onLoadCalcPropsHandle();
    }, []);

    const getPropPrice = (key) => {
        let price = 0;

        if (calcProps && calcProps.length > 0) {
            const prop = calcProps.find(x => x.key === key);

            if (prop) {
                price = prop.price - 0;
            }
        }

        return price;
    };

    // Внутренние размеры
    const [innerSize, setInnerSize] = useState({
        length:50,
        width:20,
        height:0
    })

    // Внешние размеры
    const [outSize, setOutSize] = useState({
        length:0,
        width:0,
        height:0
    })

    // Кол-во торцов
    const [torecCount, setTorecCount] = useState(2)

    // Ворота
    const [gates, setGates] = useState(1)

    // Размеры ворот
    const [gateSize, setGateSize] = useState({
        width: 4,
        height: 4
    })

    // Диаметр баллона
    const [diametr, setDiametr] = useState(3)

    // Толщина торца
    const [torec, setTorec] = useState(1.2)

    // Кол-во баллонов
    const [baloons, setBaloons] = useState(0)

    // Кол-во сегментов
    const [segments, setSegments] = useState(0)

    // Общее кол-во сегментов
    const [totalSegments, setTotalSegments] = useState(0)

    // Длина рулона (50 или 58)
    const [rollLength, setRollLength] = useState(58)

    // Кол-во сегментов в одном рулоне
    const [segmentsOnRoll, setSegmentsOnRoll] = useState(0)

    // Необходимое количество рулонов
    const [totalRolls, setTotalRolls] = useState(0)

    // Кол-во полуколец
    const [rings, setRings] = useState(0)

    // Карабины
    const [carabins, setCarabins] = useState({
        din6: 0,
        din7: 0
    })

    // Внешний полог
    const [cover, setCover] = useState(0)

    // Кол-во рулонов для полога
    const [rollsForCover, setRollsForCover] = useState(0)

    // Материал для полога на торцы
    const [frontRolls, setFrontRolls] = useState(0)

    const onInnerSizeHandle = (e) => {
        const {target} = e
        const value = target.value - 0
        const {name} = target
        setInnerSize(prevState => ({...prevState, [name]: value}))
        setError(null)
    }

    const onChangeGateSize = (e) => {
        const {target} = e
        const value = target.value - 0
        const {name} = target
        setGateSize(prevState => ({...prevState, [name]: value}))
    }

    const onChangeTorecCountHandle = (e) => {
        setTorecCount(e.target.value - 0)
    }

    const onChangeRollLength = (e) => {
        setRollLength(e.target.value - 0)
    }

    // Расчет внутренней высоты и диаметра баллона
    useEffect(() => {
        // Расчет внутренней высоты
        setInnerSize(prevState => ({...prevState, height: (innerSize.width / 2)}))

        if (innerSize.width > 36) {
            setError('Внутренняя ширина не должна быть более 36 метров')
        }

    }, [innerSize.width])

    // Расчет торца
    useEffect(() => {
        if (innerSize.width > 20) {
            setTorec(1.2)
        }
    }, [innerSize.width]);

    // Внешние размеры
    useEffect(() => {
        setOutSize(prevState => ({
            ...prevState,
            length: innerSize.length + (diametr * 2),
            width: innerSize.width + (diametr * 2),
            height: innerSize.height + diametr
        }))
    }, [diametr, innerSize])

    // Кол-во балонов
    useEffect(() => {
        setBaloons(Math.ceil(((innerSize.length + diametr + torec) / diametr).toFixed(2)))
    }, [ outSize.length ])

    // Расчет сегментов на баллон
    useEffect(() => {
        let temp_segments = Math.ceil(((((outSize.width / 2) + 0.6) * PI) / 3) + 1)
        temp_segments = temp_segments % 2 === 0 ? temp_segments : temp_segments + 1

        setSegments(temp_segments)
    }, [outSize.width])

    // Общее количество сегментов
    useEffect(() => {
        setTotalSegments(segments * baloons)
    }, [segments, baloons])

    // Расчет кол-ва сегментов в рулоне
    useEffect(() => {
        setSegmentsOnRoll(Math.floor((rollLength / ((diametr * PI) + 0.03)).toFixed(2)))
    }, [totalSegments, rollLength])

    // Необходимое кол-во рулонов
    useEffect(()=> {
        setTotalRolls(Math.ceil(totalSegments / segmentsOnRoll))
    }, [segmentsOnRoll, totalSegments])

    // Площадь ткани для пневмобалонов
    const [baloonsFabric, setBaloonsFabric] = useState(0);
    useEffect(() => {
        setBaloonsFabric(Math.ceil(totalRolls * rollLength * 3).toFixed(2));
    }, [
        totalRolls
    ]);

    // Кол-во полуколец
    useEffect(() => {
        // полукольца для всех балонов
        let x = (totalSegments * 12) + (baloons * 2)

        // полукольца для торцевых балонов
        let y = Math.ceil((((outSize.width - diametr) * 1.57) / 0.4)) * 2

        // полукольца
        setRings(x + y)

        // карабины
        setCarabins({
            din6: y / 2,
            din7: x / 2
        })
    }, [totalSegments])

    const [ringsStropa, setRingsStropa] = useState(0)
    const [stropaCoverFix, setStropaCoverFix] = useState(0)
    const [frogs, setFrogs] = useState(0)

    // Расчеты для полога
    useEffect(() => {
        // квадратура полога
        let s = Math.ceil(((outSize.width * 1.57) + 2.6) * ((outSize.length - diametr) + (diametr * 1.57)))
        setCover(s)

        // сегментов полога на рулон
        let segmetsPologOnRoll = (rollLength / (outSize.length - (diametr * 1.57))).toFixed(2)

        // кол-во сегментов для полога
        let segmentsOnPolog = Math.ceil((((outSize.width * 1.57) + 2 + (diametr * 1.57)) / 3).toFixed(2))

        // Рулоны для полога
        let r = Math.ceil((segmentsOnPolog / segmetsPologOnRoll).toFixed(2))
        setRollsForCover(r)

        // Полукольца для стропы фиксации оболочки
        setRingsStropa((segmentsOnPolog * (baloons - 1)) + (baloons * 2))

        // Стропа для фиксации оболочки
        setStropaCoverFix(Math.ceil((outSize.width * 1.57 * (baloons - 1)).toFixed(2)))

        // Лягушки для фиксации
        setFrogs(Math.ceil((((outSize.width - diametr) * 1.57) / 0.4)) * 2)

    }, [outSize.width, outSize.length, rollLength])

    // Торцевые стенки (рулоны)
    const [ringsTorec, setRingsTorec] = useState(0)
    // Веревка для фиксации шкур торца
    const [rope, setRope] = useState(0)
    // Полукольца для БЛ-ки
    const [ringsBL, setRingsBL] = useState(0)

    // <--- Расчет для торцов
    useEffect(() => {
        let s = Math.ceil((((innerSize.width * innerSize.height) * 5) / (rollLength * 3)).toFixed(2) / 2)
        setFrontRolls(s * torecCount)

        let r = Math.ceil((((innerSize.width - torec) / 0.4).toFixed(2)) * (innerSize.height - torec)) * 2
        setRingsTorec(r * torecCount)

        setRope(Math.ceil((r / 2).toFixed(2)) * torecCount)

        setRingsBL(Math.ceil((segments * 12).toFixed(2)) * torecCount)

    }, [innerSize.width, innerSize.height, rollLength, torecCount])
    // Расчет для торцов --->

    // Мешки (внутренние)
    const [bag, setBag] = useState(0)

    useEffect(()=>{
        setBag((baloons - 1) * 2)
    },[baloons])

    // Кол-во материала на мешки (внутренние)
    const [bagMaterial, setBagMaterial] = useState(0)
    // Кол-во зубъев (внутренние)
    const [tooth, setTooth] = useState(0)

    useEffect(()=>{
        setBagMaterial(Math.ceil((bag * 6 / (rollLength * 3)).toFixed(2)))
        setTooth(bag * 8)
    },[bag])


    // Мешки (внешние)
    const [bagOut, setBagOut] = useState(0)

    useEffect(()=>{
        setBagOut((baloons - 1) * 2)
    },[baloons])

    // Кол-во материала на мешки (внешние)
    const [bagOutMaterial, setBagOutMaterial] = useState(0)

    useEffect(()=>{
        setBagOutMaterial(Math.ceil((bagOut * 6 / (rollLength * 3)).toFixed(2)))
    },[bagOut])

    
    
    const [strop, setStrop] = useState(4.67)

    // <-- Gates
    const [traversa, setTraversa] = useState(0)

    useEffect(() => {
        setTraversa(gateSize.width)
    }, [gates, gateSize.width])
    // Gates -->

    // <-- Doors
    const [doors, setDoors] = useState(1)
    const [doorsKarcas, setDoorsKarcas] = useState(0)

    useEffect(() => {
        setDoorsKarcas(doors * 8)
    }, [doors])
    // Doors -->

    // <-- Воздуховод
    const [ductMain, setDustMain] = useState(0)
    const [ductMontage, setDuctMontage] = useState(0)

    useEffect(() => {
        setDustMain(Math.ceil((0.3 * PI * outSize.length).toFixed(2)))
        setDuctMontage(Math.ceil((0.3 * PI * 7).toFixed(2)))
    }, [outSize.length])
    // Воздуховод -->
    
    // Надбавка
    const [addedValue, setAddedValue] = useState(100);

    /* Квадратура */
    const [area, setArea] = useState(0);

    useEffect(() => {
        setArea(Math.ceil(innerSize.length * innerSize.width).toFixed(2));
    }, [innerSize.length, innerSize.width]);
    
    // Коэффициенты
    const [s100, setS100] = useState(0);
    const [s200, setS200] = useState(0);
    const [s300, setS300] = useState(0);
    const [s400, setS400] = useState(0);
    const [s500, setS500] = useState(0);
    const [s600, setS600] = useState(0);
    const [s700, setS700] = useState(0);
    const [s800, setS800] = useState(0);
    const [s900, setS900] = useState(0);
    const [s1000, setS1000] = useState(0);
    const [s1100, setS1100] = useState(0);
    const [s1200, setS1200] = useState(0);
    const [s1300, setS1300] = useState(0);
    const [s1400, setS1400] = useState(0);
    const [s1500, setS1500] = useState(0);

    /* Итоговая стоимость */
    const [price, setPrice] = useState({
        hangar: 0,
        hangarTotalPrice: 0
    });

    useEffect(() => {
        onLoadOptionHandle('s100', setS100);
        onLoadOptionHandle('s200', setS200);
        onLoadOptionHandle('s300', setS300);
        onLoadOptionHandle('s400', setS400);
        onLoadOptionHandle('s500', setS500);
        onLoadOptionHandle('s600', setS600);
        onLoadOptionHandle('s700', setS700);
        onLoadOptionHandle('s800', setS800);
        onLoadOptionHandle('s900', setS900);
        onLoadOptionHandle('s1000', setS1000);
        onLoadOptionHandle('s1100', setS1100);
        onLoadOptionHandle('s1200', setS1200);
        onLoadOptionHandle('s1300', setS1300);
        onLoadOptionHandle('s1400', setS1400);
        onLoadOptionHandle('s1500', setS1500);
    }, []);
    
    // итоговые размеры для отображения
    const [printSize, setPrintSize] = useState({
        innerSize: {
            length: 0,
            width: 0,
            height: 0
        },
        outSize: {
            length: 0,
            width: 0,
            height: 0
        }
    });
    
    useMemo(() => {
        
        const innerLength = (baloons * diametr) - diametr - torec;
        const outLength = innerLength + torec + diametr;
        
        setPrintSize({...printSize,
            innerSize: {
                ...printSize.innerSize,
                length: innerLength,
                width: innerSize.width,
                height: innerSize.height
            },
            outSize: {
                ...printSize.outSize,
                length: outLength,
                width: outSize.width,
                height: outSize.height
            }
        });
        
        setArea(Math.floor((innerLength * innerSize.width).toFixed(2)));
        
    }, [
        innerSize,
        outSize,
        baloons,
        diametr,
        torec
    ]);
    
    // Освещение
    const [lights, setLights] = useState(0);
    useMemo(() => {
        setLights(Math.floor(((baloons - 2) * 2).toFixed(2)));
    }, [
        baloons
    ]);

    // Внутренний объем ангара
    const [volume, setVolume] = useState(0);
    useMemo(() => {
        setVolume(((innerSize.width / 2) * (innerSize.width / 2) * PI * printSize.innerSize.length).toFixed(2));
    }, [innerSize.width, printSize.innerSize.length]);

    // Ветиляция
    const [air, setAir] = useState({
        in: 0,
        out: 0
    });
    
    useMemo(()=>{
        const x = Math.ceil(volume / 2000);
        setAir({...air, in: x, out: x});
    }, [
        volume
    ]);
    
    const [showLights, setShowLights] = useState(false);
    const onChangeShowLightHandle = () => setShowLights(!showLights);

    const [showAir, setShowAir] = useState(false);
    const onChangeShowAirHandle = () => setShowAir(!showAir);


    // Расчет стоимости ангара
    useMemo(() => {
        const fabricPrice = getPropPrice('fabric');
        const ringsPrice = getPropPrice('rings');
        const du114Price = getPropPrice('du_114');
        const du75Price = getPropPrice('du_75');
        const ventOutPrice = getPropPrice('vent_out');
        const din6Price = getPropPrice('din6');
        const din7Price = getPropPrice('din7');
        const frogsPrice = getPropPrice('frogs');
        const ropePrice = getPropPrice('rope');
        const vent_reducionPrice = getPropPrice('vent_reducion');
        const stropaPrice = getPropPrice('stropa');
        const doorPrice = getPropPrice('door');
        
        // TODO сделать расчет по квадратуре
        const gatePrice = 40000; //getPropPrice('gate');

        let tempPrice = 0;
        let totalPrice = 0;

        /* Пневмобаллоны */
        // Цена ткани на пневмобаллоны
        tempPrice = tempPrice + baloonsFabric * fabricPrice;
        // Полукольца на пневмобаллоны
        tempPrice = tempPrice + (rings * ringsPrice);
        // Клапан ДУ114 на пневмобаллонах
        tempPrice = tempPrice + (baloons * du114Price);
        // Клапан лодочный (стравливающий) на пневмобаллонах
        tempPrice = tempPrice + (baloons * ventOutPrice);
        // Карабины Din6 на пневмобаллонах
        tempPrice = tempPrice + (carabins.din6 * din6Price);
        // Карабины Din7 на пневмобаллонах
        tempPrice = tempPrice + (carabins.din7 * din7Price);

        /* Полог */
        // Полог (Ткань)
        tempPrice = tempPrice + (cover * fabricPrice);
        // Полукольца 
        tempPrice = tempPrice + (ringsStropa * ringsPrice);
        // Стропа
        tempPrice = tempPrice + (stropaCoverFix * stropaPrice);
        // Лягушки 
        tempPrice = tempPrice + (frogs * frogsPrice);

        /* Торец */
        tempPrice = tempPrice + (frontRolls * rollLength * 3 * fabricPrice);
        // Полукольца для торца
        tempPrice = tempPrice + (ringsTorec * ringsPrice);
        // Веревка для фиксации шкур
        tempPrice = tempPrice + ((rope * torec) * ropePrice);
        // Торец Карабин Din6
        tempPrice = tempPrice + (rope * din6Price);
        // Полукольца для БЛ-ки
        tempPrice = tempPrice + (ringsBL * ringsPrice);
        // Торец Клапан ДУ114
        tempPrice = tempPrice + (torecCount * du114Price);
        // Торец Клапан редукционный (стравливающий)
        tempPrice = tempPrice + (torecCount + vent_reducionPrice);
        // Торец Клапан ДУ75
        tempPrice = tempPrice + ((torecCount * gates) * du75Price);

        /* Ворота */
        tempPrice = tempPrice + (gatePrice * gates);

        /* Двери */
        tempPrice = tempPrice + (doorPrice * doors);

        /* Воздуховод (основной) */
        // Клапан ДУ114: 1 шт.
        tempPrice = tempPrice + du114Price;

        /* Воздуховод (монтажный) */

        /* Баластные мешки (внутренние) */
        // Ткань
        tempPrice = tempPrice + ((bagMaterial * rollLength * 3) * fabricPrice);
        // Зубья

        // Полукольца
        tempPrice = tempPrice + (bag * ringsPrice);

        /* Баластные мешки (наружные) */
        // Ткань
        tempPrice = tempPrice + ((bagOutMaterial * rollLength * 3) * fabricPrice);
        // Полукольца
        tempPrice = tempPrice + ((bagOut * 4) * ringsPrice);
        // Стропа
        tempPrice = tempPrice + ((bagOut * strop) * stropaPrice);

        let percent = 100;

        if (area > 0 && area <= 100) {
            percent = s100; setAddedValue(s100);
        }
        if (area > 100 && area <= 200) {
            percent = s200; setAddedValue(s200);
        }
        if (area > 200 && area <= 300) {
            percent = s300; setAddedValue(s300);
        }
        if (area > 300 && area <= 400) {
            percent = s400; setAddedValue(s400);
        }
        if (area > 400 && area <= 500) {
            percent = s500; setAddedValue(s500);
        }
        if (area > 500 && area <= 600) {
            percent = s600; setAddedValue(s600);
        }
        if (area > 600 && area <= 700) {
            percent = s700; setAddedValue(s700);
        }
        if (area > 700 && area <= 800) {
            percent = s800; setAddedValue(s800);
        }
        if (area > 800 && area <= 900) {
            percent = s900; setAddedValue(s900);
        }
        if (area > 900 && area <= 1000) {
            percent = s1000; setAddedValue(s1000);
        }
        if (area > 1000 && area <= 1100) {
            percent = s1100; setAddedValue(s1100);
        }
        if (area > 1100 && area <= 1200) {
            percent = s1200; setAddedValue(s1200);
        }
        if (area > 1200 && area <= 1300) {
            percent = s1300; setAddedValue(s1300);
        }
        if (area > 1300 && area <= 1400) {
            percent = s1400; setAddedValue(s1400);
        }
        if (area > 1400) {
            percent = s1500; setAddedValue(s1500);
        }

        // Итоговая стоимость:
        totalPrice = Math.floor((tempPrice * percent) / 100);
        
        if (showLights) {
            totalPrice = totalPrice + (lights * 5000);
        }
        if (showAir) {
            totalPrice = totalPrice + (air.in * 2 * 48000);
        }

        setPrice({...price,
            hangar: tempPrice,
            hangarTotalPrice: totalPrice
        });

    }, [
        calcProps,
        baloonsFabric,
        rings,
        baloons,
        carabins,
        cover,
        ringsStropa,
        frogs,
        frontRolls,
        rollLength,
        ringsTorec,
        rope,
        torec,
        ringsBL,
        torecCount,
        gates,
        doors,
        bagMaterial,
        bagOutMaterial,
        bagOut,
        stropaCoverFix,
        strop,
        area,
        addedValue,
        s100,
        s200,
        s300,
        s400,
        s500,
        s600,
        s700,
        s800,
        s900,
        s1000,
        s1100,
        s1200,
        s1300,
        s1400,
        s1500,
        air,
        lights,
        showLights,
        showAir
    ]);
    
    return (
        <div className="container calc">
            <div className="row">
                <div className="col-12">
                    <h2>Калькулятор ангара</h2>

                    {
                        fetch && <FetchState loading={fetch} size="sm" label="Загрузка данных" variant="primary" />
                    }

                    <div className="d-lg-flex w-100 mt-4">
                        <div className="calc-main w-100 ms-0 me-md-4">
                            <h5>Параметры ангара</h5>
                            <div className="d-md-flex w-100 mt-4">
                                <div className="mb-3 me-0 me-md-2">
                                    <label htmlFor="innerLength" className="form-label">Длина (м.):</label>
                                    <input
                                        className="form-control"
                                        id="innerLength"
                                        name="length"
                                        value={innerSize.length}
                                        onChange={onInnerSizeHandle}
                                        type="number"/>
                                </div>
                                <div className="mb-3 me-0 me-md-2">
                                    <label htmlFor="innerWidth" className="form-label">Ширина (м.):</label>
                                    <input
                                        className="form-control"
                                        id="innerWidth"
                                        name="width"
                                        value={innerSize.width}
                                        onChange={onInnerSizeHandle}
                                        type="number"/>
                                </div>
                                <div className="mb-3 me-0 me-md-2">
                                    <label htmlFor="innerHeight" className="form-label">Высота (м.):</label>
                                    <input
                                        className="form-control"
                                        id="innerHeight"
                                        name="height"
                                        value={innerSize.height}
                                        onChange={onInnerSizeHandle}
                                        type="number"/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="torecCount" className="form-label">Количество торцов (шт.):</label>
                                <input
                                    className="form-control"
                                    id="torecCount"
                                    name="torecCount"
                                    value={torecCount}
                                    onChange={onChangeTorecCountHandle}
                                    type="number" />
                            </div>
                            
                            {
                                access.rollsLength &&
                                <div className="mb-3 col-12 col-md-2">
                                    <label htmlFor="dlina_rulona" className="form-label">Длина рулона (м.):</label>
                                    <select
                                        onChange={onChangeRollLength}
                                        value={rollLength}
                                        name="dlina_rulona"
                                        id="dlina_rulona"
                                        className="form-select">
                                        <option value={50}>50</option>
                                        <option value={58}>58</option>
                                    </select>
                                </div>
                            }

                            <br/>
                            <h5>Ворота</h5>
                            <div className="mb-3">
                                <label htmlFor="gates" className="form-label">Количество (шт.):</label>
                                <input
                                    className="form-control"
                                    id="gates"
                                    name="gates"
                                    value={gates}
                                    onChange={e => setGates(e.target.value - 0)}
                                    type="number" />
                            </div>
                            <div className="d-md-flex w-100 mt-4">
                                <div className="mb-3 me-0 me-md-2">
                                    <label htmlFor="gateSizeWidth" className="form-label">Ширина (м.):</label>
                                    <input
                                        className="form-control"
                                        id="gateSizeWidth"
                                        name="width"
                                        value={gateSize.width}
                                        onChange={onChangeGateSize}
                                        type="number"/>
                                </div>
                                <div className="mb-3 me-0 me-md-2">
                                    <label htmlFor="gateSizeHeight" className="form-label">Высота (м.):</label>
                                    <input
                                        className="form-control"
                                        id="gateSizeHeight"
                                        name="height"
                                        value={gateSize.height}
                                        onChange={onChangeGateSize}
                                        type="number"/>
                                </div>
                            </div>

                            <br/>
                            <h5>Двери</h5>
                            <div className="mb-3">
                                <label htmlFor="doors" className="form-label">Количество (шт.):</label>
                                <input
                                    className="form-control"
                                    id="doors"
                                    name="doors"
                                    value={doors}
                                    onChange={e => setDoors(e.target.value - 0)}
                                    type="number" />
                            </div>
                            
                            <hr/>
                            <div className="mb-3">
                                <div className="form-check">
                                    <input type="checkbox"
                                           className="form-check-input"
                                           id="exampleCheck1"
                                           name="rememberMe"
                                           onChange={onChangeShowLightHandle}
                                           checked={showLights}
                                    />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Расчитывать освещение</label>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="form-check">
                                    <input type="checkbox"
                                           className="form-check-input"
                                           id="exampleCheck2"
                                           name="rememberMe"
                                           onChange={onChangeShowAirHandle}
                                           checked={showAir}
                                    />
                                    <label className="form-check-label" htmlFor="exampleCheck2">Расчитывать вентиляцию</label>
                                </div>
                            </div>

                        </div>
                        <div className="calc-summary mb-3 ms-lg-auto">


                            {
                                (isAdmin || isSales) &&
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5>Расчетная стоимость:</h5>

                                        {
                                            loadOption.addedPrice ?
                                                <FetchState loading={loadOption.addedPrice} label="Расчет цены" />
                                                :
                                                <div>
                                                    {
                                                        isAdmin &&
                                                        <div className="mb-3">
                                                            <div>
                                                                Себестоимость: {formatCurrency(price.hangar)}
                                                            </div>
                                                            <div>
                                                                Надбавка: {`${addedValue}%`} = {formatCurrency(price.hangarTotalPrice)}
                                                            </div>
                                                            <div className="mt-1 text-right small">
                                                                <Link to='/admin/options'>Изменить</Link>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                    }

                                                    Цена: {formatCurrency(price.hangar + price.hangarTotalPrice)}
                                                </div>
                                        }
                                    </div>
                                </div>
                            }

                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5>Расчетные параметры:</h5>
                                    {
                                        error &&
                                        <Alert variant="danger">{error}</Alert>
                                    }

                                    <div className="mb-3">
                                        <div>
                                            Внешние размеры:<br/>
                                            {`${printSize.outSize.length} x ${printSize.outSize.width} x ${printSize.outSize.height}`}
                                        </div>
                                        
                                        <div>
                                            Укрываемая площадь: {printSize.outSize.length * printSize.outSize.width} м<sup>2</sup>
                                        </div>
                                        
                                        <hr/>
                                        
                                        <div>
                                            Внутренние размеры:<br/>
                                            {`${printSize.innerSize.length} x ${printSize.innerSize.width} x ${printSize.innerSize.height}`}
                                        </div>
                                        
                                        <div>
                                            Полезная площадь: {area} м<sup>2</sup>
                                        </div>

                                        <div>
                                            Внутренний объем ангара: { volume } м<sup>3</sup>
                                        </div>
                                        
                                        <hr/>
                                        
                                        <div>
                                            Кол-во баллонов: {baloons} шт
                                        </div>
                                        
                                        {
                                            (isAdmin || isSales) &&
                                                <div>
                                                    <div>
                                                        Диаметр баллона: {diametr} м.
                                                    </div>
                                                    <div>
                                                        Кол-во ворот: {gates} шт.
                                                    </div>
                                                    <div>
                                                        Кол-во дверей: {doors} шт.
                                                    </div>
                                                    <div>
                                                        Толщина торца: {torec} м.
                                                    </div>

                                                    <hr/>
                                                    
                                                    {
                                                        showLights &&
                                                        <div>
                                                            Освещение: {lights} шт.
                                                        </div>
                                                    }
                                                    
                                                    {
                                                        showAir &&
                                                        <>
                                                            <div>
                                                                Вентиляция приточная: {air.in} шт.
                                                            </div>
                                                            <div>
                                                                Вентиляция вытяжная: {air.out} шт.
                                                            </div>
                                                        </>  
                                                    }
                                                    
                                                    
                                                </div>
                                        }
                                        
                                        
                                    </div>

                                    {
                                        !isSales &&
                                        <div>
                                            <details className="mb-3">
                                                <summary>Пневмобаллоны:</summary>
                                                <div>
                                                    Диаметр баллона: {diametr} м.
                                                </div>
                                                <div>
                                                    Количество баллонов: {baloons} шт.
                                                </div>
                                                <div>
                                                    Сегментов на баллон: {segments} шт.
                                                </div>
                                                <div>
                                                    Общее кол-во сегментов: {totalSegments} шт.
                                                </div>
                                                <div>
                                                    Сегментов в рулоне: {segmentsOnRoll} шт.
                                                </div>
                                                <div>
                                                    Необходимое кол-во рулонов: {totalRolls} шт.
                                                </div>
                                                <div>
                                                    Площадь ткани: {baloonsFabric} м<sup>2</sup>
                                                </div>
                                                <div>
                                                    Полукольца: {rings} шт.
                                                </div>
                                                <div>
                                                    Клапан ДУ114: {baloons} шт.
                                                </div>
                                                <div>
                                                    Клапан лодочный (стравливающий): {baloons} шт.
                                                </div>
                                                <div>
                                                    Карабин Din6: {carabins.din6} шт.
                                                </div>
                                                <div>
                                                    Карабин Din7: {carabins.din7} шт.
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Полог:</summary>
                                                <div>
                                                    Полог: {cover} м<sup>2</sup>
                                                </div>
                                                <div>
                                                    Кол-во рулонов для полога: {rollsForCover} шт.
                                                </div>
                                                <div>
                                                    Полукольца фиксации оболочки: {ringsStropa} шт.
                                                </div>
                                                <div>
                                                    Стропа для фиксации оболочки: {stropaCoverFix} м.
                                                </div>
                                                <div>
                                                    Лягушки для фиксации: {frogs} шт.
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Торец:</summary>
                                                <div>
                                                    Руллонов для торца: {frontRolls} шт.
                                                </div>
                                                <div>
                                                    Полукольца для торца: {ringsTorec} шт.
                                                </div>
                                                <div>
                                                    Веревка для фиксации шкур: {rope} шт.
                                                    ({torec * rope }&nbsp;м.)
                                                </div>
                                                <div>
                                                    Карабин Din6: {rope} шт.
                                                </div>
                                                <div>
                                                    Полукольца для БЛ-ки: {ringsBL} шт.
                                                </div>
                                                <div>
                                                    Клапан ДУ114: {torecCount} шт.
                                                </div>
                                                <div>
                                                    Клапан редукционный (стравливающий): {torecCount} шт.
                                                </div>
                                                <div>
                                                    Клапан ДУ75: {torecCount * gates} шт.
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Ворота:</summary>
                                                <div>
                                                    Ворота: {gates} шт.
                                                </div>
                                                <div>
                                                    Дельфер: {gates} шт.
                                                </div>
                                                <div>
                                                    Траверса: {traversa} м. ({gates} шт.)
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Двери:</summary>
                                                <div>Количество: {doors} шт.</div>
                                                <div>
                                                    Каркас: {doorsKarcas} м.
                                                </div>
                                                <div>
                                                    Саморез (кровельный): {100 * doors} шт.
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Воздуховод (основной):</summary>
                                                <div>
                                                    Магистраль: {ductMain} м.
                                                </div>
                                                <div>
                                                    Клапан ДУ114: 1 шт.
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Воздуховод (Монтажный):</summary>
                                                <div>
                                                    Магистраль: {ductMontage} м.
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Баластные мешки (внутренние):</summary>
                                                <div>
                                                    Кол-во мешков: {bag} шт.
                                                </div>
                                                <div>
                                                    Кол-во рулонов: {bagMaterial} шт.
                                                </div>
                                                <div>
                                                    Кол-во зубьев: {tooth} шт.
                                                </div>
                                                <div>
                                                    Полукольца: {bag} шт.
                                                </div>
                                            </details>

                                            <details className="mb-3">
                                                <summary>Баластные мешки (наружные):</summary>
                                                <div>
                                                    Кол-во мешков: {bagOut} шт.
                                                </div>
                                                <div>
                                                    Кол-во рулонов: {bagOutMaterial} шт.
                                                </div>
                                                <div>
                                                    Полукольца: {bagOut * 4} шт.
                                                </div>
                                                <div>
                                                    Стропа: {strop} м. (Всего: {bagOut * strop} м.)
                                                </div>
                                            </details>
                                        </div>
                                    }

                                </div>
                            </div>

                            {
                                isAdmin &&
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5>
                                            Требуемый материал:
                                        </h5>
                                        <div>
                                            Ткань: {totalRolls + rollsForCover + frontRolls + bagMaterial + bagOutMaterial}  рулонов (шт.)
                                        </div>
                                        <div>
                                            Полукольца: {rings + ringsTorec + ringsBL + bag + (bagOut * 4)} шт.
                                        </div>
                                        <div>
                                            Карабин Din6: {carabins.din6 + rope} шт.
                                        </div>
                                        <div>
                                            Карабин Din7: {carabins.din7} шт.
                                        </div>
                                        <div>
                                            Клапан ДУ114: {baloons + torecCount + 1} шт.
                                        </div>
                                        <div>
                                            Клапан лодочный (стравливающий): {baloons} шт.
                                        </div>
                                        <div>
                                            Клапан редукционный (стравливающий): {torecCount} шт.
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}