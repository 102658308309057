import React, {useEffect, useState, useMemo} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {addToStockAction} from '../../store/stocks/actions';
import Api from '../../helpers/axios';
import {Spinner, Alert, Modal} from "react-bootstrap";
import Autocomplete from "../../components/autocomplete";
import ItemsTable from '../../components/stockItemsTable';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import PhoneInput from "react-phone-number-input/input";
import {amountTypes, getZaAmountType} from '../../helpers/amountTypes';
import CurrencyInput from "react-currency-input-field";
import CreateCraftItemComponent from "../../components/createCraftItemBtn";
import roles from "../../helpers/roles";
import ExportStockData from "../../components/exportStockData";

export default () => {
    
    const isAdmin = roles.is(roles.administrator);
    const isStorekeeper = roles.is(roles.storekeeper);
    const isPurchaseDepartment = roles.is(roles.purchaseDepartment);

    const canAddNewPosition = isPurchaseDepartment
        || isStorekeeper
        || isAdmin;

    const canEnterAmount = roles.is(roles.storekeeper) || isAdmin;

    const canEnterPrice = roles.is(roles.purchaseDepartment) || isAdmin;

    const {id} = useParams();
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState({
        stock: false,
        addItem: false
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [errorCreate, setErrorCreate] = useState('');
    const [stock, setStock] = useState(null);
    const [isNewItem, setIsNewItem] = useState(false);
    const [isNewSupplier, setIsNewSupplier] = useState(false);

    const getStock = () => {
        if (!fetching.stock) {
            setFetching(prevState => ({...prevState, stock: true}));
            setError(null);

            Api.post('/api/stocks/get', {id: id})
                .then(res => {
                    setStock(res.data.body);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setFetching(prevState => ({...prevState, stock: false}));
                });
        }
    }

    useEffect(() => {
        getStock();
    }, []);

    const initialCreateState = {
        stockId: '',
        amount: 0,
        amountType: 0,
        price: 0,
        minAmount: 0,
        item: {
            id: '',
            name: '',
            brand: ''
        },
        supplier: {
            id: '',
            name: '',
            brand: '',
            address: '',
            url: '',
            note: '',
            manager: '',
            phone: ''
        }
    };

    const [showAddModal, setShowAddModal] = useState(false);
    const [createData, setCreateData] = useState(initialCreateState);

    const ShowAddModalHandle = (stockId) => {
        setCreateData(prevState => ({...prevState, stockId: stockId}));
        setShowAddModal(true);
    }
    const CloseAddModalHandle = () => {
        setCreateData(initialCreateState);
        setShowAddModal(false);
        setErrorCreate('');
    }

    const updateItemName = (data) => {
        const price = data.body.price || 0;

        setCreateData(prevState => ({...prevState,
            price: price,
            item: {
                ...prevState.item,
                id: data.id,
                name: data.label}
        }));
    };

    // Обработка полей формы для поставщика
    const onChangeSupplierHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setCreateData(prevState => ({ ...prevState, supplier: {...prevState.supplier, [name]: value} }));
    }

    // Change phoneNumber
    const onChangeSupplierPhone = (value) => {
        setCreateData(prevState => ({ ...prevState, supplier: {...prevState.supplier, phone: value || ''} }));
    };

    // Get data from supplier autocomplete
    const updateSupplier = (selectedSupplier) => {
        setCreateData(prevState => ({...prevState,
            supplier: {...prevState.supplier,
                id: selectedSupplier.id,
                name: selectedSupplier.label} }));
    }

    // Get data from brand autocomplete
    const updateItemBrand = (inputBrand) => {
        setCreateData(prevState => ({...prevState,
            item: {
            ...prevState.item,
                brand: inputBrand.label
            }
        }))
    }

    useMemo(()=>{
        if (createData.supplier.name !== '') {
            setIsNewSupplier(createData.supplier.id === '');
        }
        else {
            setIsNewSupplier(false);
        }
    }, [createData.supplier]);

    useMemo(() => {
        if (createData.item.name !== '') {
            setIsNewItem(createData.item.id === '');
        }
        else {
            setIsNewItem(false);
        }
    }, [createData.item]);

    // Обработка полей формы
    const onChangeHandle = (e) => {
        const { target } = e
        let value = target.type === 'checkbox' ? target.checked : target.value
        const { name } = target

        if (name === 'amountType') {
            value = value - 0
        }

        setCreateData(prevState => ({ ...prevState, [name]: value }))
    }
    
    const onChangePriceHandle = (val) => {
        if (!val) {
            val = 0
        }
        setCreateData(prevState => ({...prevState, price: val}))
    }

    const AutocompleteItemTemplate = (item) => {
        return (<>
            {
                item.name
            }
            {
                item.supplier && <div className="mt-1 text-muted">Поставщик: {item.supplier.name}</div>
            }
        </>)
    }

    const validateCreate = () => {
        if (createData.stockId === '') {
            setErrorCreate('Не указан ИД склада, попробуйте обновить страницу.');
            return false;
        }

        if (createData.item.name === '') {
            setErrorCreate('Наименование позиции не может быть пустым.');
            return false;
        }

        if (createData.amount === '' || createData.amount < 0) {
            setErrorCreate('Укажите корректное значение количества позиции.');
            return false;
        }

        if (createData.minAmount === '' || createData.minAmount < 0) {
            setErrorCreate('Минимальное количество позиции должно быть 0 или больше.');
            return false;
        }

        if (createData.price === '' || createData.price < 0) {
            setErrorCreate('Укажите корректную цену за позицию.');
            return false;
        }

        if (createData.supplier.phone) {
            if (!isPossiblePhoneNumber(createData.supplier.phone)) {
                setErrorCreate('Укажите валидный номер телефона.');
                return false;
            }
        }

        return true;
    }
    
    // call api
    const createItemHandle = () => {
        if (validateCreate() && !fetching.addItem) {
            setFetching(prevState => ({...prevState, addItem: true}));
            setErrorCreate(null);

            // Call api
            Api.post('/api/stocks/addItem', createData)
                .then(() => {
                    setSuccess('Позиция добавлена');

                    setCreateData(initialCreateState);
                    setCreateData(prevState => ({...prevState, stockId: stock.id}))

                    dispatch(addToStockAction(true));

                    setTimeout(() => {
                        setSuccess(null);
                    }, 5000);
                })
                .catch(err => {
                    setErrorCreate(err.response.data.message);
                })
                .finally(() => {
                    setFetching(prevState => ({...prevState, addItem: false}));
                });

        }
    }

    return (
        <>
            <div className="container">
                {
                    error && <Alert variant="danger">{error}</Alert>
                }

                {(!error && stock) &&
                    <>
                        <div className="card">
                            <div className="card-body">
                                {
                                    (stock && !fetching.stock) ?
                                        <div className="text-center text-md-start">
                                            <h2>
                                                {stock.name}
                                            </h2>
                                            {stock.address}
                                            {
                                                stock.note && <div className="text-muted mt-1">{stock.note}</div>
                                            }
                                        </div>
                                        : <Spinner animation="border" variant="warning"/>
                                }
                            </div>
                        </div>

                        {
                            (!fetching.stock && canAddNewPosition) &&
                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="d-flex w-100 align-items-center">
                                        <div>
                                            <button className="btn btn-primary mt-3 mt-md-0" onClick={() => ShowAddModalHandle(stock.id)}>+ Новая позиция</button>
                                            <Modal show={showAddModal} onHide={CloseAddModalHandle}>
                                                <Modal.Body>
                                                    <form onSubmit={e => { e.preventDefault() }}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5 className="mb-4">
                                                                    Новая позиция
                                                                </h5>

                                                                <div className="form-group">
                                                                    <label>*Наименование:</label>
                                                                    <Autocomplete
                                                                        value={createData.item.name}
                                                                        callback={updateItemName}
                                                                        extended={true}
                                                                        template={AutocompleteItemTemplate}
                                                                        endpoint="/api/suggestions/ItemsByName"
                                                                        inputClassName="form-control"/>
                                                                </div>

                                                                {
                                                                    isNewItem &&
                                                                    <>
                                                                        <div className="form-group">
                                                                            <label>Бренд:</label>
                                                                            <Autocomplete
                                                                                value={createData.item.brand}
                                                                                callback={updateItemBrand}
                                                                                endpoint="/api/suggestions/ItemsBrands"
                                                                                inputClassName="form-control"/>
                                                                            <div className="form-text text-muted">
                                                                                Название компании изготовителя
                                                                            </div>
                                                                        </div>

                                                                        <div className="pt-3">
                                                                            <h5 className="mb-4">
                                                                                Поставщик позиции
                                                                            </h5>
                                                                            <div className="form-group">
                                                                                <label>Название:</label>
                                                                                <Autocomplete
                                                                                    value={createData.supplier.name}
                                                                                    callback={updateSupplier}
                                                                                    endpoint="/api/suggestions/suppliers"
                                                                                    inputClassName="form-control"/>
                                                                            </div>
                                                                            {
                                                                                isNewSupplier &&
                                                                                <>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="supplier_brand">Бренд:</label>
                                                                                        <input type="text"
                                                                                               id="supplier_brand"
                                                                                               className="form-control"
                                                                                               name="brand"
                                                                                               value={createData.supplier.brand}
                                                                                               onChange={onChangeSupplierHandle} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="supplier_manager">Контактное лицо:</label>
                                                                                        <input type="text"
                                                                                               id="supplier_manager"
                                                                                               className="form-control"
                                                                                               name="manager"
                                                                                               value={createData.supplier.manager}
                                                                                               onChange={onChangeSupplierHandle} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="supplier_phone">Телефон:</label>
                                                                                        <PhoneInput type="text"
                                                                                                    international={true}
                                                                                                    withCountryCallingCode={true}
                                                                                                    defaultCountry="RU"
                                                                                                    id="supplier_phone"
                                                                                                    className="form-control"
                                                                                                    name="phone"
                                                                                                    value={createData.supplier.phone}
                                                                                                    onChange={onChangeSupplierPhone}
                                                                                                    placeholder="+7 ___ __ __"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="supplier_address">Адрес:</label>
                                                                                        <textarea
                                                                                            id="supplier_address"
                                                                                            className="form-control"
                                                                                            name="address"
                                                                                            value={createData.supplier.address}
                                                                                            onChange={onChangeSupplierHandle} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="supplier_url">Сайт:</label>
                                                                                        <input type="text"
                                                                                               id="supplier_url"
                                                                                               className="form-control"
                                                                                               name="url"
                                                                                               value={createData.supplier.url}
                                                                                               onChange={onChangeSupplierHandle} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="supplier_note">Дополнительная информация по поставщику:</label>
                                                                                        <textarea
                                                                                            id="supplier_note"
                                                                                            className="form-control"
                                                                                            name="note"
                                                                                            value={createData.supplier.note}
                                                                                            onChange={onChangeSupplierHandle} />
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>

                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="card mt-3">
                                                            <div className="card-body">
                                                                <h5 className="mb-4">
                                                                    Параметры позиции на складе
                                                                </h5>

                                                                {
                                                                    canEnterAmount &&
                                                                    <>
                                                                        <div className="d-md-flex">
                                                                            <div className="form-group">
                                                                                <label htmlFor="amount">*Остаток:</label>
                                                                                <input
                                                                                    id="amount"
                                                                                    type="number"
                                                                                    name="amount"
                                                                                    className="form-control"
                                                                                    value={createData.amount}
                                                                                    onChange={onChangeHandle}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group ml-0 ml-md-2">
                                                                                <label htmlFor="amountType">Ед. измерения:</label>
                                                                                <select name="amountType"
                                                                                        className="form-control"
                                                                                        id="amountType"
                                                                                        value={createData.amountType}
                                                                                        onChange={onChangeHandle}>
                                                                                    {
                                                                                        amountTypes.map(type =>
                                                                                            <option key={type.key} value={type.key}>{type.label} ({type.short})</option>)
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="minAmount">*Минимальное количество:</label>
                                                                            <input
                                                                                id="minAmount"
                                                                                type="number"
                                                                                name="minAmount"
                                                                                className="form-control"
                                                                                value={createData.minAmount}
                                                                                onChange={onChangeHandle}
                                                                            />
                                                                            <div className="form-text small text-muted">
                                                                                Количество позиции, которое всегда должно быть на складе.<br/>
                                                                                По умолчанию: 0
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }

                                                                {
                                                                    canEnterPrice &&
                                                                    <div className="form-group">
                                                                        <label htmlFor="price">*Цена за {getZaAmountType(createData.amountType)}:</label>
                                                                        <CurrencyInput
                                                                            id="price"
                                                                            className="form-control"
                                                                            name="price"
                                                                            allowNegativeValue={false}
                                                                            decimalSeparator="."
                                                                            suffix=" ₽"
                                                                            defaultValue={createData.price}
                                                                            value={createData.price}
                                                                            decimalsLimit={2}
                                                                            onValueChange={onChangePriceHandle} />
                                                                    </div>
                                                                }

                                                            </div>
                                                        </div>

                                                    </form>

                                                    {
                                                        errorCreate && <Alert variant="danger">{errorCreate}</Alert>
                                                    }
                                                    {
                                                        success &&
                                                        <Alert variant="success">{success}</Alert>
                                                    }
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    {
                                                        !fetching.addItem &&
                                                        <button
                                                            onClick={CloseAddModalHandle}
                                                            className="btn btn-secondary">Закрыть</button>
                                                    }
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={createItemHandle}>
                                                        {
                                                            fetching.addItem ?
                                                                <div className="d-flex align-items-center">
                                                                    <Spinner animation="border" variant="light" size="sm"/>
                                                                    <span className="ml-2">Добавление</span>
                                                                </div>
                                                                :
                                                                <>
                                                                    Добавить
                                                                </>
                                                        }
                                                    </button>
                                                </Modal.Footer>
                                            </Modal>

                                            <CreateCraftItemComponent
                                                stockId={stock.id}
                                                classname="btn btn-dark ms-2"
                                                label="+ Крафтовое изделие"
                                                callback={getStock}
                                            />
                                        </div>
                                        {
                                            (isAdmin || isStorekeeper || isPurchaseDepartment) &&
                                            <div className="ms-auto">
                                                <ExportStockData stockId={stock.id} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                    <ItemsTable stock={stock}/>

                    </>
                }

            </div>
        </>
    )
}