// Save state to Storage
export function saveToStorage(state) {
    try {
        let serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    }
    catch(err) {
        console.log(err);
    }
}

// load state from Storage
export function loadFromStorage() {
    try {
        let serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    }
    catch(err) {
        console.log(err);
        return undefined;
    }
}