import React from 'react';
import Sidebar from '../sidebar';
import Header from '../header';
import ErrorBoundary from "../../errorBoundary";

export default ({children}) => {
    return (
        <div className="root-wrapper d-flex w-100 flex-row">
            <div className="app-page d-flex w-100 flex-column">
                <Header/>
                <div className="app-page-content d-flex w-100 flex-fill">
                    <ErrorBoundary>
                        {children}
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    )
}