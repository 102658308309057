import React, {useState} from "react"
import Api from '../../../helpers/axios'
import {Alert, Modal, Spinner} from 'react-bootstrap'
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input'

export default function EditSupplier({supplier, callback}) {
    const initialData = supplier
    const [supp, setSupp] = useState(initialData)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const openHandle = () => setOpen(true)
    const closeHandle = () => {
        setOpen(false)
        setSupp(prevState => ({...prevState, ...initialData}))
    }

    const onChangeHandle = (e) => {
        const { target } = e
        const value = target.type === 'checkbox' ? target.checked : target.value
        const { name } = target
        setSupp(prevState => ({ ...prevState, [name]: value }))
    }

    // Change phoneNumber
    const onChangePhone = (value) => {
        setSupp(prevState => ({ ...prevState, phone: value || ''}))
    };

    const validate = () => {
        if (supp.name === '') {
            setError('Укажите наиемнование');
            return false;
        }

        if (supp.phone) {
            if (!isPossiblePhoneNumber(supp.phone)) {
                setError('Укажите валидный номер телефона');
                return false;
            }
        }

        return true;
    }

    const onSaveChanges = () => {
        if (validate() && !loading) {
            setLoading(true)
            setError(null)

            Api.post('/api/suppliers/update', supp)
                .then(() => {
                    callback(supp)
                    setOpen(false)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <>
            <button
                disabled={open}
                onClick={openHandle}
                className="btn btn-sm btn-primary">
                Изменить
            </button>

            <Modal show={open} onHide={closeHandle}>
                <Modal.Body>
                    <h4>
                        Редактирование
                    </h4>

                    <div className="mt-3">
                        <div className="form-group">
                            <label htmlFor={`supp-name-${supp.id}`}>
                                *Наименование:
                            </label>
                            <input
                                id={`supp-name-${supp.id}`}
                                className="form-control"
                                name="name"
                                value={supp.name}
                                onChange={onChangeHandle}
                                type="text"/>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`supp-brand-${supp.id}`}>
                                Бренд:
                            </label>
                            <input
                                id={`supp-brand-${supp.id}`}
                                className="form-control"
                                name="brand"
                                value={supp.brand}
                                onChange={onChangeHandle}
                                type="text"/>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`supp-address-${supp.id}`}>
                                Адрес:
                            </label>
                            <textarea
                                id={`supp-address-${supp.id}`}
                                className="form-control"
                                name="address"
                                value={supp.address}
                                onChange={onChangeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`supp-manager-${supp.id}`}>
                                Менеджер:
                            </label>
                            <input
                                id={`supp-manager-${supp.id}`}
                                className="form-control"
                                name="manager"
                                value={supp.manager}
                                onChange={onChangeHandle}
                                type="text"/>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`supp-phone-${supp.id}`}>Телефон:</label>
                            <PhoneInput type="text"
                                        international={true}
                                        withCountryCallingCode={true}
                                        defaultCountry="RU"
                                        id={`supp-phone-${supp.id}`}
                                        className="form-control"
                                        name="phone"
                                        value={supp.phone}
                                        onChange={onChangePhone}
                                        placeholder="+7 ___ __ __"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`supp-url-${supp.id}`}>
                                Сайт:
                            </label>
                            <input
                                type="text"
                                id={`supp-url-${supp.id}`}
                                className="form-control"
                                name="url"
                                value={supp.url}
                                onChange={onChangeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor={`supp-address-${supp.id}`}>
                                Дополнительная информация:
                            </label>
                            <textarea
                                id={`supp-note-${supp.id}`}
                                className="form-control"
                                name="note"
                                value={supp.note}
                                onChange={onChangeHandle}/>
                        </div>

                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }

                        <div className="pt-3">
                            <button
                                disabled={loading}
                                onClick={onSaveChanges}
                                className="btn btn-primary">
                                {
                                    loading
                                        ?
                                        <div className="d-flex align-items-center">
                                            <Spinner animation="border" size="sm" variant="light"/>
                                            <span className="ml-2">Сохраняем</span>
                                        </div>
                                        : 'Сохранить'
                                }
                            </button>
                            {
                                !loading &&
                                <button
                                    onClick={closeHandle}
                                    className="btn btn-dark ml-2">
                                    Отмена
                                </button>
                            }
                        </div>

                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}