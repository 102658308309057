import {ORDERS_FILTERS, RESET_ORDERS_FILTERS} from './actions';

export const initialState = {
    page: 1,
    onPage: 30,
    desc: true,
    status: '',
    orderNumber: '',
    supplierId: '',
    tags: []
};

export const ordersFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDERS_FILTERS:
            return {
                ...state, ...action.payload
            };
        case RESET_ORDERS_FILTERS:
            return initialState;
        default:
            return state;
    }
}

export default ordersFiltersReducer;