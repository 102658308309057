import React, {useState} from "react";
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import {Alert, Modal, Spinner} from "react-bootstrap";
import Api from '../../../helpers/axios';

export default (props) => {
    const callback = props.callback || (() => { console.log('У компоненты добалвения поставщика не указан метод callback') });
    const initialData = {
        name: '',
        brand: '',
        address: '',
        url: '',
        note: '',
        manager: '',
        phone: ''
    }
    const [showModal, setShowAddModal] = useState(false);
    const [creating, setCreating] = useState(false);
    const [createData, setCreateData] = useState(initialData);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [warning, setWarning] = useState(null);

    // Закрыть модалку создания позиции
    const handleCloseModal = () => {
        setCreateData(initialData);
        setShowAddModal(false);
        setWarning(null);
        setError(null);
    };

    // Открыть модалку создания позиции
    const handleShowModal = (user) => {
        setShowAddModal(true);
    };

    // Обработка полей формы
    const onChangeHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setCreateData(prevState => ({ ...prevState, [name]: value }));
    }

    // Change phoneNumber
    const onChangePhone = (value) => {
        setCreateData(prevState => ({ ...prevState, phone: value || ''}));
    };

    const validate = () => {
        if (createData.name === '') {
            setError('Укажите наиемнование');
            return false;
        }

        if (createData.phone) {
            if (!isPossiblePhoneNumber(createData.phone)) {
                setError('Укажите валидный номер телефона');
                return false;
            }
        }

        return true;
    }

    const checkSupplier = () => {

        if (createData.name !== '') {
            if (validate() && !creating) {
                setCreating(true);
                setError(null);

                Api.post('/api/suppliers/GetSupplierByName', createData)
                    .then(res => {
                        if ((res.data.message - 0) !== 0) {
                            setWarning('Поставщик с таким наименованием уже есть в базе.');
                        } else {
                            setWarning(null);
                        }
                    })
                    .catch(err => {
                        setError(err.response.data.message);
                    })
                    .finally(() => {
                        setCreating(false);
                    });
            }
        }
        else {
            setWarning(null);
        }
    }

    // Save
    const saveItemHandle = () => {
        if (validate() && !creating) {
            setCreating(true);
            setError(null);
            setWarning(null);

            Api.post('/api/suppliers/create', createData)
                .then(() => {
                    setSuccess('Поставщик добавлен');
                    // Сбросим форму
                    setCreateData(initialData);
                    if (callback) {
                        callback();
                    }
                    setTimeout(() => {
                        setSuccess(null);
                    }, 5000);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setCreating(false);
                });
        }
    }


    return (
        <>
            <button className='btn btn-primary' onClick={handleShowModal}>
                Добавить поставщика
            </button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="card mt-3">
                            <div className="card-body">
                                <h5>
                                    Поставщик
                                </h5>
                                <div className="form-group">
                                    <label htmlFor="supplier_name">Наименование:</label>
                                    <input type="text"
                                           id="supplier_name"
                                           className="form-control"
                                           name="name"
                                           value={createData.name}
                                           onChange={onChangeHandle}
                                           onBlur={checkSupplier}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supplier_brand">Бренд:</label>
                                    <input type="text"
                                           id="supplier_brand"
                                           className="form-control"
                                           name="brand"
                                           value={createData.brand}
                                           onChange={onChangeHandle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supplier_manager">Контактное лицо:</label>
                                    <input type="text"
                                           id="supplier_manager"
                                           className="form-control"
                                           name="manager"
                                           value={createData.manager}
                                           onChange={onChangeHandle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supplier_phone">Телефон:</label>
                                    <PhoneInput type="text"
                                                international={true}
                                                withCountryCallingCode={true}
                                                defaultCountry="RU"
                                                id="supplier_phone"
                                                className="form-control"
                                                name="phone"
                                                value={createData.phone}
                                                onChange={onChangePhone}
                                                placeholder="+7 ___ __ __"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supplier_address">Адрес:</label>
                                    <textarea
                                        id="supplier_address"
                                        className="form-control"
                                        name="address"
                                        value={createData.address}
                                        onChange={onChangeHandle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supplier_url">Сайт:</label>
                                    <input type="text"
                                           id="supplier_url"
                                           className="form-control"
                                           name="url"
                                           value={createData.url}
                                           onChange={onChangeHandle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supplier_note">Дополнительная информация по поставщику:</label>
                                    <textarea
                                        id="supplier_note"
                                        className="form-control"
                                        name="note"
                                        value={createData.note}
                                        onChange={onChangeHandle} />
                                </div>
                            </div>
                        </div>
                    </form>

                    {
                        warning &&
                        <Alert variant="warning"><Alert.Heading>Внимание!</Alert.Heading>{warning}</Alert>
                    }

                    {
                        error &&
                        <Alert variant="danger">{error}</Alert>
                    }
                    {
                        success &&
                        <Alert variant="success">{success}</Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group">
                        {
                            !creating &&
                            <button className="btn btn-secondary mr-2" onClick={handleCloseModal}>
                                Закрыть
                            </button>
                        }

                        <button className="btn btn-primary" onClick={saveItemHandle}>
                            {
                                creating ?
                                    <div className='d-flex align-items-center'>
                                        <Spinner animation="border" variant="light" size="sm" />
                                        <span className='ml-2'>Сохранение</span>
                                    </div>
                                    :
                                    <>Добавить</>
                            }
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}