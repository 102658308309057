import React, {useState, useEffect, useRef, useMemo} from 'react'
import {Modal, Alert, Spinner} from 'react-bootstrap'
import Api from '../../../helpers/axios'
import {flowTypes} from '../../../helpers/flowType'
import CurrencyInput from 'react-currency-input-field'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from  'react-datepicker'
import ru from 'date-fns/locale/ru'
import FlowTags from '../../flowTags'
import Autocomplete from '../../autocomplete'

registerLocale('ru', ru)

export default ({bankAccountId, managers, callback}) => {
    const initialData = {
        bankAccountId: bankAccountId,
        type: 1,
        value: 0,
        contractPrice: 0,
        contractName: '',
        description: '',
        userId: '',
        billDate: new Date(),
        counterparty: '',
        organizationId: '',
        contractId: '',
        tags: []
    }
    const [contractDebet, setContractDebet] = useState(null)
    const [data, setData] = useState(initialData)
    const [show, setShow] = useState(false)
    const onShowHandle = () => {
        setShow(true)
    }
    const onHideHandle = () => {
        setData(initialData)
        setError(null)
        setShow(false)
    }

    // Get data from supplier autocomplete
    const updateCounterparty = (suggection) => {
        setData(prevState => ({...prevState,
            organizationId: suggection.id,
            counterparty: suggection.label}))
    }
    
    const [contractFiles, setContractFiles] = useState([])
    const suggestionContracts = (suggestion) => {
        setData(prevState => ({
            ...prevState,
            contractId: suggestion.id,
            contractName: suggestion.label,
            contractPrice: suggestion.body.price
        }))
        setContractFiles(suggestion.body.files)
        setContractDebet(suggestion.body.debet)
    }
    
    const onChangeHandle = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' ? target.value - 0 : target.value;
        const {name} = target;
        setData(prevState => ({...prevState, [name]: value}));
    }
    
    const onChangeValueHandle = (val) => {
        if (!val) {
            val = 0
        }
        setData(prevState => ({...prevState, value: val}));
    }

    const onChangeContractPriceHandle = (val) => {
        if (!val) {
            val = 0
        }
        setData(prevState => ({...prevState, contractPrice: val}));
    }

    const validate = () => {
        if (data.value === 0) {
            setError('Сумма должна быть больше 0')
            return false
        }
        
        if (data.description === '') {
            setError('Укажите описание')
            return false
        }
        
        if (data.counterparty === '') {
            setError('Укажите контрагента')
            return false
        }
        
        return true
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [upload, setUpload] = useState(false)
    const inputFile = useRef()
    const [formData, setFormData] = useState(null)
    const [uploadFiles, setUploadFiles] = useState([])

    const selectFiles = () => {
        inputFile.current.click()
    }

    const onSelectFilesHandler = (e) => {
        const files = e.target.files
        const filesData = new FormData()

        for (let i = 0; i < files.length; i++) {
            filesData.append('files', e.target.files[i])
        }
        
        inputFile.current.value = null
        setFormData(filesData)
        
        let object = {}
        
        filesData.forEach((value, key) => {
            // Reflect.has in favor of: object.hasOwnProperty(key)
            if(!Reflect.has(object, key)){
                object[key] = value.name;
                return;
            }
            if(!Array.isArray(object[key])){
                object[key] = [object[key]];
            }
            object[key].push(value.name);
        })

        setUploadFiles(object)
    }

    const onSaveHandle = () => {
        if (!loading && validate()) {
            setLoading(true)
            setError(null)

            Api.post('/api/flow/create', data)
                .then((res) => {
                    
                    if (formData) {
                        const contractId = res.data.body.contractId

                        let fd = formData;
                        fd.append('contractId', contractId)

                        if (!upload) {
                            setLoading(true)
                            setUpload(true)

                            Api.post('/api/flow/upload', fd)
                                .then(() => {
                                })
                                .catch(err => setError(err.response.data.message))
                                .finally(() => {
                                    setLoading(false)
                                    setUpload(false)
                                    setFormData(null)
                                    setUploadFiles([])
                                })
                        }
                    }
                    
                    onHideHandle()

                    if (typeof callback === "function") {
                        callback()
                    }
                    setError(null)
                })
                .catch(err => {
                    setError(err.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    const onUpdateTags = (tags) => {
        setData(prevState => ({...prevState, tags: tags}))
    }
    
    const [additionalProps, setAdditionalProps] = useState(false)

    useEffect(() => {
        setAdditionalProps(data.type === 0)

        setData(prevState => ({...prevState,
            value: 0,
            contractPrice: 0,
            contractName: '',
            description: '',
            userId: '',
            billDate: new Date(),
            counterparty: '',
            organizationId: '',
            contractId: ''
        }))
        
    },[
        data.type
    ])

    const removeAttachedFiles = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setFormData(null)
        setUploadFiles([])
    }
    
    useMemo(() => {
        if(error) {
            setError(null)
        }
    }, [data])

    return (
        <div>
            <button
                disabled={show}
                onClick={onShowHandle}
                className="btn btn-primary">Новая запись
            </button>

            <Modal show={show} onHide={()=>{}}>
                <Modal.Body>
                    <form onSubmit={e => { e.preventDefault() }}>
                        <div className="card-body">
                            <h5 className="mb-3">
                                Новая запись
                            </h5>
                            <div className="text-muted small mb-3">
                                Все поля обязательны для заполнения
                            </div>

                            <div className="mb-3">
                                <label>Тип:</label>
                                {
                                    flowTypes.map((type, i) =>
                                        <div key={i} className="form-check fake-checkbox">
                                            <input type="radio"
                                                   className="form-check-input"
                                                   id={`type_${type.value}`}
                                                   name="type"
                                                   value={type.value}
                                                   checked={data.type === type.value}
                                                   onChange={onChangeHandle}
                                            />
                                            <label className="form-check-label" htmlFor={`type_${type.value}`}>
                                                {type.label}
                                            </label>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="mb-3">
                                <label>Дата:</label>
                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={data.billDate}
                                        onChange={(date) => {setData(prevState => ({...prevState, billDate: date}))}}
                                        locale="ru"
                                        peekNextMonth
                                        maxDate={new Date()}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        timeCaption="время"
                                        dateFormat="d MMMM yyyy в HH:mm"
                                    />
                                </div>
                            </div>
                            
                            <div className="mb-3">
                                <label>Контрагент:</label>
                                <Autocomplete
                                    value={data.counterparty}
                                    callback={updateCounterparty}
                                    endpoint="/api/suggestions/organizations"
                                    inputClassName="form-control" placeholder={'Например, ООО "ХИМПРОЕКТ"'}
                                />
                            </div>
                            
                            {
                                additionalProps &&
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="manager">Менеджер:</label>
                                        <select
                                            onChange={onChangeHandle}
                                            value={data.userId}
                                            name="userId"
                                            id="manager"
                                            className="form-control">
                                            <option value={''}>Я</option>
                                            {
                                                managers.map(manager =>
                                                    <option value={manager.id}>{`${manager.firstName} ${manager.lastName}`}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </>
                            }

                            <div className="mb-3">
                                <label htmlFor="flow-value">Сумма {data.type === 0 ? 'прихода': 'расхода'}:</label>
                                <CurrencyInput
                                    id="flow-value"
                                    className="form-control"
                                    name="value"
                                    allowNegativeValue={true}
                                    suffix=" ₽"
                                    decimalSeparator="."
                                    defaultValue={data.value}
                                    value={data.value}
                                    decimalsLimit={2}
                                    onValueChange={onChangeValueHandle}
                                />
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="flow-description">Описание:</label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    id="flow-description"
                                    placeholder="Например, Ангар 40 на 20"
                                    value={data.description}
                                    onChange={onChangeHandle}
                                />
                            </div>
                            
                            <div className="mb-3">
                                <label>Теги:</label>
                                <FlowTags
                                    tagsList={data.tags}
                                    callback={onUpdateTags}/>
                            </div>
                            
                            {
                                error && <Alert variant="danger">{error}</Alert>
                            }
                            
                            <div className="pt-3">
                                <button
                                    disabled={loading}
                                    onClick={onSaveHandle}
                                    className="btn btn-primary">
                                    {
                                        loading ?
                                            <div className="d-flex align-items-center">
                                                <Spinner animation="border" size="sm" variant="light"/>
                                                <span className="ms-2">Сохранение</span>
                                            </div>
                                            :
                                            'Сохранить'
                                    }
                                </button>
                                {
                                    !loading &&
                                    <button
                                        onClick={onHideHandle}
                                        className="btn btn-dark ms-2">Отмена</button>
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}