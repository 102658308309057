import React, {useEffect} from 'react'
import Api from '../../helpers/axios'
import {useDispatch} from "react-redux"
import {logoutAction} from "../../store/auth/actions"
import {Link} from "react-router-dom";

export default () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(logoutAction);

        Api.post('/api/account/logout')
            .then(() => {
                window.localStorage.removeItem('state');
                dispatch(logoutAction);
            })
            .catch(err => console.log('Error', JSON.stringify(err)));
        
    }, []);
    
    return (
        <div className="container text-center pt-4">
            <h2>
                Выход
            </h2>
            <p>
                Вы успешно вышли из своего аккаунта.
            </p>
            <Link to="/login" className="btn btn-primary">Войти</Link>
        </div>
    )
}