import React, {useState, useEffect, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";
import FetchState from "../../components/ui/fetchState";
import {Alert} from 'react-bootstrap';
import Api from '../../helpers/axios';
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {requestOrderStatus} from "../../helpers/getStatusRequest";
import UserDetails from "../../components/userDetails";
import StockDetails from "../../components/stockDetails";
import {getShortNameAmountType} from "../../helpers/amountTypes";
import ItemPhotos from "../../components/itemPhotos";
import roles from "../../helpers/roles";
import getCurrentUser from "../../helpers/getCurrentUser";

export default () => {
    const {id} = useParams();
    const [fetch, setFetch] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [order, setOrder] = useState(null);
    const currentUser = getCurrentUser();
    const canDelete = roles.is(roles.administrator);
    const canConfirm = roles.is(roles.storekeeper);
    const [isOwner, setIsOwner] = useState(false);
    
    const onGetOrderHandle = () => {
      if (!fetch) {
          setFetch(true);
          
          Api.post('/api/requestOrders/getOrder', {id: id})
              .then(res => {
                  setOrder(res.data.body);
              })
              .catch(err => setError(err.response.data.message))
              .finally(() => {
                 setFetch(false); 
              });
      }  
    };

    // Выдать все
    const onConfirmAllHandle = async () => {
        if (!loading) {
            setLoading(true)
            setError(null)
            setSuccess(null)

            Api.post('/api/requestOrders/confirmAll', {id: order.id})
                .then(() => {
                    getOrder()
                    setSuccess("Позиции полностью выданы")
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const [deleting, setDeleting] = useState(false);
    const onDeleteHandle = () => {
        if (confirm('Уверены что хотите удалить заявку?')) {
            if (!deleting) {
                setDeleting(true);
                setError(null);

                Api.post('/api/requestOrders/delete', {id: order.id})
                    .then(() => {
                        setSuccess("Заявка удалена");
                        location.reload();
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setDeleting(false)
                    })
            }
        }
    }
    
    useEffect(() => {
        onGetOrderHandle();
    }, []);
    
    useMemo(() => {
        if (order.authorId === currentUser.id) {
            setIsOwner(true);
        }
    }, [order]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-8 offset-md-2">
                    <p>
                        <Link to="/requests">Заявки на выдачу</Link>
                    </p>
                    {
                        error && <Alert variant="danger">{error}</Alert>
                    }
                    {
                        fetch ?
                            <FetchState loading={fetch} label="Загрузка данных" size="sm" variant="warning"/>
                            :
                            order && <div className="mt-3">
                                <h3>
                                    Заявка на выдачу №
                                    {order.orderNumber}
                                </h3>
                                <div className="mb-2">
                                    от {format(new Date(order.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})},
                                    статус: <strong>{requestOrderStatus.getDisplayName(order.state)}</strong>
                                </div>
                                
                                <div className="d-flex align-items-center mt-3 mb-3">
                                    <span className="me-2">Заявитель:</span>
                                    <UserDetails user={order.author}/>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h4>
                                            <span className="mr-1">Назначение:</span>
                                            {
                                                order.project ?
                                                    <Link to={`/project/${order.project.id}`}>
                                                        {order.project.name}
                                                    </Link>
                                                    : 'Не указано'
                                            }
                                        </h4>

                                        <table className="table table-bordered">
                                            <caption>
                                                Всего позиций: {order.positions.length}
                                            </caption>
                                            <thead>
                                            <tr>
                                                <th>
                                                    Позиция:
                                                </th>
                                                <th>
                                                    Склад:
                                                </th>
                                                <th>
                                                    Запрошено:
                                                </th>
                                                <th>
                                                    Выдано:
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                order.positions.map(position =>
                                                    <tr key={position.item.id}>
                                                        <td>
                                                            {position.item.name}
                                                            {
                                                                position.item.brand && <div
                                                                    className="small mt-1 text-muted">{position.item.brand}</div>
                                                            }
                                                            <div>
                                                                <ItemPhotos
                                                                    itemPhotos={position.item.itemPhotos}
                                                                    caption={position.item.name} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <StockDetails stock={position.stock}/>
                                                        </td>
                                                        <td>
                                                            {position.amount} {getShortNameAmountType(position.amountType)}
                                                        </td>
                                                        <td>
                                                            {position.amountIssued} {getShortNameAmountType(position.amountType)}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                {
                                    success && <Alert variant="success">{success}</Alert>
                                }
                            
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex w-100">
                                            {
                                                ((canConfirm || canDelete) && order.state !== requestOrderStatus.completed) &&
                                                <div>
                                                    <button onClick={onConfirmAllHandle} className="btn btn-primary">
                                                        {
                                                            loading
                                                                ? <FetchState loading={true} label="Выдача" size="sm" variant="light" />
                                                                : 'Выдать все'
                                                        }
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}