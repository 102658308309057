import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import Layout from '../components/layouts/layoutAdmin';
import roles from "../helpers/roles";

export const AdminRoute = ({ component: Component, ...rest }) => {
    const state = localStorage.state ? JSON.parse(localStorage.state) : null;
    const isAuth = state ? state.auth.isAuth : false;
    const isAdmin = roles.is(roles.administrator);

    return (
            <Route
                {...rest}
                render={props => isAuth && isAdmin ?
                    (
                        <Layout><Component {...props} /></Layout>
                    ) :
                    (<Redirect to={{
                        pathname: '/404',
                        state: {from: props.location}
                    }}
                    />)
                }
            />
    );
}