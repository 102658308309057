import React, {useState, useEffect} from "react";
import Api from "../../../../helpers/axios";
import {Modal, Alert} from "react-bootstrap";
import FetchState from "../../../../components/ui/fetchState";
import GetResponseError from "../../../../helpers/getResponseError";
import list from "../../../client-project/components/comments/list";

export const ExcelReport = ({supplier, projects}) => {
    const [listProjects, setListProjects] = useState(projects);
    const [fetch, setFetch] = useState(false);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const onHide = () => {
        setShow(false);
    };
    const onShow = () => {
        setShow(true);
    };

    const [filename, setFilename] = useState('');
    const initialData = {
        supplierId: supplier.id,
        periodStart: "",
        periodEnd: "",
        exceptProjects: []
    };
    const [data, setData] = useState(initialData);
    
    const onChangeHandle = e => {
        setData({...data, [e.target.name] : e.target.value});
    };
    
    const addExceptProject = (id) => {
        setData({...data, exceptProjects: [...data.exceptProjects, id]});
    };
    
    const removeExceptProject = (id) => {
        setData({...data, exceptProjects: data.exceptProjects.filter(x => x !== id)});
    };

    const onSelectProject = e => {
        const id = e.target.value;
        if (id !== "") {
            if (!data.exceptProjects.find(x => x === id)) {
                addExceptProject(id);
            }
        }
    }
    
    const onStartExport = () => {
        if (!fetch) {
            setFetch(true);
            setError("");
            setFilename("");

            Api.post("/api/suppliers/ExcelReportSupplier", data)
                .then(res => {
                    setFilename(res.data.body);
                })
                .catch(err => setError(GetResponseError(err)))
                .finally(() => {
                    setFetch(false);
                });
        }
    }
    
    useEffect(() => {
        setListProjects(projects);
    }, [projects]);
    
    
    
    return (
        <>
            <button
                onClick={onShow}
                className="btn btn-sm btn-secondary">
                Отчет Excel
            </button>
            <Modal show={show} onHide={onHide} size={"lg"}>
                <Modal.Header closeButton>
                    <h5>Отчет по поставщику</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center pt-2 pb-3">
                        <h4>
                            {supplier.name}
                        </h4>
                        <div>
                            <p>
                                Отчет по выданным позициям поставщика.
                            </p>
                            Запустите создание отчета, чтобы получить ссылку на excel-файл с актуальными данными.
                        </div>
                        <div className="mt-3">
                            <div className="text-center mb-2">
                                <h5>
                                    Выберите период:
                                </h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="form-group">
                                    <label htmlFor="periodStart" className="form-label">
                                        Начало периода:
                                    </label>
                                    <input type="date"
                                           className="form-control"
                                           onChange={onChangeHandle}
                                           name="periodStart"
                                           id="periodStart"
                                           value={data.periodStart} />
                                </div>

                                <span className="px-2">
                                    &mdash;
                                </span>

                                <div className="form-group">
                                    <label htmlFor="periodStart" className="form-label">
                                        Конец периода:
                                    </label>
                                    <input type="date"
                                           className="form-control"
                                           onChange={onChangeHandle}
                                           name="periodEnd"
                                           id="periodEnd"
                                           value={data.periodEnd} />
                                </div>
                            </div>
                        </div>
                        {
                            listProjects.length > 0 &&
                            <div className="mt-3">
                                <div>
                                    Добавить проекты, которые не нужно учитывать (например проекты на возврат поставщику).
                                </div>
                                <div className="d-flex align-items-center mt-1">
                                    <select
                                        onChange={onSelectProject}
                                        className="form-select">
                                        <option value={""}>-</option>
                                        {
                                            listProjects.map(project =>
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        }

                        {
                            data.exceptProjects.length > 0 &&
                            <div className="my-2">
                                <div className="text-start mb-2">
                                    <b>Проекты, которые не учитывать:</b>
                                </div>
                                {
                                    data.exceptProjects.map((item, i) =>
                                        {
                                            const project = projects.find(x => x.id === item);
                                            
                                            if (!project) {
                                                return <></>
                                            }
                                            
                                            return (
                                                <div className="d-flex mb-1">
                                                    <span className="me-1">
                                                        {i + 1}.
                                                    </span>
                                                    <span>
                                                        {project.name}
                                                    </span>
                                                    <a href="#" className="ms-2" onClick={() => removeExceptProject(project.id)}>
                                                        Убрать
                                                    </a>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        }
                        
                        <div className="mt-3">
                            <button
                                disabled={fetch || data.periodStart === "" || data.periodEnd === ""}
                                onClick={onStartExport}
                                className="btn btn-primary">
                                {
                                    fetch
                                        ? <FetchState loading={true} size={"sm"} label={"Обработка данных"} variant={"light"} />
                                        : 'Запустить'
                                }
                            </button>
                            {
                                error &&
                                <div className="mt-3">
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        filename &&
                        <div className="mt-3 text-center">
                            <hr/>
                            <a href={`/exports/${filename}`} className="btn btn-link" target="_blank">
                                Скачать Excel-файл
                            </a>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}