import React, {useState} from "react";
import Api from '../../helpers/axios';
import {Alert, Modal} from 'react-bootstrap';
import FetchState from "../../components/ui/fetchState";

export default () => {
    const [data, setData] = useState({
        email: ''
    });
    const [fetch, setFetch] = useState(false);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const onShow = e => {
        e.preventDefault();
        setShow(true);
    };
    const onHide = () => {
        setShow(false);
    };
    
    const validate = () => {
        if (data.email === '') {
            setError('Укажите email');
            return false;
        }
        return true;
    };
    
    const onChangeHandle = (e) => {
        setData({...data, email: e.target.value});
        error && setError('');
    }
    
    const [success, setSuccess] = useState('');
    
    const onSaveHandle = () => {
        if (validate() && !fetch) {
            setFetch(true);
            setError('');
            
            Api.post('/api/account/resetPasswordBot', data)
                .then(() => {
                    setSuccess('Перейдите в телеграм, бот прислал вам новый пароль');
                    setData({...data, email: ''});
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setFetch(false);
                });
        }
    };
    
    return (
        <>
            <div className="text-center">
                <a href="#reset" onClick={onShow}>Я забыл(а) пароль 😔</a>
            </div>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h5>
                        Сброс пароля
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Укажите ваш email, который вы использовали для входа в систему.
                    </p>
                    <p className="small text-muted">
                        Сброс пароля будет происходить через телеграм бота, если вы его не подключали,
                        то через эту форму восстановить пароль не получится, обратитесь к руководству или администратору.
                    </p>
                    <div className="form-group">
                        <label htmlFor="resetEmail">Email:</label>
                        <input
                            type="email"
                            id="resetEmail"
                            className="form-control"
                            value={data.email}
                            onChange={onChangeHandle} />
                    </div>

                    {
                        error &&
                        <div>
                            <Alert variant="danger">{error}</Alert>
                        </div>
                    }
                    {
                        success &&
                        <div className="text-center">
                            <Alert variant="success">{success}</Alert>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={onSaveHandle}
                        className="btn btn-primary">
                        {
                            fetch
                                ? <FetchState loading={fetch} label="Обработка" size="sm" variant="light" />
                                : 'Продолжить'
                        }
                    </button>
                    <button
                        onClick={onHide}
                        className="btn btn-dark ml-2">Закрыть</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}