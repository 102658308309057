import React, {useState, useEffect, useRef} from "react";
import Api from '../../helpers/axios';
import {ReactComponent as IconBell} from "./icons/alarm_bell.svg";
import styles from './style.module.scss';
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import GetResponseError from "../../helpers/getResponseError";
import formatDate from "../../helpers/formatDate";
import {notificationTypes} from "../notifications/types";
import {Link} from "react-router-dom";
import FetchState from "../ui/fetchState";
import {Alert} from "react-bootstrap";
import {SignalContext} from "../../contexts/signalContext";

export default () => {
    const ref = useRef(null);
    const [firstRender, setFirstRender] = useState(true);
    const [error, setError] = useState('');
    const [count, setCount] = useState(0);
    const [notices, setNotices] = useState([]);
    const [fetch, setFetch] = useState(false);
    const onLoadNotices = () => {
        setFetch(true);
        Api.post('/api/notices/GetNewNotices', {})
            .then(res => {
                setCount(res.data.body.count);
                setNotices(res.data.body.notices);
            })
            .catch(err => setError(GetResponseError(err)))
            .finally(() => {
                setFetch(false);
            });
    };
    
    useEffect(() => {
        if (firstRender) {
            onLoadNotices();
            setFirstRender(false);
        }

        const intervalId = setInterval(() => {
            onLoadNotices();
        }, 60000);

        return () => clearInterval(intervalId);

    }, []);
    /*
    const connection = React.useContext(SignalContext);

    connection.Notifications &&
    connection.Notifications.on('UpdateNotices', () => {
        onLoadNotices();
    });
     */
    
    const [open, setOpen] = useState(false);
    const onOpen = () => {
        setOpen(true);
    };
    const onCloseHandle = (e) => {
        e.preventDefault();
        setOpen(false);
    };
    
    const onMakeAsRead = (id) => {
        setNotices(notices.filter(x => x.id !== id));
        const newCount = count - 1;
        setCount(newCount);
        Api.post('/api/notices/SetNoticeAsRead', {id: id})
            .then()
            .catch(err => setError(GetResponseError(err)));
        
        if (newCount <= 0 && open) {
            setOpen(false);
        }
    }
    
    const clickOnLink = (noticeId) => {
        noticeId && onMakeAsRead(noticeId);
        setOpen(false);
    }

    const onCloseLinkHandle = () => setOpen(false);

    const checkIfClickedOutside = e => {
        if (open && ref.current && !ref.current.contains(e.target)) {
            setOpen(false);
            e.stopPropagation();
        }
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", checkIfClickedOutside);
        
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [open]);
    
    return (
        <div className={styles.noticeBell}>
            <div onClick={onOpen}>
                <IconBell/>
            </div>
            {
                count > 0 &&
                <span className={styles.noticeBellCount}>
                    {
                        count > 99 ? '99+' : count
                    }
                </span>
            }
            {
                open &&
                <div className={styles.notices} ref={ref}>
                    <div className={styles.noticesHeader}>
                        <span>
                            Уведомления
                        </span>
                        <a href={null} className={styles.noticesHeaderClose} onClick={e => onCloseHandle(e)}>&times;</a>
                    </div>
                    <div className={styles.noticesBody}>

                        {
                            error &&
                            <div className="mb-2 px-2 pt-2">
                                <Alert variant="danger">{error}</Alert>
                            </div>
                        }

                        {
                            fetch &&
                            <div className="d-flex w-100 justify-content-center py-4">
                                <FetchState loading={true} label="" size="lg" />
                            </div>
                        }                       
                        
                        {
                            (notices && notices.length > 0) ?
                            notices.map(notice =>
                                <div key={notice.id} className={styles.noticesCard}>
                                    <div className="d-flex">
                                        <div className={styles.noticesCardTitle}>
                                            {notice.title}
                                        </div>
                                        <div className={styles.noticesCardDate}>
                                            {formatDate(new Date(notice.dateCreate), "dd.MM.yyyy в HH:mm")}
                                        </div>
                                    </div>
                                    <div className={styles.noticesCardText}>
                                        {notice.text}
                                    </div>
                                    <div>
                                        {
                                            notice.type === notificationTypes.newTaskComment &&
                                            <div className="mt-2">
                                                Задача:{' '}
                                                <Link
                                                    onClick={() => clickOnLink(notice.id)}
                                                    to={`/task/${notice.entityId}`}>
                                                    {notice.entityJsonData}
                                                </Link>
                                            </div>
                                        }
                                        {
                                            notice.type === notificationTypes.lessMinimalAmountOnStock &&
                                            <div className="mt-2">
                                                <Link
                                                    onClick={() => clickOnLink(notice.id)}
                                                    to={`/stock/${notice.entityJsonData}?query=${notice.entityId}`}
                                                >
                                                    Посмотреть на складе
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                    <div className="d-flex w-100 justify-content-end">
                                        <button
                                            onClick={() => onMakeAsRead(notice.id)}
                                            className="btn btn-sm btn-dark">
                                            Прочитано
                                        </button>
                                    </div>
                                </div>
                            )
                                :
                                <div className="pt-4 pb-4 text-center text-muted">
                                    Новых уведомлений пока нет
                                    <br/><br/>
                                    <button
                                        onClick={e => onCloseHandle(e)}
                                        className="btn btn-sm btn-secondary">Закрыть</button>
                                </div>
                        }
                        <div className={styles.noticesFooter}>
                            <Link to={'/notifications'} onClick={onCloseLinkHandle}>Все уведомления</Link>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
