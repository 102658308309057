import {CHANGE_APP_THEME} from "./actions";

const initialState = {
    themes: [
        'theme-light',
        'theme-dark'
    ],
    currentTheme: 'theme-light'
}

export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_APP_THEME:
            document.documentElement.className = action.themeName;
            return {
                ...state,
                currentTheme: action.themeName
            }
        default:
            return state;
    }
}

export default themeReducer;