import React from "react";
import './style.scss';
import UserDetails from "../../../../../components/userDetails";
import formatDate from "../../../../../helpers/formatDate";
import Avatar from "../../../../../components/ui/avatar";

export default function CommentItem({comment}) {
    return (
        <div className="comment-item d-flex w-100">
            <div className="comment-item-photo">
                <Avatar path={comment.user.avatar} size={36} />
            </div>
            <div className="ms-2">
                <div className="d-flex w-100 align-items-center">
                    <div>
                        <UserDetails
                            user={comment.user}
                            showAvatar={false}
                            showModal={true} />
                    </div>
                    <div className="comment-item-date ms-auto">
                        {formatDate(new Date(comment.dateCreate), "dd.MM.yyyy в HH:mm")}
                    </div>
                </div>
                <div className="comment-item-content">
                    <div dangerouslySetInnerHTML={{__html: comment.content}} />
                </div>
            </div>
        </div>
    )
}