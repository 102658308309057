import React, {useState} from 'react'
import {Alert, Modal, Spinner} from 'react-bootstrap'
import {getShortNameAmountType} from "../../helpers/amountTypes"
import {amountTypes} from "../../helpers/amountTypes"
import Api from "../../helpers/axios"

export default ({orderId, positionId, positionName, amount, amountType, orderState, callback}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [show, setShow] = useState(false)
    const onHide = () => setShow(false)
    const onShow = (e) => {
        e.preventDefault()
        setShow(true)
    }
    const initialData = {
        id: positionId,
        orderId: orderId,
        amount: amount,
        amountType: amountType
    }
    const [data, setData] = useState(initialData)
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }
    const onCancel = () => {
        setData(initialData)
        setShow(false)
    }
    
    const onSaveChanges = () => {
        if(confirm('Сохранить изменения количества?')) {
            if(!loading) {
                setLoading(true)
                setError(null)

                Api.post('/api/orders/changeAmount', data)
                    .then(() => {
                        onHide()
                        callback()
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }
    
    return (
        <>
            <div>
                {data.amount} {getShortNameAmountType(data.amountType)}
                {
                    (orderState === 0 || orderState === 1) &&
                    <div className="small">
                        <a
                            onClick={onShow}
                            href="#edit">Изменить</a>
                    </div>
                }
            </div>
            <Modal show={show} onHide={onCancel}>
                <Modal.Body>
                    <h5>
                        Редактирование количества
                    </h5>
                    <div className="mb-4 text-muted">
                        Количества товара должно соответствовать количеству
                        указаного в прикрепленном счете от поставщика.
                    </div>
                    <hr/>
                    
                    <div className="form-area">
                        <div className="form-group">
                            <div className="display-18">
                                {positionName}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="form-group">
                                <label htmlFor={`amount_${positionId}`}>Количество:</label>
                                <input
                                    id={`amount_${positionId}`}
                                    className="form-control"
                                    type="number"
                                    name="amount"
                                    onChange={handleChange}
                                    value={data.amount}
                                />
                            </div>
                            <div className="form-group ml-2">
                                <label htmlFor={`amountType_${positionId}`}>Ед. измерения:</label>
                                <select name="amountType"
                                        className="form-control"
                                        id={`amountType_${positionId}`}
                                        value={data.amountType}
                                        onChange={handleChange}>
                                    {
                                        amountTypes.map(type =>
                                            <option key={type.key} value={type.key}>{type.label} ({type.short})</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }
                        <div className="form-group">
                            <button
                                disabled={loading}
                                onClick={onSaveChanges}
                                className="btn btn-primary">
                                {
                                    loading ?
                                        <div className="d-flex align-items-center">
                                            <Spinner animation="border" size="sm" variant="light" />
                                            <span className="ml-2">Сохраняем</span>
                                        </div>
                                        :
                                        'Сохранить'
                                }
                            </button>
                            {
                                !loading &&
                                <button
                                    onClick={onCancel}
                                    className="btn btn-dark ml-2">Отмена</button>
                            }
                        </div>
                    </div>
                    
                </Modal.Body>
            </Modal>
        </>
    )
}