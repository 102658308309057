import React, {useState, useMemo} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {addToOrderAction, removeFromOrderByIdAction, changeAmountProductOrderAction} from "../../../store/order/actions";
import InputNumber from "../../ui/inputNumber";

export default (props) => {
    const orderState = useSelector(state => state.order);
    const dispatch = useDispatch();
    const product = props.product || null;
    const stock = props.stock || null;
    const amountType = props.amountType || 0;
    const [added, setAdded] = useState(false);
    const [productAdded, setProductAdded] = useState(null);

    // Add to cart
    const addToOrderHandle = (e) => {
        e.preventDefault();

        const newProduct = {
            product: product,
            amount: 1,
            amountType: amountType,
            stock: stock
        };

        dispatch(addToOrderAction(newProduct));
        setProductAdded(newProduct);
        setAdded(true);
    }

    const changeAmount = (value) => {
        if (value === '' || value === 0) {
            dispatch(removeFromOrderByIdAction(product.id));
            setAdded(false);
            return;
        }

        setProductAdded(prevState => ({...prevState, amount: value}))

        dispatch(changeAmountProductOrderAction({
            id: productAdded.product.id,
            value: value
        }));
    }

    useMemo(() => {
        if (orderState.products.length) {
            let findProduct = orderState.products.find(x => x.product.id === product.id);
            if (findProduct) {
                setProductAdded(findProduct);
                setAdded(true);
            }
        }
    }, []);

    useMemo(() => {

        if (orderState.products) {
            let findProduct = orderState.products.find(x => x.product.id === product.id);
            setAdded(!!findProduct);
        }

    }, [orderState]);

    return (
        <>
            {
                added ?
                    <div className="d-flex flex-column align-items-center">
                        <InputNumber
                            value={productAdded.amount}
                            label={'Закупить на склад'}
                            onChange={changeAmount}
                        />
                    </div>
                    :
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={addToOrderHandle}>
                        Закупить на&nbsp;склад
                    </button>
            }
        </>
    )
}