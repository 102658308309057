import React, {useState} from "react";
import {Modal} from "react-bootstrap";

export default (props) => {
    const stock = props.stock;
    const [showDetails, setShowDetails] = useState(false);

    const handleCloseDetailsModal = () => {
        setShowDetails(false);
    };
    const handleShowDetailsModal = (e) => {
        e.preventDefault();
        setShowDetails(true);
    };

    return (
        (stock && stock.id) ?
            <>
                <a href="#" onClick={handleShowDetailsModal}>
                    {`${stock.name}`}
                </a>
                <Modal show={showDetails} onHide={handleCloseDetailsModal}>
                    <Modal.Body>
                        <h3>
                            {stock.name}
                        </h3>
                        <table className="table table-striped table-bordered">
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Адрес:</strong>
                                </td>
                                <td>
                                    {stock.address || 'Не указано'}
                                </td>
                            </tr>
                            {
                                stock.note &&
                                <tr>
                                    <td>
                                        <strong>Примечание:</strong>
                                    </td>
                                    <td>
                                        {stock.note}
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={handleCloseDetailsModal}>Ок</button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            <>
                &mdash;
            </>
    )
}