import React, {useState} from 'react'
import {Modal, Alert, Spinner} from 'react-bootstrap'
import Api from '../../../helpers/axios'

export default (props) => {
    const callback = props.callback || null
    const initialData = {
        name: '',
        description: ''
    }
    const [data, setData] = useState(initialData)
    const [show, setShow] = useState(false)
    const onShowHandle = () => {
        setShow(true)
    }
    const onHideHandle = () => {
        setData(initialData)
        setError(null)
        setShow(false)
    }

    const onChangeHandle = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;
        setData(prevState => ({...prevState, [name]: value}));
    }

    const validate = () => {
        if (data.name === '') {
            setError('Укажите наименование проекта')
            return false
        }
        if (data.description === '') {
            setError('Укажите описание проекта')
            return false
        }

        return true
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSaveHandle = () => {
        if (!loading && validate()) {
            setLoading(true)
            setError(null)

            Api.post('/api/projects/create', data)
                .then(() => {
                    onHideHandle()

                    if (typeof callback === "function") {
                        callback()
                    }

                })
                .catch(err => {
                    setError(err.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div>
            <button
                disabled={show}
                onClick={onShowHandle}
                className="btn btn-primary">Новый проект
            </button>

            <Modal show={show} onHide={onHideHandle}>
                <Modal.Body>
                    <form onSubmit={e => {
                        e.preventDefault()
                    }}>
                        <div className="card-body">
                            <h5 className="mb-3">
                                Новый проект
                            </h5>
                            <div className="text-muted small mb-3">
                                * - Обязательные поля для заполнения
                            </div>

                            <div className="form-group">
                                <label htmlFor="project-name">*Наименование проекта:</label>
                                <input
                                    id="project-name"
                                    className="form-control"
                                    name="name"
                                    value={data.name}
                                    onChange={onChangeHandle}
                                    type="text"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="project-name">*Описание проекта:</label>
                                <textarea
                                    id="project-description"
                                    className="form-control"
                                    name="description"
                                    value={data.description}
                                    onChange={onChangeHandle}
                                />
                            </div>
                            
                            {
                                error && <Alert variant="danger">{error}</Alert>
                            }

                            <div>
                                <button
                                    disabled={loading}
                                    onClick={onSaveHandle}
                                    className="btn btn-primary">
                                    {
                                        loading ?
                                            <div className="d-flex align-items-center">
                                                <Spinner animation="border" size="sm" variant="light"/>
                                                <span className="ml-2">Сохранение</span>
                                            </div>
                                            :
                                            'Сохранить'
                                    }
                                </button>
                                {
                                    !loading &&
                                    <button
                                        onClick={onHideHandle}
                                        className="btn btn-dark ml-2">Отмена</button>
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}