import React from "react";
import {NavLink, Link} from "react-router-dom";
import './style.scss';

export default () => {
    return (
        <div className='adminMenu'>
            <Link to='/' className='adminMenu-item'>Открыть сайт</Link>
            <NavLink exact
                     to='/admin/users'
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Пользователи
            </NavLink>
            <NavLink exact
                     to='/admin/stocks'
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Склады
            </NavLink>
            <NavLink exact
                     to='/admin/calc'
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Расчетные свойства
            </NavLink>
            <NavLink exact
                     to={'/admin/funnels'}
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Доски
            </NavLink>
            <NavLink exact
                     to='/admin/dataProps'
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Поля форм CRM
            </NavLink>
            <NavLink exact
                     to='/admin/fileGroup'
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Группы файлов
            </NavLink>
            <NavLink exact
                     to='/admin/contacts'
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Типы контактов
            </NavLink>
            <NavLink exact
                to='/admin/parser'
                 activeClassName='adminMenu-item--active'
                 className='adminMenu-item'>
                Парсер движения средств
            </NavLink>
            <NavLink exact
                     to='/admin/options'
                     activeClassName='adminMenu-item--active'
                     className='adminMenu-item'>
                Настройки
            </NavLink>
        </div>
    );
};