import React, {useState, useMemo} from "react";
import {Alert, Modal, Spinner} from "react-bootstrap";
import Autocomplete from '../../autocomplete';
import Api from '../../../helpers/axios';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import userHaveRole from "../../../helpers/userHaveRole";
import {amountTypes} from "../../../helpers/amountTypes";

export default (props) => {
    const canEnterPrice = userHaveRole("Purchase Department")
        || userHaveRole("Administrator");
    const callback = props.callback || (() => { console.log('У компоненты добалвения позиции не указан метод callback') });
    const initialData = {
        name: '',
        brand: '',
        price: 0,
        amountType: 0,
        supplier: {
            id: '',
            name: '',
            brand: '',
            address: '',
            url: '',
            note: '',
            manager: '',
            phone: ''
        }
    };
    const [showModal, setShowAddModal] = useState(false);
    const [creating, setCreating] = useState(false);
    const [createData, setCreateData] = useState(initialData);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isNewSupplier, setIsNewSupplier] = useState(false);

    // Закрыть модалку создания позиции
    const handleCloseModal = () => {
        setCreateData(initialData);
        setShowAddModal(false);
        setIsNewSupplier(false);
    };

    // Открыть модалку создания позиции
    const handleShowModal = (user) => {
        setShowAddModal(true);
    };

    // Обработка полей формы
    const onChangeHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setCreateData(prevState => ({ ...prevState, [name]: value }));
    }

    // Обработка полей формы для поставщика
    const onChangeSupplierHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setCreateData(prevState => ({ ...prevState, supplier: {...prevState.supplier, [name]: value} }));
    }

    // Change phoneNumber
    const onChangeSupplierPhone = (value) => {
        setCreateData(prevState => ({ ...prevState, supplier: {...prevState.supplier, phone: value || ''} }));
    };

    // Get data from supplier autocomplete
    const updateSupplier = (selectedSupplier) => {
        setCreateData(prevState => ({...prevState,
            supplier: {...prevState.supplier,
                id: selectedSupplier.id,
                name: selectedSupplier.label} }));
    }

    useMemo(()=>{
        if (createData.supplier.name !== '') {
            setIsNewSupplier(createData.supplier.id === '');
        }
        else {
            setIsNewSupplier(false);
        }
    }, [createData.supplier]);

    // Get data from brand autocomplete
    const updateItemBrand = (inputBrand) => {
        setCreateData(prevState => ({...prevState, brand: inputBrand.label}))
    }

    const validate = () => {
        if (createData.name === '') {
            setError('Укажите наиемнование');
            return false;
        }

        if (createData.supplier.phone) {
            if (!isPossiblePhoneNumber(createData.supplier.phone)) {
                setError('Укажите валидный номер телефона');
                return false;
            }
        }

        return true;
    }

    // Save item
    const saveItemHandle = () => {
        if (validate() && !creating) {
            setCreating(true);
            setError(null);

            Api.post('/api/items/add', createData)
                .then(() => {
                    setSuccess('Позиция добавлена');

                    // Сбросим форму
                    setCreateData(prevState => (
                        {
                            ...prevState,
                            name: '',
                            brand: ''
                        })
                    );

                    if (callback) {
                        callback();
                    }

                    setTimeout(() => {
                        setSuccess(null);
                    }, 5000);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setCreating(false);
                });
        }
    }

    return (
        <React.Fragment>
            <button className='btn btn-primary' onClick={handleShowModal}>
                Добавить позицию
            </button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="card">
                            <div className="card-body">
                                <h5>
                                    Новая позиция
                                </h5>
                                <div className="form-group">
                                    <label htmlFor="name">*Наименование:</label>
                                    <input type="text"
                                           id="name"
                                           className="form-control"
                                           name="name"
                                           value={createData.name}
                                           onChange={onChangeHandle} />
                                </div>
                                <div className="form-group">
                                    <label>Бренд:</label>
                                    <Autocomplete
                                        value={createData.brand}
                                        callback={updateItemBrand}
                                        endpoint="/api/suggestions/ItemsBrands"
                                        inputClassName="form-control"/>
                                    <div className="form-text text-muted">
                                        Название компании изготовителя
                                    </div>
                                </div>

                                {
                                    canEnterPrice &&
                                    <div className="form-group">
                                        <label htmlFor="price">Цена за единицу:</label>
                                        <input type="number"
                                               id="price"
                                               min={0}
                                               className="form-control"
                                               name="price"
                                               value={createData.price}
                                               onChange={onChangeHandle} />
                                    </div>
                                }

                                <div className="form-group">
                                    <label htmlFor="amountType">Ед. измерения:</label>
                                    <select name="amountType"
                                            className="form-control"
                                            id="amountType"
                                            value={createData.amountType}
                                            onChange={onChangeHandle}>
                                        {
                                            amountTypes.map(type =>
                                                <option key={type.key} value={type.key}>{type.label} ({type.short})</option>)
                                        }
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <h5>
                                    Поставщик позиции
                                </h5>
                                <div className="form-group">
                                    <label>Название:</label>
                                    <Autocomplete
                                        value={createData.supplier.name}
                                        callback={updateSupplier}
                                        endpoint="/api/suggestions/suppliers"
                                        inputClassName="form-control"/>
                                </div>
                                {
                                    isNewSupplier &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="supplier_brand">Бренд:</label>
                                            <input type="text"
                                                   id="supplier_brand"
                                                   className="form-control"
                                                   name="brand"
                                                   value={createData.supplier.brand}
                                                   onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="supplier_manager">Контактное лицо:</label>
                                            <input type="text"
                                                   id="supplier_manager"
                                                   className="form-control"
                                                   name="manager"
                                                   value={createData.supplier.manager}
                                                   onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="supplier_phone">Телефон:</label>
                                            <PhoneInput type="text"
                                                        international={true}
                                                        withCountryCallingCode={true}
                                                        defaultCountry="RU"
                                                        id="supplier_phone"
                                                        className="form-control"
                                                        name="phone"
                                                        value={createData.supplier.phone}
                                                        onChange={onChangeSupplierPhone}
                                                        placeholder="+7 ___ __ __"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="supplier_address">Адрес:</label>
                                            <textarea
                                                id="supplier_address"
                                                className="form-control"
                                                name="address"
                                                value={createData.supplier.address}
                                                onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="supplier_url">Сайт:</label>
                                            <input type="text"
                                                   id="supplier_url"
                                                   className="form-control"
                                                   name="url"
                                                   value={createData.supplier.url}
                                                   onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="supplier_note">Дополнительная информация по поставщику:</label>
                                            <textarea
                                                id="supplier_note"
                                                className="form-control"
                                                name="note"
                                                value={createData.supplier.note}
                                                onChange={onChangeSupplierHandle} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </form>

                    {
                        error &&
                        <Alert variant="danger">{error}</Alert>
                    }
                    {
                        success &&
                        <Alert variant="success">{success}</Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group">
                        {
                            !creating &&
                            <button className="btn btn-secondary mr-2" onClick={handleCloseModal}>
                                Закрыть
                            </button>
                        }

                        <button className="btn btn-primary" onClick={saveItemHandle}>
                            {
                                creating ?
                                    <div className='d-flex align-items-center'>
                                        <Spinner animation="border" variant="light" size="sm" />
                                        <span className='ml-2'>Сохранение</span>
                                    </div>
                                    :
                                    <>Добавить</>
                            }
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}