import React, { useState, useEffect, useMemo } from 'react';
import CreateProjectBtn from '../../components/buttons/createProject';
import Api from '../../helpers/axios';
import './style.scss';
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { Alert, Spinner } from "react-bootstrap";
import UserDetails from '../../components/userDetails';
import Pagination from '../../components/pagination';
import DotsMenu from '../../components/dotsMenu';
import ReportProjectItems from "../../components/reportProjectItems";
import EditProject from '../../components/buttons/editProject';
import roles from "../../helpers/roles";
import ExportProjectItemsData from "../../components/exportProjectItemsData";

export default () => {
    const isAdmin = roles.is(roles.administrator);
    const isSales = roles.is(roles.sales);
    const isProduction = roles.is(roles.production);
    const isStoreKeeper = roles.is(roles.storekeeper);
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [option, setOption] = useState({
        currentPage: 1,
        onPage: 30,
        desc: true
    });
    const [projects, setProjects] = useState([]);
    const [count, setCount] = useState(0);

    const updatePagination = (data) => {
        setOption(prevState => ({ ...prevState, currentPage: data }));
    };
        
    const getProjects = () => {
        if (!loading) {
            setLoading(true);
            setError(null);

            Api.post('/api/projects/perPage', option)
                .then(res => {
                    setProjects(res.data.projects);
                    setCount(res.data.count);
                })
                .catch(err => setError(err))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        getProjects();
    }, []);

    const onUpdateList = () => {
        getProjects();
    };

    useMemo(() => {
        getProjects();
    }, [option]);


    const onCloseProject = (e, project) => {
        e.preventDefault();

        if (confirm('Уверены, что хотите закрыть проект?')) {
            if (!loading) {
                setLoading(true);
                setError(null);

                Api.post('/api/projects/close', { id: project.id })
                    .then(() => {
                        getProjects();
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const onOpenProject = (e, project) => {
        e.preventDefault();

        if (confirm('Уверены, что хотите снова открыть проект?')) {
            if (!loading) {
                setLoading(true);
                setError(null);

                Api.post('/api/projects/open', { id: project.id })
                    .then(() => {
                        getProjects();
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };
    
    const onDeleteProject = (e, project) => {
        e.preventDefault();

        if (confirm('Действительно удалить проект?')) {
            if (confirm('Все данные по проекту будут удалены! Продолжить удаление?')) {
                if (!loading) {
                    setLoading(true);
                    setError(null);

                    Api.post('/api/projects/remove', { id: project.id })
                        .then(() => {
                            getProjects();
                        })
                        .catch(err => setError(err.response.data.message))
                        .finally(() => {
                            setLoading(false);
                        });
                }
            }
        }
    };

    return (
        <>
            <div className="container">
                <div className="d-md-flex w-100 text-center text-md-start">
                    <div>
                        <h2>
                            Проекты
                        </h2>
                        <div>
                            {
                                loading ?
                                    <div className="d-flex w-100 align-items-center">
                                        <Spinner animation="border" variant="warning" size="sm"/>
                                        <span className="text-muted ms-2">
                                        загрузка данных
                                    </span>
                                    </div>
                                    :
                                    <div className="text-muted">
                                        Проекты по состоянию
                                        на {format(new Date(), 'H:mm, d MMMM yyyy', {locale: ru})}<br/>
                                        Всего: {count}
                                    </div>
                            }
                        </div>
                    </div>

                    {
                        (isAdmin || isSales) &&
                        <div className="ms-auto mt-3 mt-md-0">
                            <CreateProjectBtn callback={onUpdateList}/>
                        </div>
                    }
                </div>

                {
                    error && <Alert variant="danger">{error}</Alert>
                }

                {
                    projects.length > 0 ?
                        <div className="mt-2">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>
                                        Проект:
                                    </th>
                                    <th>
                                        Менеджер:
                                    </th>
                                    <th>
                                        Дата создания:
                                    </th>
                                    {
                                        (isAdmin || isStoreKeeper) &&
                                        <th>
                                            Export
                                        </th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    projects.map(project =>
                                        <tr key={project.id} className={project.dateFactClose ? 'table-danger' : ''}>
                                            <td>
                                                <div className="d-flex w-100">
                                                    <div>
                                                        <div className="display-18 mb-1">
                                                            {project.name}
                                                        </div>
                                                        <div className="text-muted">
                                                            {project.description}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="ms-auto">
                                                        <DotsMenu>
                                                            {
                                                                (isAdmin || isSales || isProduction) &&
                                                                <EditProject
                                                                    callback={onUpdateList}
                                                                    project={project}
                                                                    btnLabel={'Изменить проект'}
                                                                    classNameBtn={'dropdown-dots-item'}
                                                                />
                                                            }
                                                            {
                                                                (isAdmin || isProduction || isStoreKeeper) &&
                                                                    <ReportProjectItems
                                                                        projectId={project.id}
                                                                        classNameBtn={"dropdown-dots-item"}
                                                                        btnLabel={'Выданные позиции'}
                                                                    />
                                                            }
                                                            {
                                                                (!project.dateFactClose && (isAdmin || isProduction)) &&
                                                                <a className="dropdown-dots-item"
                                                                   onClick={(e) => onCloseProject(e, project)}
                                                                   href="#">Завершить проект</a>
                                                            }
                                                            {
                                                                (isAdmin || isProduction) &&
                                                                <div className="dropdown-dots-danger">
                                                                    <div className="dropdown-dots-separator"/>
                                                                    {
                                                                        project.dateFactClose &&
                                                                        <a href="#open"
                                                                           className="dropdown-dots-item"
                                                                           onClick={e => onOpenProject(e, project)}>
                                                                            Перезапустить проект</a>
                                                                    }
                                                                    <a className="dropdown-dots-item"
                                                                       onClick={e => onDeleteProject(e, project)}
                                                                       href="#">
                                                                        Удалить</a>
                                                                </div>
                                                            }
                                                        </DotsMenu>
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                            <td>
                                                <UserDetails user={project.author}/>
                                            </td>
                                            <td>
                                                {format(new Date(project.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})}
                                            </td>
                                            {
                                                (isAdmin || isStoreKeeper) &&
                                                <td>
                                                    <ExportProjectItemsData project={project} />
                                                </td>
                                            }
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="text-muted mt-3 text-center">
                            Нет проектов
                        </div>
                }
                {
                    !loading &&
                    <Pagination
                        callback={updatePagination}
                        currentPage={option.currentPage}
                        onPage={option.onPage}
                        count={count}
                    />
                }

            </div>
        </>
    )
}