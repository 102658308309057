import React, {useState} from "react";
import Api from '../../helpers/axios';
import {Spinner, Alert} from "react-bootstrap";
import {ReactComponent as DocumentIcon} from "./document.svg";
import './style.scss';
import userHaveRole from "../../helpers/userHaveRole";
import {format} from "date-fns";
import {ru} from "date-fns/locale";

export default function InvoiceControl(props) {
    const [invoices, setInvoices] = useState(props.invoices || []);
    const supplierId = props.supplierId;
    const orderState = props.state || 0;
    const callback = props.callback;

    const [removing, setRemoving] = useState(false);
    const [error, setError] = useState(null);

    const deleteInvoiceHandle = (e, invoice) => {
        e.preventDefault();
        if (!removing) {
            if (confirm('Вы уверены, что хотите удалить счет?')) {
                setRemoving(true);
                setError(null);

                Api.post('/api/invoice/deleteInvoice', {id: invoice.id})
                    .then(() => {
                        callback();
                    })
                    .catch(err => {
                        setError(err.response.data.message);
                    })
                    .finally(() => {
                        setRemoving(false);
                    });
            }
        }
    };
    
    const [approving, setApproving] = useState(false)
    const onChangeApprove = (id, state) => {
        if (!approving) {
            setApproving(true)
            
            const url = state ? '/api/invoice/Approving' : '/api/invoice/NotApproved'
            
            Api.post(url, {id: id})
                .then(() => {
                    
                    const data = invoices.map(item => {
                        if (item.id === id) {
                            item.isApproved = state
                        }
                        return item
                    })
                    
                    setInvoices(data)
                    
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setApproving(false)
                })
            
        }
    }

    return <>
        {
            error && <Alert variant="danger">{error}</Alert>
        }
        <div className="d-flex align-items-center">
            <div className="me-2">
                <b>
                    Прикреплен счет:
                </b>
            </div>
            {
                invoices.filter(i => (i.supplierId === supplierId))
                    .map(invoice =>
                        <div key={invoice.id} className="d-flex align-items-center mr-2">
                            <a href={`${process.env.PUBLIC_URL}/upload/invoices/${invoice.filename}`}
                               className={`d-flex align-items-center invoice-document ${orderState !== 0 ? 'invoice-document--no-remove' : ''}`}
                               target="_blank"
                               rel="noopener noreferrer">
                                <span className="ms-1">
                                    {
                                        invoice.numberInvoice
                                            ?
                                            <span>
                                            Счет №{invoice.numberInvoice} от {format(new Date(invoice.dateInvoice), 'd MMMM yyyy г.', {locale: ru})}
                                            </span>
                                            :
                                            invoice.originalFilename
                                    }
                                </span>
                            </a>
                            {
                                orderState === 0 &&
                                <a href="#"
                                   title="Удалить"
                                   onClick={e => deleteInvoiceHandle(e, invoice)}
                                   className="invoice-remove-btn">
                                    {
                                        removing
                                            ? <Spinner variant="light" size="sm" animation="border" />
                                            : <>&times;</>
                                    }
                                </a>
                            }

                            {
                                (orderState >= 1 && invoice.isApproved) &&
                                <b className="ms-3 text-success">Счет утвержден</b>
                            }

                            {
                                (userHaveRole("Administrator") && orderState === 1) &&
                                    <div className="ms-3">
                                        {
                                            !approving ?
                                                <>
                                                    {
                                                        invoice.isApproved ?
                                                            <button
                                                                onClick={() => onChangeApprove(invoice.id, false)}
                                                                className="btn btn-sm btn-danger">
                                                                Снять с утверждения
                                                            </button>
                                                            :
                                                            <button
                                                                onClick={() => onChangeApprove(invoice.id, true)}
                                                                className="btn btn-sm btn-warning">
                                                                Утвердить счет
                                                            </button>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <Spinner animation="border" size="sm" variant="warning" />
                                                </>
                                        }
                                    </div>
                            }
                            
                            
                        </div>
                    )
            }
        </div>
    </>
}