import React, { Component } from 'react';

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
        state = {
            isDisconnected: false
        }

        componentDidMount() {
            this.handleConnectionChange();
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange);
            window.removeEventListener('offline', this.handleConnectionChange);
        }


        handleConnectionChange = () => {
            const pingUrl = location.origin;
            const condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') {
                const webPing = setInterval(
                    () => {
                        fetch(pingUrl, { mode: 'no-cors' })
                            .then(() => {
                                this.setState({ isDisconnected: false }, () => {
                                    return clearInterval(webPing)
                                });
                            }).catch(() => this.setState({ isDisconnected: true }) )
                    }, 5000);
                return;
            }

            return this.setState({ isDisconnected: true });
        }

        render() {
            const { isDisconnected } = this.state;
            return (
                <>
                    {
                        isDisconnected &&
                        <div className="internet-error">
                            <h3>Проблемы с интернет соединением...</h3>
                            <br/>
                            <p>
                                Кажется пропала связь с сервером
                            </p>
                            Проверьте ваше интернет соединение и обновите страницу
                        </div>
                    }
                    <ComposedComponent {...this.props} />
                </>
            );
        }
    }

    return NetworkDetector;
}