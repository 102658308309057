import {
    ADD_TO_PROD_REQUEST,
    REMOVE_FROM_PROD_REQUEST,
    CHANGE_AMOUNT_ITEM_PROD_REQUEST,
    REMOVE_PROD_REQUEST,
    CHANGE_PROJECT_REQUEST
} from "./actions";

const initialState = {
    products: [],
    projectId: '',
    sheetId: '',
    technicalTask: ''
}

export const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PROJECT_REQUEST:
            return {
                ...state,
                projectId: action.payload
            }

        case ADD_TO_PROD_REQUEST:
            // check duplicate
            if (state.products.find(x =>
                    x.product.id === action.payload.product.id &&
                    x.stock.id === action.payload.stock.id
            )) {
                return {
                    ...state
                }
            }
            return {
                ...state,
                products: [...state.products, action.payload]
            }

        case REMOVE_FROM_PROD_REQUEST:
            return {
                ...state,
                products: state.products.filter(item =>
                    !(
                        item.product.id === action.payload.productId &&
                        item.stock.id === action.payload.stockId
                    )
                )
            }

        case CHANGE_AMOUNT_ITEM_PROD_REQUEST:
            const id = action.payload.id
            const stockId = action.payload.stockId
            const amount = action.payload.value - 0 <= 0 ? 0 : action.payload.value - 0

            const itemState = state.products.find(x =>
                x.product.id === id &&
                x.stock.id === stockId)

            if (!itemState) {
                return state
            }

            if (amount === 0) {
                return {
                    ...state,
                    products: state.products.filter(item =>
                        item.product.id !== id &&
                        item.stock.id !== stockId)
                }
            }

            const productIndex = state.products.findIndex((x =>
                x.product.id === id &&
                x.stock.id === stockId))

            if (productIndex === -1) {
                return state
            }

            const data = Object.assign({}, state)
            data.products[productIndex].amount = amount

            return  {
                ...state,
                ...data
            }

        case REMOVE_PROD_REQUEST:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default requestReducer;