import {ADD_TO_STOCK, ITEMS_ON_PAGE_STOCK} from './actions';

const initialState = {
    added: false,
    onPage: 30
}

export const stocksReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_STOCK:
            return {
                ...state,
                added: action.payload
            }
        case ITEMS_ON_PAGE_STOCK:
            return {
                ...state,
                onPage: action.payload
            }
        default:
            return state;
    }
}

export default stocksReducer;