import React, {useState, useEffect} from "react";
import {Modal, Alert, Spinner} from "react-bootstrap";
import {getShortNameAmountType} from "../../../helpers/amountTypes";
import Api from '../../../helpers/axios';

export default function CapitalizeButton(props) {
    const position = props.position || null;
    const callback = props.callback;
    const amount = position.amount;
    const stocks = props.stocks;
    const [showModal, setShowModal] = useState(false);
    const initialData = {
        orderPosition: position,
        orderPositionId: position.id,
        itemId: position.item.id,
        stockId: null,
        capitalizeAmount: position.amount - position.capitalizeAmount,
        reason: '',
        reasonRequired: false
    }
    const [data, setData] = useState(initialData);
    
    useEffect(() => {
        setData(prevState => ({...prevState, reasonRequired: (position.amount - position.capitalizeAmount !== data.capitalizeAmount)}));
    }, [data.capitalizeAmount])

    const changeHandler = (e) => {
        let value = e.target.value - 0;
        if (value !== amount) {
            setData(prevState => ({...prevState, reasonRequired: true}));
        }
        else {
            setData(prevState => ({...prevState, reasonRequired: false}));
            setData(prevState => ({...prevState, reason: ''}));
        }

        if (value < 0) {
            value = 0;
        }

        setData(prevState => ({...prevState, capitalizeAmount: value}));
    }

    const changeReason = (e) => {
        setData(prevState => ({...prevState, reason: e.target.value}))
    }
    
    const changeStock = (e) => {
        setData(prevState => ({...prevState, stockId: e.target.value}))
    }

    const showModalHandle = () => {
        setShowModal(true);
    };

    const closeModalHandle = () => {
        setShowModal(false);
        setData(initialData);
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const validate = () => {
        
        if (!data.stockId) {
            setError('Выберите склад для оприходывания');
            return false;
        }
        
        return true;
    }

    const capitalizeHandle = () => {
        if (validate()) {
            if (!loading) {
                setLoading(true);
                setError(null);

                Api.post('/api/stocks/CapitalizeItemOnStock', data)
                    .then(() => {
                        callback();
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    }

    return <>

        <button
            onClick={showModalHandle}
            disabled={showModal}
            className="btn btn-sm btn-dark">
            Оприходовать
        </button>

        <Modal show={showModal}>
            <Modal.Body>
                <h5>
                    Заказанная позиция
                </h5>
                
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th>Наименование:</th>
                            <th>Кол-во:</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{position.item.name}</td>
                            <td>
                                {amount} {getShortNameAmountType(position.amountType)}
                                {
                                    position.capitalizeAmount > 0 &&
                                    <div>
                                        Оприходовано: {position.capitalizeAmount} {getShortNameAmountType(position.amountType)}
                                    </div>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="mb-3">
                    <label className="form-label" htmlFor="capitalizeAmount">Сколько оприходовать ({getShortNameAmountType(position.amountType)}):</label>
                    <input type="number"
                           className="form-control"
                           value={data.capitalizeAmount}
                           onChange={changeHandler} />
                </div>

                <div className="mb-3">
                    <label htmlFor="currentStock" className="form-label">На склад:</label>
                    <select
                        name="stockId"
                        id="currentStock"
                        className="form-select"
                        onChange={changeStock}
                        value={data.stockId}>
                        <option value={null}>- Выберите склад -</option>
                        {
                            stocks.map(stock =>
                                <option key={stock.id} value={stock.id}>
                                    {stock.name}
                                </option>)
                        }
                    </select>
                </div>

                {
                    data.reasonRequired &&
                    <div className="mb-3">
                        <label htmlFor="reason">
                            Почему оприходуется другое кол-во чем было в заявке?
                        </label>
                        <textarea
                            id="reason"
                            className="form-control"
                            placeholder={'Укажите причину'}
                            value={data.reason}
                            onChange={changeReason} />
                    </div>
                }

                <div className="mb-3 d-flex w-100">
                    <button
                        className="btn btn-primary"
                        disabled={data.reasonRequired && data.reason === ''}
                        onClick={capitalizeHandle}>
                        {
                            loading ?
                                <div className="d-flex align-items-center">
                                    <Spinner animation="border" size="sm" variant="light" />
                                    <span className="ms-2">Обработка</span>
                                </div> :
                                'Оприходовать'
                        }
                    </button>

                    {
                        !loading &&
                        <button
                            className="btn btn-dark ms-auto"
                            onClick={closeModalHandle}>
                            Отмена
                        </button>
                    }
                </div>

                {
                    error && <Alert variant="danger">{error}</Alert>
                }
            </Modal.Body>
        </Modal>

    </>
}