import {combineReducers} from 'redux';
import authReducer from './auth/reducers';
import userReducer from './user/reducers';
import itemReducer from './items/reducers';
import stocksReducer from './stocks/reducers';
import orderReducer from './order/reducers';
import requestReducer from './request/reducers';
import themeReducer from './theme/reducers';
import menuReducer from './menu/redicers';
import tasksReducer from "./tasks/reducers";
import ordersFiltersReducer from './orders/reducers';
import {LOG_OUT} from "./auth/actions";

const appReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    items: itemReducer,
    stocks: stocksReducer,
    order: orderReducer,
    orderFilters: ordersFiltersReducer,
    productionRequest: requestReducer,
    themes: themeReducer,
    mainMenu: menuReducer,
    tasks: tasksReducer
});

export const rootReducer = (state, action) => {
    if (action.type === LOG_OUT) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};