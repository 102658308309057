import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Api from "../../helpers/axios";
import {Alert} from "react-bootstrap";
import FetchState from "../../components/ui/fetchState";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {getShortNameAmountType} from "../../helpers/amountTypes";

export default () => {
    const {id} = useParams();
    const [fetch, setFetch] = useState(false);
    const [error, setError] = useState(null);
    const [order, setOrder] = useState(null);

    const onGetOrderHandle = () => {
        if (!fetch) {
            setFetch(true);

            Api.post('/api/requestOrders/getOrder', {id: id})
                .then(res => {
                    setOrder(res.data.body);
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setFetch(false);
                });
        }
    };

    useEffect(() => {
        onGetOrderHandle();
    }, []);
    
    return (
        <>
            {
                error && <Alert variant="danger">{error}</Alert>
            }

            {
                fetch ?
                    <FetchState loading={fetch} label="Загрузка данных" size="sm" variant="warning"/>
                    :
                    order && <div className="mt-3">
                        <div className="text-center">
                            <h2>
                                Выдача позиций со склада
                            </h2>
                            <h3>
                                Заявка № {order.orderNumber}
                            </h3>
                            <h6>
                                от {format(new Date(order.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})}
                            </h6>
                            <div className="mt-3 mb-2">
                                <span>Заявитель: {order.author.lastName} {order.author.firstName}</span>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <h4>
                                    <span className="mr-1">Назначение:</span>
                                    {
                                        order.project ?
                                            <Link to={`/project/${order.project.id}`}>
                                                {order.project.name}
                                            </Link>
                                            : 'Не указано'
                                    }
                                </h4>

                                <table className="table table-bordered flex-grow-1">
                                    <caption>
                                        Всего позиций: {order.positions.length} шт.
                                    </caption>
                                    <thead>
                                    <tr>
                                        <th>
                                            Позиция:
                                        </th>
                                        <th>
                                            Склад:
                                        </th>
                                        <th>
                                            Запрошено:
                                        </th>
                                        <th>
                                            Пометки:
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        order.positions.map(position =>
                                            <tr key={position.item.id}>
                                                <td>
                                                    {position.item.name}
                                                    {
                                                        position.item.brand && <div
                                                            className="small mt-1 text-muted">{position.item.brand}</div>
                                                    }
                                                </td>
                                                <td>
                                                    {position.stock.name}
                                                </td>
                                                <td>
                                                    {position.amount} {getShortNameAmountType(position.amountType)}
                                                </td>
                                                <td>
                                                    &nbsp;
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="mt-3">
                                                Получил: __________________________________________________
                                            </div>
                                            <div className="mt-3">
                                                Дата: __________________________________________________
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
            }
        </>
    )
};