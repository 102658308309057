import React, {useState, useEffect} from 'react'
import Api from '../../helpers/axios'
import {Modal, Alert, Spinner} from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field'
import formatCurrency from '../../helpers/formatCurrency'
import {getZaAmountType} from "../../helpers/amountTypes";

export default ({position, callback, orderState}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [show, setShow] = useState(false)
    const onHide = () => setShow(false)
    const onShow = (e) => {
        e.preventDefault()
        setShow(true)
    }
    const [data, setData] = useState(position)

    const onCancel = () => {
        setData(position)
        setShow(false)
    }

    const onChangePositionPriceHandle = (val) => {
        if (!val) {
            val = 0
        }
        setData(prevState => ({...prevState, newPrice: val}));
    }
    
    const onSaveChanges = () => {
        if(confirm('Сохранить изменения цены?')) {
            if(!loading) {
                setLoading(true)
                setError(null)
                
                Api.post('/api/orders/changePrice', data)
                    .then(() => {
                        onHide()
                        callback()
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }
    
    useEffect(() => {
        if (!position.newPrice) {
            setData(prevState => ({...prevState, newPrice: position.price}));
        }
    }, [])
    
    const delta = useState(Math.abs(data.newPrice - data.price).toFixed(2))
    const priceDirection = data.newPrice - data.price > 0 ? 'bigger' : data.newPrice - data.price === 0 ? 'normal' : 'lower'
    
    
    return (
        <>
            <div>
                <div className="display-18">{formatCurrency(data.newPrice)}</div>
                {
                    data.newPrice - data.price !== 0 &&
                    <div className="d-flex mt-1">
                        <div className="mr-1">
                            <span className="small text-muted">{formatCurrency(data.price)}</span>
                        </div>
                        <div className={`priceDir priceDir-${priceDirection}`}>
                            {
                                priceDirection === 'bigger' && <>&uarr;</>
                            }
                            {
                                priceDirection === 'lower' && <>&darr;</>
                            }
                            {formatCurrency(parseFloat(delta))}
                        </div>
                    </div>
                }
            </div>

            {
                (orderState === 0 || orderState === 1) &&
                <div className="small">
                    <a
                        onClick={onShow}
                        href="#edit">Изменить</a>
                </div>
            }
            
            <Modal show={show} onHide={onCancel}>
                <Modal.Body>
                    <h5>
                        Редактирование цены
                    </h5>
                    <div className="mb-4 text-muted">
                        Цена на товар должна соответствовать цене
                        указаной в прикрепленном счете от поставщика.
                    </div>
                    
                    <hr/>
                    
                    <div className="form-area">
                        <div className="mb-3">
                            <div className="display-18">
                                {data.item.name}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="positionPriceEdit">Цена (за {getZaAmountType(position.amountType)}):</label>
                            <CurrencyInput
                                id="positionPriceEdit"
                                className="form-control"
                                name="newPrice"
                                allowNegativeValue={true}
                                suffix=" ₽"
                                decimalSeparator="."
                                defaultValue={data.newPrice}
                                value={data.newPrice}
                                decimalsLimit={4}
                                onValueChange={onChangePositionPriceHandle}
                            />
                        </div>
                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }
                        <div className="mb-3">
                            <button
                                disabled={loading}
                                onClick={onSaveChanges}
                                className="btn btn-primary">
                                {
                                    loading ?
                                        <div className="d-flex align-items-center">
                                            <Spinner animation="border" size="sm" variant="light" />
                                            <span className="ml-2">Сохраняем</span>
                                        </div>
                                        :
                                        'Сохранить'
                                }
                            </button>
                            {
                                !loading &&
                                <button
                                    onClick={onCancel}
                                    className="btn btn-dark ms-2">Отмена</button>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}