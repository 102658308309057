import React, { useState, useEffect, useMemo } from "react";
import Api from '../../helpers/axios';
import { Alert, Spinner } from "react-bootstrap";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import getStatusOrder, { getStatusOrderStyle, orderStates } from "../../helpers/getStatusOrder";
import UserDetails from '../../components/userDetails';
import './style.scss';
import { Link } from "react-router-dom";
import Pagination from "../../components/pagination";
import { getShortNameAmountType } from "../../helpers/amountTypes";
import useDebounce from '../../helpers/hooks/useDebounce';
import roles from "../../helpers/roles";
import {useDispatch, useSelector} from "react-redux";
import {ordersFiltersAction, resetOrdersFiltersAction} from "../../store/orders/actions";
import {initialState as initialFiltersData} from '../../store/orders/reducers';
import FetchState from "../../components/ui/fetchState";
import SupplierDetails from '../../components/supplierDetails';
import OrderTag from '../../components/orderTags/tag';
import { Combobox } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

export default () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [orders, setOrders] = useState([]);
    const [count, setCount] = useState(0);
    
    const options = useSelector(state => state.orderFilters);

    const isAdmin = roles.is(roles.administrator);

    const [suppliers, setSuppliers] = useState([]);
    
    // Получить всех поставщиков
    const getSuppliers = () => {
        Api.post('/api/suppliers/GetAllSuppliers')
            .then(res => {
                setSuppliers(res.data.body)
            });
    };
    
    // Получить заявки
    const getOrders = () => {
        if (!loading) {
            setLoading(true);
            setError(null);

            Api.post('/api/orders/PerPage', options)
                .then(res => {
                    setOrders(res.data.body.orders);
                    setCount(res.data.body.count);
                    setError(null);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    };

    const refreshHandle = (e) => {
        e.preventDefault();
        getOrders();
    };
    
    const [filterNumber, setFilterNumber] = useState('')
    const onFilterByNumber = e => {
        setFilterNumber(e.target.value);
    };    

    useEffect(() => {
        getSuppliers();
        getOrders();
    }, []);

    const updatePagination = (data) => {
        dispatch(ordersFiltersAction({ page: data }));
    };
    
    const onFilterByStatus = (e) => {
        dispatch(ordersFiltersAction({ status: e.target.value }));
    };
    
    const onFilterBySupplier = (supplierId) => {
        dispatch(ordersFiltersAction({ supplierId: supplierId }));
    };

    useDebounce(() => {
        dispatch(ordersFiltersAction({ orderNumber: filterNumber }));
    }, 1000, [filterNumber]);
        
    useMemo(() => {
        getOrders();
    }, [options]);


    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [query, setQuery] = useState('');

    const [filteredSuppliers, setFilteredSuppliers] = useState([]);

    useEffect(() =>{
        if (suppliers.length > 0) {
            setFilteredSuppliers(query === ''
                ? suppliers
                : suppliers.filter((supplier) =>
                    supplier.name
                        .toLowerCase()
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                ));
        }
    }, [suppliers, query]);


    useEffect(() => {
        if (selectedSupplier) {
            onFilterBySupplier(selectedSupplier.id);
        }
        else {
            onFilterBySupplier('');
        }
    }, [selectedSupplier]);
    
    
    const onResetFilterHandle = e => {
        e.preventDefault();
        dispatch(resetOrdersFiltersAction());
        setSelectedSupplier(null);
    };
    
    const isHaveFilters = () => (JSON.stringify(options) === JSON.stringify(initialFiltersData));
    
    
    return (
        <div className="container">
            <div className="d-md-flex">
                <h2>
                    Заказы позиций
                </h2>
                {
                    isAdmin &&
                    <div className="ms-md-auto mt-2 mt-md-0">
                        <Link to="/reports/orders" className="btn btn-primary">Отчет</Link>
                    </div>
                }
            </div>
            
            <div className="mt-1">
                {
                    error &&
                    <Alert variant="danger">{error}</Alert>
                }

                {
                    (!loading && count > options.onPage) &&
                    <div className="mt-3">
                        <Pagination
                            callback={updatePagination}
                            currentPage={options.page}
                            onPage={options.onPage}
                            count={count}/>
                    </div>
                }
                
                {
                    orders.length > 0 &&
                    <div className="table-responsive">                       
                        <table className="table table-bordered">
                            <caption>
                                {
                                    loading ?
                                        <div className="d-flex w-100 align-items-center">
                                            <Spinner animation="border" variant="warning" size="sm"/>
                                            <span className="text-muted ms-2">
                                            Подгрузка данных
                                        </span>
                                        </div>
                                        :
                                        <>
                                        <div className="d-flex">
                                            <span className="me-2">
                                                Заявки на {format(new Date(), 'H:mm, d MMMM yyyy', {locale: ru})}
                                            </span>
                                            <a href="#"
                                               className="me-2"
                                               onClick={refreshHandle}>Обновить список</a>
                                        </div>
                                            {
                                                !isHaveFilters() &&
                                                <div className="d-flex align-items-center mt-2 alert alert-info">
                                                    <span className="me-2">
                                                        <strong>
                                                            К списку применены фильтры.
                                                        </strong>
                                                        <a href="#"
                                                           className="ms-2 btn btn-sm btn-outline-info"
                                                           onClick={onResetFilterHandle}>
                                                            Сбросить фильтры
                                                        </a>
                                                    </span>
                                                </div>
                                            }
                                    </>
                                }
                            </caption>
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Статус:
                                    </th>
                                    <th>
                                        Позиции:
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <input
                                            type="number"
                                            className="form-control"
                                            onChange={onFilterByNumber}
                                            value={filterNumber}
                                            placeholder="По номеру"
                                        />
                                    </th>
                                    <th>
                                        <select
                                            className="form-select"
                                            name="status"
                                            value={options.status}
                                            onChange={e => onFilterByStatus(e)}>
                                            <option value={''}>Все</option>
                                            {
                                                orderStates.map(item =>
                                                    <option key={item.key} value={item.key}>{item.label}</option>)
                                            }
                                        </select>
                                    </th>
                                    <th>
                                        {
                                            (suppliers && suppliers.length > 0) &&
                                            <div className="position-relative">
                                                <Combobox as="div" value={selectedSupplier} onChange={setSelectedSupplier}>
                                                    <div>
                                                        <Combobox.Input className="form-control"
                                                                        placeholder={"Поиск по поставщику"}
                                                                        displayValue={(supplier) => supplier ? supplier.name : ''}
                                                                        onChange={(event) => setQuery(event.target.value)} />
                                                    </div>
                                                    <div className="position-absolute z-100">
                                                        <Combobox.Options
                                                            className="list-group">
                                                            {
                                                                filteredSuppliers.length === 0 && query !== '' ?
                                                                    (
                                                                        <div className="list-group-item py-2">
                                                                            Поставщик не найден
                                                                        </div>
                                                                    )
                                                                    :
                                                                    <>
                                                                        <Combobox.Option
                                                                            value={null}
                                                                            className={({active}) =>
                                                                                `list-group-item cursor-pointer list-group-item-action ${
                                                                                    active ? 'list-group-item--hover' : ''
                                                                                }`}
                                                                        >
                                                                            ---
                                                                        </Combobox.Option>
                                                                    {
                                                                        filteredSuppliers.map((supplier) => (
                                                                            <Combobox.Option
                                                                                key={supplier.id}
                                                                                value={supplier}
                                                                                className={({active}) =>
                                                                                    `list-group-item cursor-pointer list-group-item-action ${
                                                                                        active ? 'list-group-item--hover' : ''
                                                                                    }`}
                                                                            >
                                                                                {
                                                                                    ({ selected, active }) => {
                                                                                        return (
                                                                                            <div className="d-flex">
                                                                                                <div className="icon-20-20 me-2">
                                                                                                    {
                                                                                                        selected &&
                                                                                                        <CheckIcon
                                                                                                            className="text-success icon-20-20"
                                                                                                            aria-hidden="true"/>
                                                                                                    }
                                                                                                </div>
                                                                                                <span className={(active || selected) ? 'text-strong' : ''}>
                                                                                                    {supplier.name}
                                                                                                </span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            </Combobox.Option>
                                                                        ))
                                                                    }
                                                                    </>
                                                            }
                                                        </Combobox.Options>
                                                    </div>
                                                </Combobox>
                                            </div>
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                orders.map(order =>
                                    {
                                        let statusCssClass = 'badge badge-pill py-2 px-2';
                                        
                                        switch (order.state) {
                                            case 0:
                                                statusCssClass += ' bg-dark';
                                                break;
                                            case 1:
                                                statusCssClass += ' bg-danger';
                                                break;
                                            case 3:
                                                statusCssClass += ' bg-primary';
                                                break;
                                            case 6:
                                                statusCssClass += ' bg-secondary';
                                                break;
                                            case 7:
                                            case 9:
                                                statusCssClass += ' bg-warning';
                                                break;
                                            case 8:
                                                statusCssClass += ' bg-success';
                                                break;
                                        }
                                        
                                        return <tr key={order.id}>
                                            <td>
                                                <div className="text-center">
                                                    {order.priority === 1
                                                        && <span className="badge badge-pill bg-danger mb-2">Высокая важность!</span>}
                                                    <div className="display-18">
                                                        Заявка&nbsp;<Link to={`/order/${order.id}`} className="link link-text link--underline">
                                                        №{order.orderNumber}
                                                    </Link>
                                                    </div>

                                                    <div className="pt-1 text-muted">
                                                        от {format(new Date(order.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})}
                                                    </div>
                                                </div>

                                                <div className="pt-3 d-flex justify-content-center">
                                                    <UserDetails user={order.author} showAvatar={true} />
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <span className={statusCssClass}>
                                                    {getStatusOrder(order.state)}
                                                </span>
                                            </td>
                                            <td>
                                                {
                                                    order.tags.length > 0 &&
                                                    <div className="pb-3 pt-2">
                                                        <div className="d-flex flex-wrap">
                                                        <span className="me-2">
                                                            <strong>Теги:</strong>
                                                        </span>
                                                            {
                                                                order.tags.map((tag, index) =>
                                                                    <OrderTag
                                                                        key={index}
                                                                        tag={tag.tag}
                                                                        canRemove={false}
                                                                        removeCallback={() => {}} />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (order.positions && order.positions.length > 0) &&
                                                    <div className="positions">
                                                        <div className="row positions-item-list">
                                                            <div className="col-12 col-md-6">Позиции:</div>
                                                            <div className="col-12 col-md-4">Поставщик:</div>
                                                            <div className="col-12 col-md-2">Кол-во:</div>
                                                        </div>
                                                        {
                                                            order.positions.map(position =>
                                                                <div className="row positions-item-list" key={position.item.id}>
                                                                    <div className="col-12 col-md-6">
                                                                        {position.item.name}
                                                                    </div>
                                                                    <div className="col-12 col-md-4">
                                                                        {
                                                                            position.item.supplier ?
                                                                                <SupplierDetails
                                                                                    supplier={position.item.supplier}/>
                                                                                :
                                                                                <>&mdash</>
                                                                        }
                                                                    </div>
                                                                    <div className="col-12 col-md-2">
                                                                        {position.amount} {getShortNameAmountType(position.item.amountType)}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                                <div className="pt-3">
                                                    <Link to={`/order/${order.id}`} className="btn btn-sm btn-dark">
                                                        Открыть заявку
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                }

                {
                    loading ?
                    <div className="py-3 d-flex w-100 justify-content-center">
                        <FetchState loading={loading} label="" size="lg" />
                    </div> :
                    orders.length === 0 &&
                    <div className="text-center py-5">
                        <div className="display-20 mb-3">
                            {
                                isHaveFilters() ?
                                "Заявки не найдены" : "По этим фильтрам ничего не найдено"
                            }
                        </div>
                        {
                            !isHaveFilters() &&
                            <div>
                                <a href="#"
                                   className="btn btn-dark"
                                   onClick={onResetFilterHandle}>
                                    Сбросить фильтры
                                </a>
                            </div>
                        }
                    </div>
                }

                {
                    (!loading && count > options.onPage) &&
                    <Pagination
                        callback={updatePagination}
                        currentPage={options.page}
                        onPage={options.onPage}
                        count={count}/>
                }
            </div>
        </div>
    )
}