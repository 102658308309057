/*
* Функция склонения слов по числу
* @function declensionOfNumber
* @global
* @param {int} - Число
* @param {array} - Массив склоненных слов для значений 1, 4, 5
* @example
* dayWord = declensionOfNumber(count, ['день', 'дня', 'дней']);
*/
export default function declensionOfNumber (number, titles) {
    const decCache = [];
    const decCases = [2, 0, 1, 1, 1, 2];
    if (!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
    return titles[decCache[number]];
}