import React, {useState, useRef} from 'react'
import './style.scss'
import {colors} from "../../../helpers/colors"
import {ReactComponent as DotsIcon} from "./dots.svg"
import {ReactComponent as CheckIcon} from "./check.svg"
import {ReactComponent as TrashIcon} from "./trash.svg"
import Api from '../../../helpers/axios'
import {Alert, Spinner} from 'react-bootstrap'

export default function EditFlowTag({tag, callback}) {
    const [data, setData] = useState({...tag})
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const inputTag = useRef()

    const onShowBoxHandle = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setShow(true)
    }

    const onHideBoxHandle = () => {
        setData({...tag})
        setError(null)
        setShow(false)
    }

    const onChangeTitleHandle = (e) => {
        setError(null)
        setData(prevState => ({...prevState, title: e.target.value}))
    }

    const onChangeColorHandle = (color) => {
        setError(null)
        setData(prevState => ({...prevState, color: color}))
    }

    const onUpdateTagHandler = () => {
        if (!loading) {

            setLoading(true)
            setError(null)

            Api.post('/api/FlowTag/Update', data)
                .then(() => {
                    callback(data)
                    setShow(false)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && data.title !== '') {
            e.preventDefault()
            onUpdateTagHandler()
        }
    }

    const onDelete = () => {
        if (confirm('Тег будет полностью удален и так же у всех ранее связанных с ним записей! Вы уверены, что хотите удалить тег?')) {
            if (!loading) {
                setLoading(true)
                setError(null)

                Api.post('/api/flowTag/Delete', data)
                    .then(() => {
                        callback(tag, true)
                        setShow(false)
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }

    return <div className="editTag-wrapper">
        <a
            className="openEditTagBtn"
            href="#"
            onClick={e => onShowBoxHandle(e)}>
            <DotsIcon/>
        </a>

        <div
            onClick={e => e.stopPropagation()}
            className={`editTag-box editTag-box--${show ? 'open' : 'close'}`}>
            {
                error && <Alert variant="danger">{error}</Alert>
            }

            {
                loading &&
                <div className="d-flex w-100 align-items-center justify-content-end pt-2 pr-4 position-absolute">
                    <Spinner animation="border" size="sm" variant="secondary"/>
                </div>
            }

            <div className="editTag-header">
                <div className="mb-3 px-2">
                    <input
                        ref={inputTag}
                        disabled={loading}
                        onKeyDown={handleKeyDown}
                        className="form-control form-control-sm"
                        type="text"
                        name="title"
                        onChange={onChangeTitleHandle}
                        value={data.title}/>
                </div>
                <div
                    onClick={onDelete}
                    className="editTag-removeBtn d-flex align-items-center">
                    <TrashIcon/>
                    <span className="ms-2">Удалить</span>
                </div>
            </div>

            <div className="colors-list">
                <div className="colors-list-title">
                    Цвет:
                </div>
                {
                    colors.map(color =>
                        <div
                            onClick={() => onChangeColorHandle(color.value)}
                            key={color.key}
                            className={`colors-list-item colors-list-item--${data.color === color.value ? 'active' : 'not-active'}`}>
                            <span className="colors-list-item-color" style={
                                {
                                    backgroundColor: color.value
                                }
                            }/>
                            <span>
                                {color.label}
                            </span>

                            {
                                data.color === color.value && <CheckIcon/>
                            }
                        </div>)
                }
            </div>

            <div className="editTag-footer">
                <button
                    disabled={loading}
                    className="btn btn-sm btn-primary"
                    onClick={onUpdateTagHandler}>
                    Сохранить
                </button>

                <button
                    className="btn btn-sm btn-dark ms-2"
                    onClick={onHideBoxHandle}>
                    Отмена
                </button>
            </div>
        </div>

    </div>
}