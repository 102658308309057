import React, {useState} from 'react';
import UserMenu from "../userMenu";
import {ReactComponent as MenuIcon} from "./icons/menu.svg";
import { Menu } from '@headlessui/react';
import styles from './style.module.scss';

export const Sidebar = () => {
    
    return (
        <div className={styles.sidebar}>
            <Menu>
                <Menu.Button as="div" className={styles.sidebarHeader}>
                    <div className={styles.sidebarMenuIcon}>
                        <MenuIcon/>
                    </div>
                    <div className="d-flex ms-3">
                        <span className="logoText">
                            {process.env.REACT_APP_SITE_TITLE}
                        </span>
                    </div>
                </Menu.Button>
                <Menu.Items as="div" className={styles.sidebarMenu}>
                    <UserMenu />
                </Menu.Items>
            </Menu>
        </div>
    )
};

export default Sidebar;