import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

export default class ErrorBoundary extends Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    };

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.log({error, errorInfo});
        this.setState({errorInfo});
    };

    render() {
        const {hasError, errorInfo} = this.state;

        if (hasError) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <div className="card my-5">
                                <div className="card-body text-center">
                                    <div className="mb-3">
                                        <img src="https://media4.giphy.com/media/xT9IgLlH1bHluWxsB2/200w.webp?cid=dda24d50b1de79c0c3c1ce8ad6fcf61c96cfba2cbf30b083&amp;rid=200w.webp&amp;ct=g"
                                             srcSet="https://media4.giphy.com/media/xT9IgLlH1bHluWxsB2/200w.webp?cid=dda24d50b1de79c0c3c1ce8ad6fcf61c96cfba2cbf30b083&amp;rid=200w.webp&amp;ct=g 200w,https://media4.giphy.com/media/xT9IgLlH1bHluWxsB2/giphy.webp?cid=dda24d50b1de79c0c3c1ce8ad6fcf61c96cfba2cbf30b083&amp;rid=giphy.webp&amp;ct=g 480w,"
                                             style={{width: '50%'}} alt="" />
                                    </div>
                                    <h3>
                                        Упс, что-то пошло не так...
                                    </h3>
                                    <br/>
                                    <p>
                                        При загрузке этой страницы произошла ошибка.<br/>
                                        Попробуйте <a href="#"
                                           onClick={e => {
                                               e.preventDefault();
                                               window.location.reload();
                                           }}>
                                            обновить страницу</a>
                                    </p>
                                    <p>
                                        Если обновление не помогает, попробуйте перезайти на сайт.
                                    </p>
                                    <p>
                                        <a href="/logout" className="btn btn-primary">Выйти и зайти снова</a>
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <details className="error-details">
                                        <summary>Нажмите, чтобы узнать подробности об ошибке</summary>
                                         {
                                            errorInfo &&
                                            <div className="py-2">
                                                {errorInfo.componentStack}
                                                <div className="mt-3">
                                                    Более подробно смотри в логах консоли
                                                </div>
                                            </div>
                                        }
                                    </details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};