export const flowTypes = [
    {
        label: 'Приход',
        value: 0
    },
    {
        label: 'Расход',
        value: 1
    }
]
    
export function getLabelFlowType(val) {
    const type = flowTypes.find(x => x.value === val);

    if (type) {
        return type.label
    }

    return null;
}