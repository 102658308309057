import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {removeOrderAction} from "../../store/order/actions";
import SupplierDetails from '../../components/supplierDetails';
import {Alert, Spinner} from "react-bootstrap";
import ChangeOrderPosition from '../../components/buttons/addOrder';
import Api from '../../helpers/axios';
import {Link} from "react-router-dom";
import {getLabelAmountType} from "../../helpers/amountTypes";
import OrderTags from "../../components/orderTags";

export default () => {
    const orderState = useSelector(state => state.order);
    const dispatch = useDispatch();
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [order, setOrder] = useState(orderState);

    const cancelOrderHandle = () => {
        if (confirm('Завяка будет полность удалена. Продолжить?')) {
            dispatch(removeOrderAction());
        }
    }
    
    const onChangeOrderTags = (tags) => {
        setOrder({...order, tags: tags});
    };

    const createOrderHandle = () => {
        if (!creating) {
            setCreating(true);
            setError(null);

            Api.post('/api/orders/create', order)
                .then(() => {
                    setSuccess('Заявка успешно отправлена');
                    dispatch(removeOrderAction());

                    setTimeout(()=>{
                        setSuccess(null);
                    }, 5000);
                })
                .catch((err) => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                   setCreating(false);
                });

        }
    }

    return (
        <div className="container">
            <h2>
                Заявка на заказ позиций
            </h2>
            <div className="mt-3">
                {
                    success && <Alert variant="success">{success}</Alert>
                }
                {
                    orderState.products.length ?
                        <>
                            <h5>Список позиций:</h5>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered">
                                            <caption>
                                                Всего позиций: {orderState.products.length}
                                            </caption>
                                            <thead>
                                            <tr>
                                                <th className="text-center">
                                                    Количество:
                                                </th>
                                                <th>
                                                    Наименование:
                                                </th>
                                                <th>
                                                    Поставщик:
                                                </th>
                                                <th>
                                                    На склад:
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                orderState.products.map(item =>
                                                    <tr key={item.product.id}>
                                                        <td>
                                                            <ChangeOrderPosition
                                                                product={item.product}
                                                                stock={item.stock}
                                                            />
                                                            <div className="mt-2 small text-center">
                                                                Ед. изм.: {getLabelAmountType(item.product.amountType)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {item.product.name}
                                                            </div>
                                                            <div className="mt-1 text-muted">
                                                                {item.product.brand && <>{item.product.brand}</>}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <SupplierDetails supplier={item.product.supplier} />
                                                        </td>
                                                        <td>
                                                            {
                                                                item.stock ? <>
                                                                        <div>
                                                                            <Link to={`/stock/${item.stock.id}`}>
                                                                                <strong>
                                                                                    {item.stock.name}
                                                                                </strong>
                                                                            </Link>
                                                                        </div>
                                                                        <div>
                                                                            {item.stock.address}
                                                                        </div>
                                                                    </>
                                                                    : <>&mdash;</>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <h5>Теги:</h5>
                                            <OrderTags tagsList={[]} callback={onChangeOrderTags} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {
                                error && <Alert variant="danger">{error}</Alert>
                            }
                            <div className="mt-3 d-flex w-100 align-items-center pb-3">
                                <button onClick={createOrderHandle}
                                        className="btn btn-primary">
                                    {
                                        creating ?
                                            <div className="d-flex align-items-center">
                                                <Spinner animation="border" variant="light" size="sm" />
                                                <span className="ms-2">
                                                    Отправляем
                                                </span>
                                            </div>
                                            :
                                            <>Отправить заявку</>
                                    }
                                </button>

                                {
                                    !creating &&
                                    <button className="btn btn-danger ms-2" onClick={cancelOrderHandle}>Отменить заявку</button>
                                }
                            </div>
                        </>
                        :
                        <div className="text-center p-5 text-muted">
                            <p>
                                Не выбраны позиции для оформления новой завки.
                            </p>
                            <Link to="/orders" className="btn btn-sm btn-primary">Заказы позиций</Link>
                        </div>
                }
            </div>
        </div>
    )
}