
export default function userHaveRole (roleName) {
    let result = false;

    if (localStorage.state) {
        const state = JSON.parse(localStorage.state);
        const roles = state.user.roles;

        if (roles.length) {
            result = roles.some((role) => {
                return role.name.toLowerCase() === roleName.toLowerCase();
            });
        }
    }

    return result;
}