import React, {useState, useEffect} from 'react'
import {Modal, Spinner, Alert} from "react-bootstrap"
import Api from '../../../helpers/axios'
import formatCurrency from "../../../helpers/formatCurrency";

export default ({bankAccountId}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [show, setShow] = useState(false)
    const [items, setItems] = useState([])
    const data = {bankAccountId: bankAccountId}

    const onShowHandle = (e) => {
        e.preventDefault()
        setShow(true)
    }
    const onHideHandle = () => {
        setShow(false)
    }

    const getItems = () => {
        if (!loading) {
            setLoading(true)
            setError(null)

            // Call api
            Api.post('/api/flow/GetDebetOrganizations', data)
                .then(res => {
                    setItems(res.data.body)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        if (show) {
            getItems()
        }
    }, [show])

    return (
        <>
            <a href="#" onClick={onShowHandle}>Показать должников</a>

            <Modal show={show} onHide={onHideHandle} size="xl">
                <Modal.Body>
                    {
                        error && <Alert variant="danger">{error}</Alert>
                    }
                    {
                        loading ?
                            <div className="d-flex align-items-center justify-content-center">
                                <Spinner animation="border" size="sm"/>
                                <span className="ml-2">Загрузка данных</span>
                            </div> :
                            <div>
                                <h3 className="mb-3">Дебиторская задолженность</h3>
                                
                                <div className="table-responsive">
                                <table className="table table-striped table-striped">
                                    <thead>
                                    <tr>
                                        <th>
                                            Контрагент:
                                        </th>
                                        <th>
                                            Долг:
                                        </th>
                                        <th>
                                            Счет:
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        items.map((item, i) =>
                                        <tr key={i}>
                                            <td>
                                                <div className="display-16 mb-2">
                                                    {item.organization.fullName}
                                                </div>
                                                <div>
                                                    Договор: {item.contract.name}<br/>
                                                    На сумму: {formatCurrency(item.contract.price)}
                                                </div>
                                                {
                                                    item.contract.files &&
                                                    <>
                                                        {
                                                            item.contract.files.map(file =>
                                                                <div key={file.id}>
                                                                    <a href={`/upload/contracts/${file.filename}`} target="_blank">
                                                                        {file.currentFilename}
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                <div>
                                                    {formatCurrency(item.contract.debet)}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {item.bankAccount.name}
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}