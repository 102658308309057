import {USER_CREATE, USER_REMOVE, USER_UPDATE_AVATAR} from './actions';

const initialState = {
    id: '',
    firstName: '',
    lastName: '',
    avatar: '',
    gender: 0,
    roles: []
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_CREATE:
            return {
                ...state,
                ...action.payload
            }
        case USER_REMOVE:
            return {
                ...state,
                id: '',
                firstName: '',
                lastName: '',
                avatar: '',
                gender: 0
            }
        case USER_UPDATE_AVATAR:
            return {
                ...state,
                avatar: action.payload.avatar
            }
        default:
            return state;
    }
}

export default userReducer;