import React, {useState, useEffect, useMemo} from "react";
import './style.scss';
import roles from "../../helpers/roles";
import Api from "../../helpers/axios";
import {Modal, Alert} from 'react-bootstrap';
import FetchState from "../ui/fetchState";
import Autocomplete from "../autocomplete";
import PhoneInput from "react-phone-number-input/input";
import {amountTypes, getShortNameAmountType} from "../../helpers/amountTypes";
import formatCurrency from "../../helpers/formatCurrency";
import useDebounce from "../../helpers/hooks/useDebounce";

export default function EditCraftItem({item, callback, classname, label}) {
    const isAdmin = roles.is(roles.administrator);
    const isStorekeeper = roles.is(roles.storekeeper);
    const [fetch, setFetch] = useState({
        saving: false,
        searching: false
    });
    const [error, setError] = useState('');
    const [data, setData] = useState(item);
    const [show, setShow] = useState(false);
    
    const onShow = e => {
        e.preventDefault();
        setShow(true);
    };
    
    const onHide = () => {
        setShow(false);
        setData(item);
    };

    const validate = () => {
        if(data.name === '') {
            setError('Укажите наименование позиции');
            return false;
        }
        if (data.craftChilds.length === 0) {
            setError('Укажите состав крафтового изделия');
            return false;
        }

        return true;
    };

    const saveChangesHandle = () => {
        if (!fetch.saving && validate()) {
            setFetch({...fetch, saving: true});
            setError(null);

            Api.post('/api/items/update', data)
                .then(() => {
                    setShow(false);
                    callback(data);
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setFetch({...fetch, saving: false});
                });
        }
    };

    // Обработка полей формы для поставщика
    const onChangeSupplierHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setData(prevState => ({ ...prevState, supplier: {...prevState.supplier, [name]: value} }));
    };

    // Change phoneNumber
    const onChangeSupplierPhone = (value) => {
        setData(prevState => ({ ...prevState, supplier: {...prevState.supplier, phone: value || ''} }));
    };

    // Get data from supplier autocomplete
    const updateSupplier = (selectedSupplier) => {
        setData(prevState => ({...prevState,
            supplier: {...prevState.supplier,
                id: selectedSupplier.id,
                name: selectedSupplier.label} }));
    };

    const [isNewSupplier, setIsNewSupplier] = useState(false);

    useMemo(()=>{
        if (data.supplier) {
            if (data.supplier.name !== '') {
                setIsNewSupplier(data.supplier.id === '');
            } else {
                setIsNewSupplier(false);
            }
        }
    }, [data.supplier]);

    const onChangeNameHandle = e => {
        setData({...data, name: e.target.value});
    };

    const [search, setSearch] = useState({
        fetch: false,
        query: ''
    });

    const [searchResults, setSearchResults] = useState({
        results: null,
        count: 0
    });

    const onSearchHandle = e => {
        setSearch({...search, query: e.target.value});
    };

    const convertToCraftItem = (item) => {
        return {
            child: item,
            childId: item.id,
            amount: 0
        }
    };

    const getCraftItems = (items) => {
        return items.map(x => convertToCraftItem(x));
    };

    const onFilterHandle = () => {
        if (!search.fetch) {

            setSearch({
                ...search,
                fetch: true
            });

            setError('');

            if (search.query) {
                Api.post('/api/items/filterItemsByName', {query: search.query})
                    .then(res => {
                        const data = res.data;
                        const craftItems = data.items.length > 0 ? getCraftItems(data.items) : [];

                        setSearchResults({
                            ...searchResults,
                            results: craftItems,
                            count: data.count
                        });
                    })
                    .catch(err => {
                        setError(err.response.data.message);
                    })
                    .finally(() => {
                        setSearch({...search, fetch: false});
                    });
            }
            else {
                setSearch({...search, fetch: false});
                setSearchResults({
                    ...searchResults,
                    results: null,
                    count: 0
                });
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (search.query) onFilterHandle();
        }
    };

    useDebounce(onFilterHandle, 600, [ search.query ]);

    const [errorCraft, setErrorCraft] = useState('');
    const addItemToCraftHandle = (e, craftItem) => {
        e.preventDefault();
        error && setError('');

        if (craftItem.amount <= 0) {
            setErrorCraft('Укажите количество');
            return;
        }
        
        const findItem = data.craftChilds.find(x => x.childId === craftItem.childId);
        
        if (findItem) {
            const temp = data.craftChilds.map(x => {
                if (x.childId === craftItem.childId) {
                    x.amount = (x.amount - 0) + (craftItem.amount - 0);
                }                
                return x;
            });
            
            setData({...data, craftChilds: temp});
        }
        else {
            setData({...data, craftChilds: [...data.craftChilds, craftItem]});
        }
        
        setSearchResults({
            ...searchResults,
            results: searchResults.results.filter(x => x.childId !== craftItem.childId),
            count: searchResults.count - 1
        });
    };
    const removeItemFromCraftHandle = (e, craftItem) => {
        e.preventDefault();
        error && setError('');
        
        setData({...data, craftChilds: data.craftChilds.filter(x => x.childId !== craftItem.childId)});

        if (searchResults.results !== null) {
            setSearchResults({
                ...searchResults,
                results: [...searchResults.results, craftItem],
                count: searchResults.count + 1
            });
        }
    };

    const changeCraftItemAmount = (e, itemId) => {
        const items = searchResults.results.map(x => {
            if (x.childId === itemId) {
                x.amount = e.target.value;
            }
            return x;
        });

        errorCraft && setErrorCraft('');

        setSearchResults({...searchResults, results: items, count: items.length});
    };

    const calculatePrice = () => {
        let price = 0;
        if (data.craftChilds.length > 0) {
            data.craftChilds.map(x => {
                price = price + (x.child.price * x.amount);
            });
        }

        setData({...data, price: price});
    };

    useMemo(() => {
        calculatePrice();
        if (searchResults.length === 0) {
            setSearch({...search, query: ''});
        }
    }, [data.craftChilds]);
    
    return (
        <>
            <a
                href="#edit"
                onClick={onShow}
                className={classname}>
                {label}
            </a>
            <Modal show={show} onHide={onHide} size={'xl'}>
                <Modal.Header closeButton>
                    <h5>
                        Редактирование позиции
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="name">*Наименование:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    value={data.name}
                                    onChange={onChangeNameHandle}
                                />
                            </div>
                            <div className="form-group">
                                <label>*Поставщик:</label>
                                <Autocomplete
                                    value={data.supplier ? data.supplier.name : ''}
                                    defaultId={data.supplierId}
                                    callback={updateSupplier}
                                    endpoint="/api/suggestions/suppliers"
                                    inputClassName="form-control"/>
                            </div>
                            {
                                isNewSupplier &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="supplier_manager">Контактное лицо:</label>
                                        <input type="text"
                                               id="supplier_manager"
                                               className="form-control"
                                               name="manager"
                                               value={data.supplier.manager}
                                               onChange={onChangeSupplierHandle} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="supplier_phone">Телефон:</label>
                                        <PhoneInput type="text"
                                                    international={true}
                                                    withCountryCallingCode={true}
                                                    defaultCountry="RU"
                                                    id="supplier_phone"
                                                    className="form-control"
                                                    name="phone"
                                                    value={data.supplier.phone}
                                                    onChange={onChangeSupplierPhone}
                                                    placeholder="+7 ___ __ __"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="supplier_address">Адрес:</label>
                                        <textarea
                                            id="supplier_address"
                                            className="form-control"
                                            name="address"
                                            value={data.supplier.address}
                                            onChange={onChangeSupplierHandle} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="supplier_url">Сайт:</label>
                                        <input type="text"
                                               id="supplier_url"
                                               className="form-control"
                                               name="url"
                                               value={data.supplier.url}
                                               onChange={onChangeSupplierHandle} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="supplier_note">Дополнительная информация по поставщику:</label>
                                        <textarea
                                            id="supplier_note"
                                            className="form-control"
                                            name="note"
                                            value={data.supplier.note}
                                            onChange={onChangeSupplierHandle} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5>Состав изделия</h5>
                            {
                                data.craftChilds.length === 0 ?
                                    <div className="text-muted py-3">
                                        Состав крафтовой позиции пустой
                                    </div>
                                    :
                                    <div className="table-responsive-x mb-4">
                                        <table className="table table-bordered table-hover table-sm">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Наименование:</th>
                                                <th>Кол-во:</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                data.craftChilds.map((x, i) =>
                                                    <tr key={i}>
                                                        <td className="text-center">
                                                            {x.child.itemNumber}
                                                        </td>
                                                        <td>
                                                            {x.child.name}
                                                            {
                                                                x.child.supplier &&
                                                                <div className="small text-muted">
                                                                    Поставщик: {x.child.supplier.name}
                                                                </div>
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {x.amount} {getShortNameAmountType(x.child.amountType)}
                                                        </td>
                                                        <td className="text-center">
                                                            <a href="#"
                                                               className="btn btn-sm btn-danger small"
                                                               onClick={e => removeItemFromCraftHandle(e, x)}>
                                                                Убрать
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                            }

                            {
                                searchResults.count === 0 &&
                                <div className="text-muted py-3">
                                    Добавьте позиции, из которых состоит изделие
                                </div>
                            }

                            <div className="form-group d-flex align-items-center">
                                <input
                                    id="search_item4craft"
                                    className="form-control flex-grow-1"
                                    type="search"
                                    placeholder="Найти по названию или номеру"
                                    onChange={onSearchHandle}
                                    onKeyDown={handleKeyDown}
                                    value={search.query}
                                />
                            </div>

                            {
                                search.fetch &&
                                <div className="mb-3">
                                    <FetchState loading={search.fetch} label="поиск позиций" size="sm" />
                                </div>
                            }
                            {
                                errorCraft &&
                                <div>
                                    <Alert variant="danger">{errorCraft}</Alert>
                                </div>
                            }
                            {
                                searchResults.results !== null &&
                                (
                                    searchResults.results.length > 0 ?
                                        <div>
                                            <div className="text-strong mb-2">
                                                Найденые позиции {`(${searchResults.count} шт.)`}:
                                            </div>
                                            <div className="table-responsive-x" style={{maxHeight: 260, overflowY: 'auto'}}>
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Наименование:</th>
                                                        <th>Кол-во:</th>
                                                        <th/>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        searchResults.results.map((itemResult) => {
                                                            return (
                                                                <tr key={itemResult.childId}>
                                                                    <td className="text-center">
                                                                        {itemResult.child.itemNumber}
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            {itemResult.child.name}
                                                                        </div>
                                                                        {
                                                                            itemResult.child.supplier &&
                                                                            <div className="small text-muted">
                                                                                Поставщик: {itemResult.child.supplier.name}
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <input
                                                                                className="form-control"
                                                                                type="number"
                                                                                style={{width: 120}}
                                                                                value={itemResult.amount}
                                                                                onChange={e => changeCraftItemAmount(e, itemResult.childId)}
                                                                            />
                                                                            <span className="ml-2">
                                                                            {getShortNameAmountType(itemResult.child.amountType)}
                                                                        </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="#"
                                                                           className="btn btn-sm small btn-dark"
                                                                           onClick={e => addItemToCraftHandle(e, itemResult)}>
                                                                            Добавить
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        search.query &&
                                        <div className="text-center">
                                            <Alert variant="info">
                                                По запросу <b>"{search.query}"</b> ничего больше не найдено
                                            </Alert>
                                        </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <div className="form-group">
                                <label>Стоимость: {formatCurrency(data.price)}</label>
                                <div className="text-muted">
                                    Стоимость расчитывается автоматически на основе позиций используемых в крафтовом изделии
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        error && <Alert variant="danger">{error}</Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        disabled={fetch.saving}
                        onClick={saveChangesHandle}
                        className="btn btn-primary">
                        {
                            fetch.saving ?
                                <FetchState
                                    loading={fetch.saving}
                                    label="Сохранение"
                                    size="sm"
                                    variant="light" />
                                :
                                'Сохранить'
                        }
                    </button>
                    <button
                        onClick={onHide}
                        className="btn btn-dark">
                        Отмена
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}