import React, {useState, useEffect, useMemo} from 'react'
import Api from '../../helpers/axios'
import {Spinner, Alert} from 'react-bootstrap'
import DatePicker from "react-datepicker"
import {format} from "date-fns"
import {ru} from "date-fns/locale"
import {Link} from "react-router-dom"
import formatCurrency from "../../helpers/formatCurrency";
import {getShortNameAmountType} from "../../helpers/amountTypes";

export default () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const date = new Date(), y = date.getFullYear(), m = date.getMonth()
    const [options, setOptions] = useState({
        startDate: new Date(y, m, 1),
        endDate: new Date(y, m + 1, 0)
    })
    const [data, setData] = useState({
        orders: [],
        count: 0
    })
    
    const getReport = () => {
        if(!loading) {
            setLoading(true)
            setError(null)
            
            Api.post('/api/orders/Report', options)
                .then(res => {
                    setData(prevState => ({
                        orders: res.data.body.orders,
                        count: res.data.body.count
                    }))
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(()=>{
        getReport()
    },[])
    
    useMemo(() => {
        getReport()
    }, [options])

    let totalPrice = 0
    let totalDelta = 0
    
    return (
        <div className="container">
            <p>
                <Link to="/orders">Заказы позиций</Link>
            </p>
            <div className="d-flex w-100">
                <h2>
                    Отчет по закупкам
                </h2>
            </div>
            <div>
                <div className="d-flex align-items-center">
                    <div>За период:&nbsp;</div>
                    <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="form-control"
                        selected={options.startDate}
                        onChange={(date) => setOptions(prevState => ({
                            ...prevState,
                            startDate: date
                        }))}
                        selectsStart
                        locale="ru"
                        dateFormat="d MMMM yyyy"
                        startDate={options.startDate}
                        endDate={options.endDate}
                    />
                    <span>&nbsp;-&nbsp;</span>
                    <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="form-control"
                        selected={options.endDate}
                        onChange={(date) => setOptions(prevState => ({
                            ...prevState,
                            endDate: date
                        }))}
                        selectsEnd
                        dateFormat="d MMMM yyyy"
                        locale="ru"
                        startDate={options.startDate}
                        endDate={options.endDate}
                        minDate={options.startDate}
                    />
                </div>
            </div>

            {
                error && <Alert variant="danger">{error}</Alert>
            }

            {
                loading &&
                <div className="d-flex align-items-center mt-3">
                    <Spinner animation="border" size="sm" variant="warning" />
                    <span className="ml-2">Формирование отчета</span>
                </div>
            }

            {
                (!loading && data.count > 0) &&
                    <div className="table-responsive mt-3">
                        <table className="table table-bordered table-hover table-sm">
                            <caption>
                                Всего позиций: {data.count}
                            </caption>
                            <thead>
                            <tr>
                                <th>Дата заявки:</th>
                                <th>Название:</th>
                                <th>Кол-во:</th>
                                <th>Цена:</th>
                                <th>Стоимость:</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.orders.map(order =>
                                    {
                                        return (
                                            <React.Fragment key={order.id}>
                                                {
                                                    order.positions.map(pos => {
                                                        const delta = Math.abs(pos.newPrice - pos.price).toFixed(2)
                                                        const priceDirection = pos.newPrice - pos.price > 0 ? 'bigger' : pos.newPrice - pos.price === 0 ? 'normal' : 'lower'
                                                        const positionPrice = pos.amount * pos.newPrice

                                                        totalPrice = totalPrice + positionPrice
                                                        
                                                        if (priceDirection === 'bigger') {
                                                            totalDelta = totalDelta + parseFloat(delta)
                                                        }
                                                        else if(priceDirection === 'lower') {
                                                            totalDelta = totalDelta - parseFloat(delta)
                                                        }
                                                        
                                                        return <tr key={pos.id}>
                                                            <td>
                                                                {format(new Date(order.dateCreate), 'd MMMM yyyy', {locale: ru})}
                                                            </td>
                                                            <td>
                                                                {pos.item.name}
                                                            </td>
                                                            <td>{pos.amount} {getShortNameAmountType(pos.amountType)}</td>
                                                            <td>
                                                                <div>{formatCurrency(pos.newPrice)}/{getShortNameAmountType(pos.amountType)}</div>
                                                                {
                                                                    pos.newPrice - pos.price !== 0 &&
                                                                    <div className="d-flex mt-1">
                                                                        <div className="mr-1">
                                                                            <span className="small text-muted">{formatCurrency(pos.price)}</span>
                                                                        </div>
                                                                        <div className={`priceDir priceDir-${priceDirection}`}>
                                                                            {
                                                                                priceDirection === 'bigger' && <>&uarr;</>
                                                                            }
                                                                            {
                                                                                priceDirection === 'lower' && <>&darr;</>
                                                                            }
                                                                            {formatCurrency(parseFloat(delta))}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {formatCurrency(positionPrice)}
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    } 
                                )
                            }
                            <tr>
                                <td colSpan={3} className="text-right pt-3 pb-3"><b>Итого:</b></td>
                                <td className="text-left pt-3 pb-3">
                                    <div className="d-flex align-items-center">
                                        <span>Дельта:</span>
                                        <div className={`ml-2 priceDir priceDir-${totalDelta > 0 ? 'bigger' : totalDelta === 0 ? '' : 'lower' }`}>
                                            {
                                                totalDelta > 0 ? <>&uarr;</> : totalDelta === 0 ? '' : <>&darr;</>
                                            }
                                            {formatCurrency(Math.abs(totalDelta))}
                                        </div>
                                    </div>
                                </td>
                                <td className="text-left pt-3 pb-3">{formatCurrency(totalPrice)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
            }
            {
                data.count === 0 &&
                    <div className="display-18 text-muted mt-4">
                        <hr/>
                        <p>
                            Отчетный период c&nbsp;
                            {format(new Date(options.startDate), 'd MMMM yyyy', {locale: ru})}
                            &nbsp;по&nbsp;
                            {format(new Date(options.endDate), 'd MMMM yyyy', {locale: ru})}.
                        </p>
                        <p>
                            Нет данных, попробуйте изменить период отчета.
                        </p>
                    </div>
            }
            
        </div>
    )
}