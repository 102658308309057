import React, {useState, useEffect, useMemo} from "react";
import Api from '../../helpers/axios';
import './style.scss';
import {Spinner} from 'react-bootstrap';

export default (props) => {
    const defaultValue = props.value || '';
    const defaultId = props.defaultId || '';
    const defaultBody = props.defaultBody || {};
    const inputClassName = props.inputClassName || '';
    const endpoint = props.endpoint || null;
    const callback = props.callback || undefined;
    const extended = props.extended || false;
    const template = props.template || (data => (<>{data}</>));
    const params = props.params || {};
    const placeholder = props.placeholder || '';

    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // Признак того, что suggestion-api возвращает какие-то резульаты
    const [hasResults, setHasResults] = useState(true);

    // Последний запрос, при котором были результаты
    const [lastQuery, setLastQuery] = useState(null);

    const initialData = {
        id: defaultId,
        label: defaultValue,
        body: defaultBody
    };

    const [selectedSuggestion, setSelectedSuggestion] = useState(initialData);

    useMemo(() => {
        // Если поле было очищено или значение defaultValue - пустое
        if (defaultValue === '') {
            setSelectedSuggestion(initialData);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (typeof callback === 'function') {
            callback(selectedSuggestion);
        }
    }, [selectedSuggestion]);
    
    

    const onChange = e => {
        let inputValue = e.target.value;
        let sendRequest = true //hasResults || (lastQuery === inputValue);

        setSelectedSuggestion(prevState => ({...prevState,
            label: inputValue,
            id: ''}
        ));

        if (inputValue === '') {
            setHasResults(true);
            setLastQuery(null);
        }

        if (sendRequest) {
            setLoading(true);
            setError('');
            setLastQuery(inputValue);

            Api.post(endpoint, {query: inputValue, params: params})
                .then(res => {
                    setFilteredSuggestions(res.data);
                    setShowSuggestions(true);

                    if (res.data.length) {
                        setHasResults(true);
                        // setLastQuery(inputValue);
                    } else {
                        if (inputValue !== '') {
                            setHasResults(false);
                        }
                    }
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const onClick = (suggestion) => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);

        setSelectedSuggestion({
            label: suggestion.label,
            id: suggestion.id,
            body: suggestion.body
        });
    }

    const onKeyDown = (e) => {
        // Enter
        if (e.keyCode === 13) {
            let filteredSuggestion = filteredSuggestions[activeSuggestion];

            if (filteredSuggestion) {
                setSelectedSuggestion({
                    label: filteredSuggestion.label,
                    id: filteredSuggestion.id,
                    body: filteredSuggestion.body
                });
            } else {
                setSelectedSuggestion({
                    label: e.currentTarget.value,
                    id: '',
                    body: {}
                });
            }

            setActiveSuggestion(0);
            setShowSuggestions(false);
        }
        // Arrow Up
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            setActiveSuggestion(activeSuggestion - 1);
        }
        // Arrow down
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1);
        }
    }

    return (
        <React.Fragment>
            {
                endpoint &&
                <div className='suggestions-wrapper'>
                    <input
                        type="text"
                        placeholder={placeholder}
                        className={inputClassName}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={selectedSuggestion.label}
                    />

                    {
                        (showSuggestions && selectedSuggestion.label) &&
                        <ul className="suggestions">
                            {
                                filteredSuggestions.map((suggestion, index) => {
                                    let className;

                                    // Flag the active suggestion with a class
                                    if (index === activeSuggestion) {
                                        className = "suggestion-active";
                                    }

                                    return (
                                        <li className={className}
                                            key={suggestion.id}
                                            onClick={() => { onClick(suggestion) }}>
                                            {template(extended ? suggestion.body : suggestion.label)}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    }
                    {
                        loading &&
                        <div className='suggestions-status'>
                            <Spinner animation="border" size="sm" variant="warning" />
                        </div>
                    }
                </div>
            }
            {
                error && <div className='suggestions-error'>
                    {error}
                </div>
            }
        </React.Fragment>
    );
}