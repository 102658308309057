import {ITEM_CREATE_EVENT, ITEMS_ON_PAGE} from './actions';

const initialState = {
    createEvent: false,
    onPage: 30
}

export const itemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ITEM_CREATE_EVENT:
            return {
                ...state,
                createEvent: action.payload
            }
        case ITEMS_ON_PAGE:
            return {
                ...state,
                onPage: action.payload
            }
        default:
            return state;
    }
}

export default itemsReducer;