import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import Api from '../../helpers/axios';
import {Alert, Spinner} from "react-bootstrap";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import getStatusOrder from "../../helpers/getStatusOrder";
import UserDetails from '../../components/userDetails';
import SupplierDetails from '../../components/supplierDetails';
import StockDetails from '../../components/stockDetails';
import './style.scss';
import _ from 'lodash';
import UploadInvoice from "../../components/uploadInvoice";
import InvoiceControl from '../../components/invoiceControl';
import {Redirect} from 'react-router-dom';
import OrderHistory from "../../components/orderHistory";
import CapitalizeButton from "../../components/buttons/capitalizePosition";
import {getShortNameAmountType} from "../../helpers/amountTypes";
import ChangePriceControl from './changePriceOrder'
import AmountControl from './changeAmount'
import roles from "../../helpers/roles";
import declensionOfNumber from "../../helpers/declensionOfNumber";
import OrderTag from '../../components/orderTags/tag';
import OrderTags from '../../components/orderTags';
import FetchState from "../../components/ui/fetchState";
import orderPriority from "./orderPriority";

export default () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [order, setOrder] = useState(null);
    const [positions, setPositions] = useState(null);
    const [stocks, setStocks] = useState([]);
    const [requiredCountInvoice, setRequiredCountInvoice] = useState(0);
    const [redirectToOrders, setRedirectToOrders] = useState(false);
    const [orderLastHistory, setOrderLastHistory] = useState(null);

    const isAdmin = roles.is(roles.administrator);
    const isStorekeeper = roles.is(roles.storekeeper);
    const isPurchaseDepartment = roles.is(roles.purchaseDepartment);
    const isFinancialDepartment = roles.is(roles.financialDepartment);
    const isViewOrder = roles.is(roles.viewOrders);
    
    const canCapitalize = isAdmin || isStorekeeper;
    const canViewPrice = isAdmin || isPurchaseDepartment || isFinancialDepartment;
    const canDelete = isAdmin || isPurchaseDepartment;
    const canEditTags = isAdmin || isPurchaseDepartment || isStorekeeper;
    // Могут видеть остаток на складе
    const canViewRemain = isAdmin || isPurchaseDepartment || isStorekeeper;

    const getOrder = (id) => {
        if (!loading) {
            setLoading(true);
            setError(null);

            Api.post('/api/orders/getOrder', {id: id})
                .then(res => {
                    setOrder(res.data.body.order);
                    setOrderLastHistory(res.data.body.lastOrderHistory);
                    setStocks(res.data.body.stocks);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const groupPosition = (positions) => {
        let result = _.chain(positions)
            .groupBy('item.supplier.id')
            .toPairs()
            .map(function (pair) {
                return _.zipObject(['supplierId', 'items'], pair);
            })
            .value();
        setRequiredCountInvoice(result.length);
        return result;
    }

    const getTotalPrice = (items) => {
        return items.reduce((acc, item) => acc + (item.newPrice * item.amount), 0);
    }

    useEffect(() => {
        getOrder(id);
    }, []);

    useEffect(() => {
        if (order) {
            setPositions(groupPosition(order.positions));
        }
    }, [order]);

    // Обновление заявки при удалении / прикрепленного счета
    const changeInvoices = () => {
        getOrder(id);
    }

    const initialChangeState = {
        orderId: id,
        note: '',
        priority: orderPriority.normal
    }
    const [changeStateData, setChangeStateData] = useState(initialChangeState);
    const [changingState, setChangingState] = useState(false);
    const [changingStateError, setChangingStateError] = useState('');

    const ChangeOrderStateHandle = (stateName) => {
        if (!changingState) {
            setChangingState(true);
            setChangingStateError(null);

            Api.post('/api/orders/changeState', {...changeStateData, state: stateName})
                .then(() => {
                    getOrder(id);
                    setChangeStateData(initialChangeState);
                })
                .catch(err => {
                    setChangingStateError(err.response.data.message);
                })
                .finally(() => {
                    setChangingState(false);
                });
        }
    }

    const capitalizeUpdate = () => {
        getOrder(id);
    }

    const onChangePositionHandle = () => {
        getOrder(id);
    }

    const onDeleteHandle = () => {
        if (confirm('Действительно отправить заявку в архив?')) {

            if (!loading) {
                setLoading(true)
                setError(null)

                Api.post('/api/orders/SetArchived', {id: id})
                    .then(() => {
                        document.location.assign('/orders')
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }
    
    const [firstRender, setFirstRender] = useState(true);
    const [updateTags, setUpdateTags] = useState(false);
    
    // Обновление тегов у заявки
    const onUpdateTagsHandle = (tags) => {
        if (!firstRender) {
            const data = {
                orderId: id,
                tags: tags
            };
            if (!updateTags) {
                setUpdateTags(true);
                
                Api.post('/api/orders/UpdateOrderTags', data)
                    .then(() => {
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setUpdateTags(false);
                    });
            }
        }
        else {
            setFirstRender(false);
        }
    };
    
    const getTagsList = (orderToTags) => {
        return orderToTags.map(x => x.tag);
    }
    
    const onChangeNote = e => {
        setChangeStateData({...changeStateData, note: e.target.value});
    };

    return (
        <div className="container">
            {
                redirectToOrders && <Redirect push to='/orders'/>
            }

            <p>
                <Link to='/orders'>Список заявок</Link>
            </p>

            {
                error && <Alert variant="danger">{error}</Alert>
            }
            {
                loading ?
                    <FetchState loading={loading} label="Загрузка данных" size="sm" />
                    :
                    <div>
                        {
                            order &&
                            <div className="row mt-3">
                                <div className="col-12 col-md-9">
                                    <div className="order-header">
                                        {order.priority === 1
                                            && <span className="badge badge-pill bg-danger mb-2">Высокая важность!</span>}
                                        <h3>
                                            Заявка №{order.orderNumber}
                                        </h3>
                                        <h6>
                                            от {format(new Date(order.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})},
                                            статус: <strong>{getStatusOrder(order.state)}</strong>
                                        </h6>
                                        <div className="d-flex align-items-center mt-3">
                                            <span className="me-2">Заявитель:</span>
                                            <UserDetails user={order.author}/>
                                        </div>
                                    </div>

                                    {
                                        positions.map(groupPosition =>
                                            <div key={groupPosition.supplierId} className="card mb-3">
                                                <div className="card-body">
                                                    <h4 className="card-title">
                                                        Поставщик: <SupplierDetails
                                                        supplier={groupPosition.items[0].item.supplier}/>
                                                    </h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <caption>
                                                                Всего позиций: {groupPosition.items.length}
                                                            </caption>
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>
                                                                    Позиция:
                                                                </th>
                                                                <th>
                                                                    Склад:
                                                                </th>
                                                                {
                                                                    canViewRemain &&
                                                                    <th>
                                                                        На&nbsp;балансе:
                                                                    </th>
                                                                }
                                                                {
                                                                    canViewPrice &&
                                                                    <th>
                                                                        Цена:
                                                                    </th>
                                                                }
                                                                <th>
                                                                    Заказано:
                                                                </th>
                                                                {
                                                                    canViewPrice &&
                                                                    <th>
                                                                        Стоимость:
                                                                    </th>
                                                                }
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                groupPosition.items.map(position =>
                                                                    <tr key={position.item.id}>
                                                                        <td>
                                                                            {position.item.itemNumber}
                                                                        </td>
                                                                        <td>
                                                                            {position.item.name}
                                                                            {
                                                                                position.item.brand && <div
                                                                                    className="small mt-1 text-muted">{position.item.brand}</div>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <StockDetails stock={position.stock}/>
                                                                        </td>
                                                                        {
                                                                            canViewRemain &&
                                                                            <td>
                                                                                {
                                                                                    position.item.stockItems.length > 0 ?
                                                                                        position.item.stockItems.map(stock =>
                                                                                            <React.Fragment key={position.id}>
                                                                                                {
                                                                                                    position.stock.id === stock.stockId &&
                                                                                                    <div>
                                                                                                        {stock.amount} {getShortNameAmountType(position.amountType)}
                                                                                                    </div>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                        :
                                                                                        <>
                                                                                            Нет данных
                                                                                        </>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            canViewPrice &&
                                                                            <td>
                                                                                <ChangePriceControl
                                                                                    callback={onChangePositionHandle}
                                                                                    position={position}
                                                                                    orderState={order.state}
                                                                                />
                                                                            </td>
                                                                        }
                                                                        <td>
                                                                            <div>
                                                                                <div>
                                                                                    <AmountControl
                                                                                        positionName={position.item.name}
                                                                                        amount={position.amount}
                                                                                        amountType={position.item.amountType}
                                                                                        orderId={id}
                                                                                        positionId={position.id}
                                                                                        orderState={order.state}
                                                                                        callback={onChangePositionHandle}
                                                                                    />

                                                                                    {
                                                                                        position.capitalizeAmount > 0 &&
                                                                                        <div className="mt-3 small">
                                                                                            Оприходовано: {position.capitalizeAmount}&nbsp;{getShortNameAmountType(position.amountType)}
                                                                                            {
                                                                                                (position.amount - position.capitalizeAmount !== 0) &&
                                                                                                <div
                                                                                                    className="text-danger">
                                                                                                    Несоответствие в&nbsp;
                                                                                                    {position.amount - position.capitalizeAmount}&nbsp;
                                                                                                    {getShortNameAmountType(position.amountType)}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        position.reason &&
                                                                                        <div className="mt-1">
                                                                                            {position.reason}
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                {
                                                                                    ((order.state === 7 || order.state === 9)
                                                                                        && position.amount !== position.capitalizeAmount
                                                                                        && canCapitalize) &&
                                                                                    <div className="pt-2">
                                                                                        <CapitalizeButton
                                                                                            callback={capitalizeUpdate}
                                                                                            position={position}
                                                                                            stocks={stocks}/>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            canViewPrice &&
                                                                            <td>
                                                                                {
                                                                                    new Intl.NumberFormat('ru-RU', {
                                                                                        style: 'currency',
                                                                                        currency: 'RUB'
                                                                                    }).format(position.newPrice * position.amount)
                                                                                }
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                canViewPrice &&
                                                                <tr>
                                                                    <td colSpan={canCapitalize ? 6 : 5} className="text-right">Итого:</td>
                                                                    <td colSpan={2}>
                                                                        <strong>
                                                                            {
                                                                                new Intl.NumberFormat('ru-RU', {
                                                                                    style: 'currency',
                                                                                    currency: 'RUB'
                                                                                }).format(getTotalPrice(groupPosition.items))
                                                                            }
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {
                                                        (order.state !== 6 && canViewPrice) &&
                                                        <div>
                                                            {
                                                                order.invoices.some(x => x.supplierId === groupPosition.supplierId)
                                                                    ?
                                                                    <InvoiceControl
                                                                        invoices={order.invoices}
                                                                        supplierId={groupPosition.supplierId}
                                                                        state={order.state}
                                                                        callback={changeInvoices}
                                                                    />
                                                                    :
                                                                    <UploadInvoice
                                                                        orderId={order.id}
                                                                        supplierId={groupPosition.supplierId}
                                                                        callback={changeInvoices}
                                                                    />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        (orderLastHistory && orderLastHistory.note) &&
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <strong>
                                                        Комментарий с предыдущего этапа:
                                                    </strong>
                                                </div>
                                                <div className="card-text">
                                                    <div className="text-muted small mb-1">
                                                        {format(new Date(orderLastHistory.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})}
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <UserDetails user={orderLastHistory.user} showAvatar={false}/>
                                                        <div>
                                                            : <b>{getStatusOrder(orderLastHistory.state)}</b> - {orderLastHistory.note}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        (!isViewOrder && order.state !== 8) &&
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>
                                                    Работа с заявкой
                                                </h4>
                                                <div className="mb-3">
                                                    Текущий этап: <strong>{getStatusOrder(order.state)}</strong>
                                                </div>
                                                {
                                                    (order.state !== 9 && order.state !== 7) &&
                                                    <div>
                                                        <div className="mb-3">
                                                            {
                                                                /* Кнопка на утрверждение */
                                                                (order.state === 0 && (isAdmin || isPurchaseDepartment) && order.invoices.length >= requiredCountInvoice) &&
                                                                <div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Комментарий:</label>
                                                                        <textarea
                                                                            value={changeStateData.note}
                                                                            onChange={onChangeNote}
                                                                            placeholder="Добавьте комментарий, если нужно"
                                                                            className="form-control mt-2"/>
                                                                    </div>
                                                                    <div className="mb-3 mt-3 col-12 col-md-4">
                                                                        <label htmlFor="priority">Важность заявки:</label>
                                                                        <select
                                                                            value={changeStateData.priority}
                                                                            onChange={e => setChangeStateData(prevState => ({
                                                                                ...prevState,
                                                                                priority: e.target.value - 0
                                                                            }))}
                                                                            name="priority"
                                                                            className="form-select"
                                                                            id="priority">
                                                                            <option value={orderPriority.normal}>Обычная</option>
                                                                            <option value={orderPriority.high}>Высокая</option>
                                                                        </select>
                                                                    </div>
                                                                    <button onClick={() => ChangeOrderStateHandle('OnApproval')}
                                                                            className="btn btn-primary">
                                                                        {
                                                                            changingState
                                                                                ? <FetchState loading={changingState} variant="light" label="Обработка" />
                                                                                : 'Отправить на утверждение'
                                                                        }
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                /* Кнопка Утвердить */
                                                                (order.state === 1 && isAdmin) &&
                                                                <>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Комментарий:</label>
                                                                    <textarea
                                                                        value={changeStateData.note}
                                                                        onChange={onChangeNote}
                                                                        placeholder="Добавьте комментарий, если нужно"
                                                                        className="form-control mt-2"/>
                                                                </div>
                                                                <button onClick={() => ChangeOrderStateHandle('Approved')}
                                                                        className="btn btn-primary">
                                                                    {
                                                                        changingState
                                                                            ? <FetchState loading={changingState} variant="light" label="Обработка" />
                                                                            : 'Утвердить заявку'
                                                                    }
                                                                </button>
                                                                </>
                                                            }
                                                            {
                                                                /* Кнопка На оплату */
                                                                (order.state === 2 && isAdmin) &&
                                                                <div>
                                                                    <div className="alert alert-success">
                                                                        Заявка утверждена, теперь ее можно отправить на оплату.
                                                                    </div>
                                                                    <div className="mb-3 mt-2">
                                                                        <label className="form-label">Комментарий:</label>
                                                                        <textarea
                                                                            value={changeStateData.note}
                                                                            onChange={onChangeNote}
                                                                            placeholder="Добавьте комментарий, если нужно"
                                                                            className="form-control mt-2"/>
                                                                    </div>
                                                                    <button onClick={() => ChangeOrderStateHandle('OnPayment')}
                                                                            className="btn btn-primary">
                                                                        {
                                                                            changingState
                                                                                ? <FetchState loading={changingState} variant="light" label="Обработка" />
                                                                                : 'Отправить на оплату'
                                                                        }
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                /* Кнопка Оплачено */
                                                                (order.state === 3 && (isAdmin || isFinancialDepartment)) &&
                                                                <div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Комментарий:</label>
                                                                        <textarea
                                                                            value={changeStateData.note}
                                                                            onChange={onChangeNote}
                                                                            placeholder="Добавьте комментарий, если нужно"
                                                                            className="form-control mt-2"/>
                                                                    </div>
                                                                    <button onClick={() => ChangeOrderStateHandle('Paid')}
                                                                            className="btn btn-success">
                                                                        {
                                                                            changingState
                                                                                ? <FetchState loading={changingState} variant="light" label="Обработка" />
                                                                                : 'Оплачено'
                                                                        }
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                /* Кнопка Ожидается поставка */
                                                                (order.state === 4 && (isAdmin || isFinancialDepartment || isPurchaseDepartment || isStorekeeper )) &&
                                                                <div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Комментарий:</label>
                                                                        <textarea
                                                                            value={changeStateData.note}
                                                                            onChange={onChangeNote}
                                                                            placeholder="Добавьте комментарий, если нужно"
                                                                            className="form-control mt-2"/>
                                                                    </div>
                                                                    <button onClick={() => ChangeOrderStateHandle('DeliveryExpected')}
                                                                            className="btn btn-success">
                                                                        {
                                                                            changingState
                                                                                ? <FetchState loading={changingState} variant="light" label="Обработка" />
                                                                                : 'Ожидается поставка'
                                                                        }
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    changingStateError &&
                                                    <Alert variant="danger">{changingStateError}</Alert>
                                                }
                                                {
                                                    (order.state === 0 && order.invoices.length !== requiredCountInvoice)
                                                        ?
                                                        <div className="text-center">
                                                            <Alert variant="warning">
                                                                <Alert.Heading>
                                                                    Добавьте {requiredCountInvoice} {declensionOfNumber(requiredCountInvoice, ['счет', 'счета', 'счетов'])}
                                                                </Alert.Heading>
                                                                <p>
                                                                    Чтобы отправить заявку <strong>на утверждение</strong>,
                                                                    прикрепите {requiredCountInvoice <= 1 ? 'счет' : 'счета'} от {requiredCountInvoice <= 1 ? 'поставщика' : 'поставщиков'}
                                                                </p>
                                                                <p>
                                                                    или
                                                                </p>
                                                                <button
                                                                    onClick={() => {
                                                                        if (confirm('Уверены, что хотите отменить эту заявку?')) {
                                                                            ChangeOrderStateHandle('Canceled');
                                                                        }
                                                                    }}
                                                                    className="btn btn-sm btn-danger small ml-2">
                                                                    {
                                                                        changingState ?
                                                                            <div className="d-flex align-items-center">
                                                                                <Spinner animation="border" size="sm" variant="light"/>
                                                                                <span className="ml-2">Обработка</span>
                                                                            </div>
                                                                            :
                                                                            'Отменить заявку'
                                                                    }
                                                                </button>
                                                            </Alert>
                                                        </div>
                                                        :
                                                        <div className="pt-3 border-top d-flex w-100">
                                                            <button onClick={() => ChangeOrderStateHandle('OnBilling')}
                                                                className="btn btn-sm btn-dark small">
                                                                {
                                                                    changingState
                                                                        ? <FetchState loading={changingState} label={'Обработка'} variant={'light'} />
                                                                        : 'Вернуть на исправление'
                                                                }
                                                            </button>

                                                            {
                                                                (
                                                                    order.state !== 7 &&
                                                                    order.state !== 6 &&
                                                                    order.state !== 5 &&
                                                                    order.state !== 4
                                                                ) &&
                                                                <button
                                                                    onClick={() => {
                                                                        if (confirm('Уверены, что хотите отменить эту заявку?')) {
                                                                            ChangeOrderStateHandle('Canceled');
                                                                        }
                                                                    }}
                                                                    className="btn btn-sm btn-outline-danger small ms-2">
                                                                    {
                                                                        changingState
                                                                            ? <FetchState loading={changingState} label={'Обработка'} variant={'danger'} />
                                                                            : 'Отменить заявку'
                                                                    }
                                                                </button>
                                                            }

                                                            {
                                                                ((order.state < 4 && canDelete) || isAdmin || order.state === 6) &&
                                                                <button onClick={onDeleteHandle}
                                                                    className="btn btn-sm btn-danger small ms-auto">
                                                                    Убрать в архив
                                                                </button>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    
                                </div>                                

                                <div className="col-12 col-md-3">

                                    <div>
                                        <h5>Теги</h5>
                                        <div className="card">
                                            <div className="card-body">
                                                {
                                                    canEditTags ?
                                                        <>
                                                            <OrderTags
                                                                tagsList={getTagsList(order.tags)}
                                                                callback={onUpdateTagsHandle} />
                                                            {
                                                                updateTags &&
                                                                <div className="mt-2">
                                                                    <FetchState loading={updateTags} size="sm" label="Обновление тегов" />
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        <div className="d-flex flex-wrap">
                                                            {
                                                                order.tags.length > 0 ? order.tags.map((tag, index) =>
                                                                        <OrderTag
                                                                            key={index}
                                                                            tag={tag.tag}
                                                                            canRemove={false}
                                                                            removeCallback={() => {}} />
                                                                    )
                                                                    :
                                                                    <div className="text-muted">
                                                                        Теги не указаны
                                                                    </div>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <OrderHistory items={order.orderHistories} showHistory={true} />
                                </div>
                                
                            </div>
                        }
                        
                    </div>
            }
        </div>
    )
}