export const colors = [
    {
        key: 1,
        label: 'Default',
        value: 'rgba(206, 205, 202, 0.5)'
    },
    {
        key: 2,
        label: 'Серый',
        value: 'rgba(155, 154, 151, 0.4)'
    },
    {
        key: 3,
        label: 'Коричневый',
        value: 'rgba(140, 46, 0, 0.2)'
    },
    {
        key: 4,
        label: 'Оранжевый',
        value: 'rgba(245, 93, 0, 0.2)'
    },
    {
        key: 5,
        label: 'Желтый',
        value: 'rgba(233, 168, 0, 0.2)'
    },
    {
        key: 6,
        label: 'Зеленый',
        value: 'rgba(0, 135, 107, 0.2)'
    },
    {
        key: 7,
        label: 'Голубой',
        value: 'rgba(0, 120, 223, 0.2)'
    },
    {
        key: 8,
        label: 'Сиреневый',
        value: 'rgba(103, 36, 222, 0.2)'
    },
    {
        key: 9,
        label: 'Розовый',
        value: 'rgba(221, 0, 129, 0.2)'
    },
    {
        key: 10,
        label: 'Красный',
        value: 'rgba(255, 0, 26, 0.2)'
    }
]

export function getRandomColor() {
    return colors[Math.floor(Math.random()*colors.length)].value
} 