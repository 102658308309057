import React, {useState, useEffect} from "react";
import styles from './style.module.scss';
import Api from '../../helpers/axios';
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {NotificationCard} from "./components/card";
import noticeSound from './audio/notification.mp3';
import useSound from 'use-sound';
import {SignalContext} from "../../contexts/signalContext";

export const Notifications = () => {
    const [cards, setCards] = useState([]);
    const [play] = useSound(noticeSound, { interrupt: true });
    
    /*
    const connection = React.useContext(SignalContext);

    connection.Notifications &&
    connection.Notifications.on('SendNotice', (data) => {
        const notificationCard = <NotificationCard data={data} />;
        setCards(prevState => ([...prevState, notificationCard]));
    });
     */

    useEffect(() => {
        if (cards && cards.length > 0) {
            play();
        }
    }, [cards]);
    
    const [allowAudio, setAllowAudio] = useState(false);
    const onAllowPlayAudioHandle = () => {
        if (!allowAudio) {
            setAllowAudio(true);
            play();
        }
    };
    
    const onTestHandle = () => {
        Api.post('/api/test/SendTestNotice').then()
    }
    
    return (
        <div className={styles.notifications} onClick={onAllowPlayAudioHandle}>
            {
                (cards && cards.length > 0) && cards.map((card, index) =>
                    <React.Fragment key={index}>
                        {card}
                    </React.Fragment>
                )
            }
        </div>
    )
};

export default Notifications;