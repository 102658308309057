import React from "react";
import './style.scss';

export default function ItemPhotos({itemPhotos}) {
    return (
        <>
            {
                itemPhotos.length > 0 &&
                <div className="photos-list d-flex flex-wrap w-100">
                    {
                        itemPhotos.map(photo =>
                            <a key={photo.id} href={`/upload/photo/${photo.fileName}`} target="_blank" className="me-1">
                                <img className="photos-list-img" src={`/upload/photo/${photo.fileName}`} alt="" />
                            </a>
                        )
                    }
                </div>
            }
        </>
    );
};