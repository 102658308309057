import React, {useState, useEffect} from 'react'
import Api from '../../helpers/axios'
import {Spinner, Alert} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import './style.scss'

export default () => {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const [stocks, setStocks] = useState([]);

    const getStocks = () => {
        if (!fetching) {
            setFetching(true);
            setError(null);

            Api.post('/api/stocks/getAll')
                .then(res => {
                    setStocks(res.data.body);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setFetching(false);
                });

        }
    };

    useEffect(() => {
        getStocks();
    }, []);

    return (
        <div className='container'>
            <h2>
                Склады
            </h2>

            {
                error &&
                <Alert variant="danger">{error}</Alert>
            }

            {
                fetching ?
                    <div className="d-flex w-100 align-items-center">
                        <Spinner animation="border" variant="warning" size="sm" />
                        <span className="ml-2 text-muted">
                            Загрузка складов
                        </span>
                    </div>
                    :
                    <div>
                        {
                            stocks.length === 0 ?
                                <div className="text-muted">Нет доступных складов</div>
                                :
                                <>
                                    <div className="stocks">
                                        {
                                            stocks.map(stock =>
                                                <div key={stock.id} className="card mb-2 mr-2">
                                                    <div className="card-body">
                                                        <div className="d-flex w-100">
                                                            <div>
                                                                <h4>
                                                                    {stock.name}
                                                                </h4>
                                                                <div className="text-muted">
                                                                    {stock.address}
                                                                </div>
                                                                {
                                                                    stock.note && <div className="text-muted mt-1">{stock.note}</div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="mt-3">
                                                            <Link
                                                                className="btn btn-sm btn-primary"
                                                                to={`/stock/${stock.id}`}>
                                                                Открыть
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                </>
                        }
                    </div>
            }

        </div>
    );
}