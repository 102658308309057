import React, {useState} from 'react'
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg'
import {Modal, Spinner, Alert} from 'react-bootstrap'
import {bankAccountType} from '../../../helpers/bankAccountType'
import CurrencyInput from 'react-currency-input-field'
import Api from '../../../helpers/axios'

export default function CreateBankAccount({callback}) {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const initialData = {
        type: 0,
        name: '',
        balance: 0,
        startBalance: 0,
        orderNumber: 0
    }
    const [data, setData] = useState(initialData)

    const onHideHandle = () => {
        setData(initialData)
        setShow(false)
    }
    const onShowHandle = () => {
        setShow(true)
    }
    
    const onChangeHandle = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' ? target.value - 0 : target.value;
        const {name} = target;
        setData(prevState => ({...prevState, [name]: value}));
    }
    const onChangeBalanceHandle = (val) => {
        if (!val) {
            val = 0
        }

        setData(prevState => ({...prevState, balance: val}));
    }
    
    const validate = () => {
        if (data.name === '') {
            setError('Укажите название счета')
            return false
        }

        return true
    }
    
    const onSaveHandle = () => {
        if(!loading && validate()) {
            setLoading(true)
            setError(null)
            
            Api.post('/api/bankAccount/create', data)
                .then(()=>{
                    callback()
                    onHideHandle()
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    return (
        <>
            <button
                disabled={show}
                onClick={onShowHandle}
                className="btn btn-primary d-flex align-items-center">
                <AddIcon/>
                <span className="ms-2">
                    Создать счет
                </span>
            </button>
            
            <Modal show={show} onHide={onHideHandle}>
                <Modal.Body>
                    <div className="card-body">
                        <h5 className="mb-3">
                            Новый счет
                        </h5>
                        
                        <div className="mb-3">
                            {
                                bankAccountType.map(type =>
                                    <div key={type.value} className="form-check fake-checkbox">
                                        <input type="radio"
                                               className="form-check-input"
                                               id={`type_${type.value}`}
                                               name="type"
                                               value={type.value}
                                               checked={data.type === type.value}
                                               onChange={onChangeHandle}
                                        />
                                        <label className="form-check-label" htmlFor={`type_${type.value}`}>
                                            {type.label}
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="orderNumber">Номер сортировки:</label>
                            <input
                                id="orderNumber"
                                type="text"
                                name="orderNumber"
                                className="form-control"
                                onChange={onChangeHandle}
                                value={data.orderNumber}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="account-name">Название счета:</label>
                            <input
                                id="account-name"
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={onChangeHandle}
                                value={data.name}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="account-balance">Начальный баланс счета:</label>
                            <CurrencyInput
                                id="flow-value"
                                className="form-control"
                                name="balance"
                                suffix=" ₽"
                                decimalSeparator="."
                                defaultValue={data.balance}
                                decimalsLimit={2}
                                onValueChange={onChangeBalanceHandle}
                            />
                        </div>

                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }

                        <div>
                            <button
                                disabled={loading}
                                onClick={onSaveHandle}
                                className="btn btn-primary">
                                {
                                    loading ?
                                        <div className="d-flex align-items-center">
                                            <Spinner animation="border" size="sm" variant="light"/>
                                            <span className="ms-2">Сохранение</span>
                                        </div>
                                        :
                                        'Сохранить'
                                }
                            </button>
                            {
                                !loading &&
                                <button
                                    onClick={onHideHandle}
                                    className="btn btn-dark ms-2">Отмена</button>
                            }
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}