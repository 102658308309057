import React from "react";
import './style.scss';

export default function Avatar ({path, size}) {

    const filePath = path ? `/avatar/${path}` : '/avatar/defaultAvatar.png';

    const style = {
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${filePath})`
    };

    return (
        <div className="avatar" style={style} />
    );
};