import React, {useRef, useState} from "react";
import Api from '../../helpers/axios';
import {Alert, Spinner} from "react-bootstrap";

export default function UploadInvoice(props) {
    const orderId = props.orderId;
    const supplierId = props.supplierId;
    const callback = props.callback;

    const [upload, setUpload] = useState(false);
    const [error, setError] = useState(null);
    const inputFile = useRef();
    
    const [data, setData] = useState({
        numberInvoice: '',
        dateInvoice: ''
    });
    
    const [show, setShow] = useState(false);
    
    const onShow = () => setShow(true);
    const onHide = () => setShow(false);

    const uploadInvoice = (file) => {
        
        if(data.numberInvoice === '' || data.dateInvoice === '') {
            setError('Заполните данные о счете: номер и дату');
            return false;
        }
        
        if (!upload) {
            setUpload(true);
            setError(null);

            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('orderId', orderId);
            formData.append('supplierId', supplierId);
            formData.append('numberInvoice', data.numberInvoice);
            formData.append('dateInvoice', data.dateInvoice);

            inputFile.current.value = null;

            Api.post('/api/invoice/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    callback()
                })
                .catch(err => {
                    setError(err.response.data.message)
                })
                .finally(() => {
                    setUpload(false)
                })
        }
    };

    const onChangeHandler = (event) => {
        uploadInvoice(event.target.files[0]);
    }

    const selectFile = () => {
        inputFile.current.click();
    }
    
    const onChangeInvoiceDataHandle = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    return <>
        {
            error && <Alert variant="danger">{error}</Alert>
        }

        <input
            ref={inputFile}
            type="file"
            name="file"
            style={{display: 'none'}}
            onChange={onChangeHandler} />

        {
            upload ?
                <div className="d-flex align-items-center ml-2">
                    <Spinner variant="warning" size="sm" animation="border" /><span className="ml-2">Загрузка счета...</span>
                </div>
                :
                <div>
                    {
                        !show && <button className="btn btn-sm btn-dark" onClick={onShow}>Добавить счет</button>
                    }
                    {
                        show && <div>
                            <div className="d-md-flex">
                                <div className="form-group">
                                    <label>Номер счета:</label>
                                    <input
                                        type="text"
                                        name="numberInvoice"
                                        className="form-control"
                                        onChange={onChangeInvoiceDataHandle}
                                        value={data.numberInvoice} />
                                </div>
                                <div className="form-group ml-0 ml-md-2">
                                    <label>Дата счета:</label>
                                    <input
                                        type="date"
                                        name="dateInvoice"
                                        className="form-control"
                                        onChange={onChangeInvoiceDataHandle}
                                        value={data.dateInvoice} />
                                </div>
                            </div>

                            <button
                                onClick={selectFile}
                                className="btn btn-primary">Прикрепить счет</button>
                            <button onClick={onHide} className="btn btn-dark ml-2">Отмена</button>
                        </div>
                    }
                </div>
        }
    </>
}