import React, {useState, useEffect, useRef, useMemo} from "react";
import Api from '../../../../../helpers/axios';
import './style.scss';
import {Editor, EditorState} from 'draft-js';
import "draft-js/dist/Draft.css";
import {stateToHTML} from "draft-js-export-html";
import FetchState from "../../../../../components/ui/fetchState";
import GetResponseError from "../../../../../helpers/getResponseError";

export const CreateComment = ({clientProjectId, onCreated}) => {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const editorRef = useRef(null);
    const [data, setData] = useState({
        clientProjectId: clientProjectId,
        content: ''
    });

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );
    useMemo(() => {
        setData({...data, content: stateToHTML(editorState.getCurrentContent())})
    }, [
        editorState
    ]);

    const onFocus = () => {
        editorRef.current.focus();
    };
    
    const onValidate = () => {
        if (data.content === '') {
            setError('Укажите текст комментария');
            return false;
        }
        return true;
    }
    
    const onSubmitHandle = () => {
        if (onValidate() && !fetching) {
            setFetching(true);
            setError('');
            
            Api.post('/api/projectComments/create', data)
                .then(res => {
                    onCreated(res.data.body);
                    setEditorState(() => EditorState.createWithText(''));
                })
                .catch(err => setError(GetResponseError(err)))
                .finally(() => {
                    setFetching(false);
                });            
        }
    }
    
    return (
        <div className="comment">
            <div className="pb-1">
                Добавить комментарий:
            </div>
            {
                error && <div className="mb-1 text-danger">{error}</div>
            }
            <div onClick={onFocus} className="comment-editor">
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    placeholder="Ваш комментарий..."
                    localization={{
                        locale: 'ru',
                    }}
                    ref={editorRef}
                />
            </div>
            <div className="mt-2 d-flex w-100">
                <div className="ms-auto">
                    <button
                        disabled={fetching}
                        onClick={onSubmitHandle}
                        className="btn btn-sm btn-dark">
                        {
                            fetching
                                ? <FetchState loading={fetching} label="Сохранение" />
                                : 'Написать'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
};

export default CreateComment;