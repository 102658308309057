import React from "react";
import {Link} from "react-router-dom";

export default () => {
    return (
        <div className="container text-center pt-5 pb-2">
            <h2>
                Доступ закрыт
            </h2>
            <p>
                У вас недостаточно прав на этот раздел.
            </p>
            <p>
                <Link to="/">Перейти на главную</Link>
            </p>
        </div>
    )
}