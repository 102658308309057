import React from "react"
import AddBtn from '../../components/buttons/addItem'
import ItemsTable from '../../components/itemsTable'
import {useDispatch} from 'react-redux'
import {itemCreateEventAction} from '../../store/items/actions'
import roles from "../../helpers/roles";
import ExportStockData from "../../components/exportStockData";

export default () => {
    const dispatch = useDispatch();
    const isAdmin = roles.is(roles.administrator);
    const isStorekeeper = roles.is(roles.storekeeper);
    const isPurchaseDepartment = roles.is(roles.purchaseDepartment);

    const refreshTable = () => {
        dispatch(itemCreateEventAction(true))
        document.location.reload()
    }

        return (
            <>
                <div className='container'>
                    <div className='d-md-flex align-items-start w-100'>
                        <div>
                            <h2>
                                Поиск позиций
                            </h2>
                        </div>
                        <div className='ms-md-auto d-flex'>
                            <AddBtn
                                callback={refreshTable} />
                        </div>
                        {
                            (isAdmin || isStorekeeper || isPurchaseDepartment) &&
                            <div className="ms-2">
                                <ExportStockData stockId={""} />
                            </div>
                        }
                    </div>
                    <div className="mt-3">
                        <ItemsTable />
                    </div>
                </div>
            </>
        );
}