import React, {useState} from 'react'
import UploadItemPhotos from '../../uploadItemPhotos'
import {Modal} from 'react-bootstrap'
import userHaveRole from "../../../helpers/userHaveRole";

export default ({item, callback, classname}) => {
    const [show, setShow] = useState(false)
    const onShow = (e) => {
        e.preventDefault()
        setShow(true)
    }
    const onHide = () => setShow(false)
    
    const canEdit = userHaveRole("Administrator") || userHaveRole("Storekeeper")
    
    return (
        canEdit ?
            <>
                <a href="#open"
                   className={classname}
                   onClick={onShow}>Фотографии</a>
                <Modal show={show} onHide={onHide}>
                    <Modal.Body>
                        <UploadItemPhotos
                            callback={callback}
                            currentItem={item} />
                    </Modal.Body>
                </Modal>
            </>
            : 
            <></>
    )
}