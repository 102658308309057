import {
    ADD_TO_ORDER,
    REMOVE_FROM_ORDER_BY_ID,
    REMOVE_ORDER,
    CHANGE_AMOUNT_PRODUCT_ORDER
} from "./actions";

const initialState = {
    products: []
}

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_ORDER:

            // check duplicate
            if (state.products.find(x => x.product.id === action.payload.product.id)) {
                return {
                    ...state
                }
            }

            return {
                ...state,
                products: [...state.products, action.payload]
            };

        case REMOVE_FROM_ORDER_BY_ID:
            return {
                ...state,
                products: state.products.filter(item => item.product.id !== action.payload)
            };

        case CHANGE_AMOUNT_PRODUCT_ORDER:
            const id = action.payload.id;
            const amount = action.payload.value - 0 <= 0 ? 0 : action.payload.value - 0;
            const itemState = state.products.find(x => x.product.id === id);

            if (!itemState) {
                return state;
            }

            if (amount === 0) {
                return {
                    ...state,
                    products: state.products.filter(item => item.product.id !== id)
                };
            }

            const productIndex = state.products.findIndex((x => x.product.id === id));

            if (productIndex === -1) {
                return state;
            }

            let data = state;
            data.products[productIndex].amount = amount;

            return  {
                ...state,
                ...data
            };

        case REMOVE_ORDER:
            return {
                ...state,
                ...initialState
            }

        default:
            return state;
    }
}

export default orderReducer;