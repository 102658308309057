import React, {useState, useEffect, useMemo} from 'react'
import Api from '../../../helpers/axios'
import {Modal, Spinner, Alert} from 'react-bootstrap'
import {amountTypes} from "../../../helpers/amountTypes"
import userHaveRole from "../../../helpers/userHaveRole"

export default ({stockItemId, callback, classname}) => {
    const [fetching, setFetching] = useState({
        main: false,
        update: false,
        deleting: false
    })
    const [error, setError] = useState({
        main: null,
        update: null,
        deleting: null
    })
    const [show, setShow] = useState(false)
    const onHide = () => setShow(false)
    
    const [stockItem, setStockItem] = useState({
        stockItemId: stockItemId,
        amount: 0,
        amountType: 0,
        minAmount: 0,
        item: {
            id: '',
            name: ''
        }
    })
    
    const getData = () => {
        if (stockItemId && !fetching.main) {
            setFetching(prevState => ({...prevState, main: true}))
            setError(prevState => ({...prevState, main: null}))
            
            Api.post('/api/stocks/getItem', {stockItemId: stockItemId})
                .then(res => {
                    setStockItem(res.data.body)
                })
                .catch(err => setError(prevState => ({...prevState, main: err.response.data.message})))
                .finally(() => setFetching(prevState => ({...prevState, main: false})))
        }
    }

    const onChangeHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setStockItem(prevState => ({ ...prevState, [name]: value }));
    }

    const onShow = (e) => {
        e.preventDefault()
        setShow(true)
        getData()
    }
    
    const onUpdateHandle = () => {
        if (!fetching.update) {
            setFetching(prevState => ({...prevState, update: true}))
            setError(prevState => ({...prevState, update: null}))
            
            Api.post('/api/stocks/updateOptions', stockItem)
                .then(() => {
                    callback()
                    onHide()
                })
                .catch(err => setError(prevState => ({...prevState, update: err.response.data.message})))
                .finally(() => setFetching(prevState => ({...prevState, update: false})))
        }
    }

    const onDeleteHandle = () => {
        if(confirm('Удалить эту позицию со склада?')) {
            if(!fetching.deleting) {
                setFetching(prevState => ({...prevState, deleting: true}))
                setError(prevState => ({...prevState, deleting: null}))

                Api.post('/api/stocks/DeleteItem', {id: stockItemId})
                    .then(() => {
                        document.location.reload()
                    })
                    .catch(err => setError(prevState => ({...prevState, deleting: err.response.data.message})))
                    .finally(() => {
                        setFetching(prevState => ({...prevState, deleting: false}))
                    })
            }
        }
    }

    const isAdmin = userHaveRole("Administrator")
    const isStorekeeper = userHaveRole("Storekeeper")
    
    return (
        <>
            <a href="#"
               className={classname}
               onClick={onShow}>Параметры на складе</a>
            
            <Modal show={show} onHide={onHide}>
                <Modal.Body>
                    {
                        error.main &&
                        <Alert variant="danger">{error.main}</Alert>
                    }
                    {
                        fetching.main ? 
                            <div className="d-flex align-items-center justify-content-center">
                                <Spinner animation="border" size="sm" variant="warning" />
                                <span className="ml-2">
                                    Загрузка данных по позции
                                </span>
                            </div>
                            :
                            <div className="card">
                                <div className="card-body">
                                    <h5>Параметры позиции на складе</h5>
                                    <div className="mb-3 display-18">
                                        {
                                            stockItem.item.name
                                        }
                                    </div>
                                    <div className="d-md-flex">
                                        {
                                            isAdmin &&
                                            <div className="form-group mr-0 mr-md-2">
                                                <label htmlFor="amount">Остаток:</label>
                                                <input
                                                    id="amount"
                                                    type="number"
                                                    name="amount"
                                                    className="form-control"
                                                    value={stockItem.amount}
                                                    onChange={onChangeHandle}
                                                />
                                            </div>
                                        }
                                        
                                        <div className="form-group">
                                            <label htmlFor="amountType">Ед. измерения:</label>
                                            <select name="amountType"
                                                    className="form-control"
                                                    id="amountType"
                                                    value={stockItem.amountType}
                                                    onChange={onChangeHandle}>
                                                {
                                                    amountTypes.map(type =>
                                                        <option key={type.key} value={type.key}>{type.label} ({type.short})</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="minAmount">Минимальное количество:</label>
                                        <div className="mb-2 small text-muted">
                                            Количество позиции, которое всегда должно быть на складе.
                                        </div>
                                        <input
                                            id="minAmount"
                                            type="number"
                                            name="minAmount"
                                            className="form-control"
                                            value={stockItem.minAmount}
                                            onChange={onChangeHandle}
                                            min={0}
                                        />
                                    </div>
                                    
                                    {
                                        error.update &&
                                        <Alert variant="danger">{error.update}</Alert>
                                    }
                                    {
                                        error.deleting &&
                                        <Alert variant="danger">{error.deleting}</Alert>
                                    }
                                    
                                    <div className="mt-4 d-flex w-100">
                                        <div>
                                            <button
                                                onClick={onUpdateHandle}
                                                className="btn btn-primary btn-sm">
                                                {
                                                    fetching.update ?
                                                        <div className="d-flex align-items-center">
                                                            <Spinner animation="border" size="sm" variant="light" />
                                                            <span className="ml-2">Сохраняем</span>
                                                        </div> :
                                                        'Сохранить'
                                                }
                                            </button>
                                            <button
                                                onClick={onHide}
                                                className="btn btn-dark btn-sm ml-2">Отмена</button>
                                        </div>
                                        {
                                            (isAdmin || isStorekeeper) &&
                                            <div className="ml-auto">
                                                <button
                                                    onClick={onDeleteHandle}
                                                    className="btn btn-outline-danger btn-sm">
                                                    {
                                                        fetching.deleting ?
                                                            <div className="d-flex align-items-center">
                                                                <Spinner animation="border" size="sm" variant="light" />
                                                                <span className="ml-2">Удаление позиции</span>
                                                            </div>
                                                            :
                                                            'Удалить со склада'
                                                    }
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
            
        </>
    )
}