import React, {useState, useMemo} from "react"
import Api from '../../helpers/axios'
import {Alert, Spinner} from "react-bootstrap"
import {format} from "date-fns"
import {ru} from "date-fns/locale"
import {getShortNameAmountType} from "../../helpers/amountTypes"
import UserDetails from "../../components/userDetails"
import getStatusRequest from "../../helpers/getStatusRequest"
import ConfirmRequest from "../../components/buttons/confirmRequest"
import Pagination from "../../components/pagination"
import {Link} from "react-router-dom";
import userHaveRole from "../../helpers/userHaveRole";

export default () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [orders, setOrders] = useState([])
    const [count, setCount] = useState(0)
    const [options, setOptions] = useState({
        page: 1,
        onPage: 30,
        desc: true
    })

    // Получить заявки
    const getOrders = () => {
        if (!loading) {
            setLoading(true);
            setError(null);

            Api.post('/api/requestorders/PerPage', options)
                .then(res => {
                    setOrders(res.data.body.orders);
                    setCount(res.data.body.count);
                    setError(null);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const refreshHandle = (e) => {
        e.preventDefault()
        getOrders()
    }

    useMemo(() => {
        getOrders()
    }, [])

    const updatePagination = (data) => {
        setOptions(prevState => ({...prevState, page: data}))
    }

    useMemo(() => {
        getOrders();
    }, [options])

    const updateOrderHandle = (order) => {

        const data = orders.map(item => {
            if(item.id === order.id) {
                item = order
            }
            return item
        })

        setOrders(data)
    }
    
    const displayAmount = (amount) => {
        
        let result = amount - 0;
        
        if (!Number.isInteger(result)) {
            result = result.toFixed(3);
        }
        
        return result;
    };
    
    return <div className="container">
        <h2>
            Выдача позиций
        </h2>
        <div className="mt-3">
            {
                error &&
                <Alert variant="danger">{error}</Alert>
            }

            <div className="table-responsive">
                <table className="table table-bordered">
                    <caption>
                        {
                            loading ?
                                <div className="d-flex w-100 align-items-center">
                                    <Spinner animation="border" variant="warning" size="sm"/>
                                    <span className="text-muted ms-2">
                                            Подгрузка данных
                                        </span>
                                </div>
                                :
                                <div className="d-flex">
                                        <span className="me-2">
                                            Заявки на {format(new Date(), 'H:mm, d MMMM yyyy', {locale: ru})}
                                        </span>
                                    <a href="#" onClick={refreshHandle}>Обновить список</a>
                                </div>
                        }
                    </caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Статус:</th>
                            <th>
                                <div className="text-start">
                                    Заказанные позиции:
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders.map(order =>
                                <tr key={order.id}>
                                    <td>
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="display-18">
                                                Заявка № {order.orderNumber}
                                            </div>
                                            <div className="pt-1 text-muted">
                                                от {format(new Date(order.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})}
                                            </div>

                                            <div className="mt-2">
                                                <UserDetails user={order.author}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                       <div className="text-center">
                                            <span className={`ms-1 badge ${
                                                order.state === 0 ? 'bg-danger'
                                                    : order.state === 1 ? 'bg-warning'
                                                        : order.state === 2 ? 'bg-success'
                                                            : order.state === 3 ? 'bg-info' : ''}`}>

                                                {getStatusRequest.getDisplayName(order.state)}
                                            </span>
                                       </div>
                                    </td>
                                    <td>
                                        <div className="pb-3 pt-2 d-flex display-18">
                                            <span className="me-1">Назначение:</span>
                                            {
                                                order.project ?
                                                    <Link to={`/project/${order.project.id}`}>
                                                        {order.project.name}
                                                    </Link>
                                                    : 'Не указано'
                                            }
                                        </div>
                                        <div>
                                            <div className="positions">
                                                <div className="row positions-item-list">
                                                    <div className="col-12 col-md-6">Позиции:</div>
                                                    <div className="col-12 col-md-2">Склад:</div>
                                                    <div className="col-12 col-md-2">Запрошено:</div>
                                                    <div className="col-12 col-md-2">На балансе:</div>
                                                </div>
                                                {
                                                    order.positions.map(position =>
                                                        <div className="row positions-item-list" key={position.item.id}>
                                                            <div className="col-12 col-md-6">
                                                                {position.item.name}
                                                            </div>
                                                            <div className="col-12 col-md-2">
                                                                {
                                                                    position.stock.name
                                                                }
                                                            </div>
                                                            <div className="col-12 col-md-2">
                                                                {displayAmount(position.amount)} {getShortNameAmountType(position.amountType)}
                                                            </div>
                                                            <div className="col-12 col-md-2">
                                                                {
                                                                    (position.item.stockItems && position.item.stockItems[0])
                                                                        ? `${displayAmount(position.item.stockItems[0].amount)} ${getShortNameAmountType(position.amountType)}`
                                                                        : <span className="text-muted">&mdash;</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <ConfirmRequest
                                                callback={updateOrderHandle}
                                                order={order}/>
                                            
                                            <Link to={`/request/print/${order.id}`}
                                                  className="btn btn-sm btn-secondary ms-2"
                                                  target="_blank">Распечать</Link>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                {
                    (!loading && !orders.length) &&
                    <div className="text-center text-muted p-5">
                        <span>
                            Нет заявок на выдачу позиций
                        </span>
                    </div>
                }
            </div>

            {
                !orders.length &&
                <div className="text-muted">
                    Заявок нет
                </div>
            }

            {
                !loading &&
                <Pagination
                    callback={updatePagination}
                    currentPage={options.page}
                    onPage={options.onPage}
                    count={count}/>
            }

        </div>
    </div>
}