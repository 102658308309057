import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import Layout from '../components/layouts/layoutPrint';

export const PrintRoute = ({ component: Component, ...rest }) => {
    const state = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : null;
    const isAuth = state == null ? false : state.auth.isAuth;
    return (
        <Route
            {...rest}
            render={props => isAuth ?
                (
                    <Layout><Component {...props} /></Layout>
                ) :
                (<Redirect to={{
                    pathname: '/login',
                    state: {from: props.location}
                }}
                />)
            }
        />
    );
}