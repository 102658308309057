import {TASKS_FILTERS} from './actions';

const initialState = {
    dateStart: null,
    dateEnd: null,
    status: '',
    desc: true,
    userId: '',
    page: 1,
    onPage: 50,
    incoming: true,
    typeTask: false,
    typeNotice: false,
    allTasks: false
};

export const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case TASKS_FILTERS:
            return {
                ...state, ...action.payload
            };
        default:
            return state;
    }
};

export default tasksReducer;