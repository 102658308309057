export const ADD_TO_ORDER = 'ADD_TO_ORDER';
export const REMOVE_FROM_ORDER_BY_ID = 'REMOVE_FROM_ORDER_BY_ID';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const CHANGE_AMOUNT_PRODUCT_ORDER = 'CHANGE_AMOUNT_PRODUCT_ORDER';

export const addToOrderAction = (payload) => ({ type: ADD_TO_ORDER, payload });
export const removeFromOrderByIdAction = (payload) => ({ type: REMOVE_FROM_ORDER_BY_ID, payload });
export const removeOrderAction = () => ({type: REMOVE_ORDER});
export const changeAmountProductOrderAction = (payload) => ({type: CHANGE_AMOUNT_PRODUCT_ORDER, payload});

