import React, {useState, useEffect} from "react";
import {useDispatch} from 'react-redux';
import { Alert } from 'react-bootstrap';
import api from '../../helpers/axios';
import {loginAction, logoutAction} from '../../store/auth/actions';
import {userCreateAction} from '../../store/user/actions';
import Loader from '../../components/ui/loader';
import './style.scss';
import {ReactComponent as OpenPassword} from "./open_eye.svg";
import {ReactComponent as HiddenPassword} from "./hidden_eye.svg";
import FetchState from "../../components/ui/fetchState";
import ResetPasswordComponent from './resetPassword';

export default (props) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [error, setError] = useState();
    const [showPass, setShowPass] = useState(false);
    const [data, setData] = useState({
        email: '',
        password: '',
        rememberMe: true,
        returnUrl: props.location.state ? props.location.state.from.pathname : '/'
    });

    // Reset state
    useEffect(() => {
        dispatch(logoutAction());
    }, []);

    // Changes form data
    useEffect(() => {
        setDisabled(data.login === '' || data.password === '');
    }, [data]);

    // Обработка полей формы
    const onChangeHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setData(prevState => ({ ...prevState, [name]: value }));
    };

    // Validate form data
    const validateForm = () => {
        if (data.login === '' || data.password === '') {
            setError('Заполните все поля');
            return false;
        }
        return true;
    };

    // Submit form
    const submitHandle = (e) => {
        e.preventDefault();

        if (validateForm()) {
            if (!loading) {

                setError(null);
                setLoading(true);

                api.post('/api/account/login', data)
                    .then((res) => {

                        // dispatch
                        dispatch(loginAction());
                        dispatch(userCreateAction(res.data.body));

                        // redirect
                        setRedirecting(true);
                        document.location.assign(res.data.message);
                    })
                    .catch(err => {
                        setError(err.response.data.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

            }
        }
    };
    
    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass);
    };
    
    return (
        <>
            {
                redirecting ? <Loader/>
                    :
                    <>
                        <div className='d-flex justify-content-center pt-1 pt-md-5'>
                            <div className="card login-card">
                                <div className="card-body">

                                    <div className='text-center pt-2 mb-4'>
                                        <h5>
                                            {process.env.REACT_APP_SITE_TITLE}
                                        </h5>
                                        <p>
                                            Войдите, чтобы продолжить
                                        </p>
                                    </div>

                                    <form onSubmit={submitHandle}>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email:</label>
                                            <input type="email"
                                                   className="form-control"
                                                   id="email"
                                                   name="email"
                                                   onChange={onChangeHandle}
                                                   value={data.email}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Пароль:</label>
                                            <div className="password-wrapper">
                                                <input type={showPass ? 'text' : 'password'}
                                                       className="form-control"
                                                       id="password"
                                                       name="password"
                                                       autoComplete={'new-password'}
                                                       onChange={onChangeHandle}
                                                       value={data.password}
                                                />
                                                <div className="passwordShowBtn" onClick={toggleShowPassword}>
                                                    {
                                                        showPass ?
                                                            <OpenPassword />
                                                            :
                                                            <HiddenPassword />
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-text">
                                                <a href="#" onClick={toggleShowPassword}>{showPass ? 'Скрыть' : 'Показать'} пароль</a>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-check fake-checkbox">
                                                <input type="checkbox"
                                                       className="form-check-input"
                                                       id="exampleCheck1"
                                                       name="rememberMe"
                                                       onChange={onChangeHandle}
                                                       checked={data.rememberMe}
                                                />
                                                <label className="form-check-label" htmlFor="exampleCheck1">Запомнить меня</label>
                                            </div>
                                        </div>

                                        <div className="form-group text-center pt-2">
                                            <button type="submit"
                                                    disabled={disabled}
                                                    className="btn btn-primary d-flex justify-content-center">
                                                {
                                                    loading ?
                                                        <FetchState loading={loading} label="Входим" size="sm" variant="light" />
                                                        :
                                                        'Войти'
                                                }
                                            </button>
                                        </div>
                                        {
                                            error &&
                                            <div className='text-center'>
                                                <Alert variant='danger'>{error}</Alert>
                                            </div>
                                        }
                                        
                                        <div className="pt-3">
                                            <ResetPasswordComponent/>
                                        </div>
                                    </form>
                                </div>
                                <div className='card-footer small text-muted text-center'>
                                    &copy; {process.env.REACT_APP_SITE_TITLE}, {new Date().getFullYear()}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}