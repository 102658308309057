import React, {useState, useEffect} from "react"
import PropTypes from 'prop-types'
import './style.scss'
import {ReactComponent as PlusIcon} from "./add.svg"
import {ReactComponent as MinusIcon} from "./minus.svg"

function InputNumber(props) {
    const [value, setValue] = useState(props.value || 0)
    const onChange = props.onChange
    const label = props.label
    const width = props.width
    const min = props.min
    const max = props.max

    const changeValue = (newValue) => {
   
        if (newValue === '') {
            newValue = 0
        }
        
        if (newValue < min) {
            newValue = min
        }

        if (max && newValue > max) {
            newValue = max
        }

        setValue(newValue)
        onChange(newValue)        
    }
    
    const incrementHandle = () => changeValue(value + 1)
    const decrementHandle = () => changeValue(value - 1)
    const onInputHandle = (e) => changeValue(e.target.value)

    useEffect(() => {
        changeValue(value);
    }, []);
    
    return <div className="d-flex flex-column">
        {
            label &&
            <div className="small text-center mb-1">
                {label}
            </div>
        }
        <div className="d-flex align-items-center input-number-control">
            {
                value > min && <MinusIcon onClick={decrementHandle} />
            }
            <input
                type="number"
                onChange={onInputHandle}
                value={value}
                min={min}
                max={max}
                style={{minWidth: width}}
                className="input-number-control-value" />
            {
                max ?
                    value < max && <PlusIcon onClick={incrementHandle} />
                    :
                    <PlusIcon onClick={incrementHandle} />
            }
        </div>
        {
            value === max &&
            <div className="small text-center mt-1">
                Максимум
            </div>
        }
        {
            value === min &&
            <div className="small text-center mt-1">
                Минимум
            </div>
        }
    </div>
}

InputNumber.defaultProps = {
    value: 0,
    width: 70,
    onChange: () => {},
    label: null,
    min: 0,
    max: null
}

InputNumber.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    width: PropTypes.number,
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number
}

export default InputNumber