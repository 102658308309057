export const bankAccountType = [
    {
        label: 'Счет организации',
        value: 0
    },
    {
        label: 'Личный счет',
        value: 1
    }
]

export function getBankAccountType(val) {
    const type = bankAccountType.find(x => x.value === val);

    if (type) {
        return type
    }

    return null;
}