import React, {useState} from "react";
import {Modal} from "react-bootstrap";

export default (props) => {
    const supplier = props.supplier;
    const [showSupplierDetails, setShowSupplierDetails] = useState(false);

    const handleCloseSupplierDetailsModal = () => {
        setShowSupplierDetails(false);
    };
    const handleShowSupplierDetailsModal = (e) => {
        e.preventDefault();
        setShowSupplierDetails(true);
    };

    return (
        (supplier && supplier.id) ?
        <>
            <a href="#" onClick={(e) => handleShowSupplierDetailsModal(e)}>
                {supplier.name}
            </a>
            <Modal show={showSupplierDetails} onHide={handleCloseSupplierDetailsModal}>
                <Modal.Body>
                    <h3>
                        {supplier.name}
                    </h3>
                    {
                        supplier.brand &&
                        <dl>
                            <dt>Бренд:</dt>
                            <dd>{supplier.brand}</dd>
                        </dl>
                    }

                    <table className="table table-striped">
                        <tbody>
                        <tr>
                            <td>
                                <strong>Контактное лицо:</strong>
                            </td>
                            <td>
                                {supplier.manager || 'Не указано'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Телефон:</strong>
                            </td>
                            <td>
                                {supplier.phone || 'Не указано'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Адрес:</strong>
                            </td>
                            <td>
                                {supplier.address || 'Не указано'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Сайт:</strong>
                            </td>
                            <td>
                                {supplier.url || 'Не указано'}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {
                        supplier.note && <dl>
                            <dt>Дополнительная информация:</dt>
                            <dd>{supplier.note}</dd>
                        </dl>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseSupplierDetailsModal}>Ок</button>
                </Modal.Footer>
            </Modal>
        </>
        :
        <>
            &mdash;
        </>
    )
}