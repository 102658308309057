import React, {useEffect, useMemo, useState} from "react";
import Api from '../../helpers/axios';
import {Alert, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import {addToStockAction, itemsOnPageAction} from "../../store/stocks/actions";
import Pagination from "../pagination";
import SupplierDetails from "../supplierDetails";
import Autocomplete from "../../components/autocomplete";
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import AddOrderButton from '../buttons/addOrder';
import AddProductionRequestButton from '../buttons/addProductionRequest';
import {getShortNameAmountType} from '../../helpers/amountTypes';
import EditItemPhoto from "../buttons/editItemPhoto";
import ReturnButton from "../buttons/returnButton";
import {ReactComponent as UpIcon} from "./arrow_up.svg";
import {ReactComponent as DownIcon} from "./arrow_down.svg";
import {ReactComponent as CheckIcon} from "./check-mark.svg";
import ItemPhotos from "../itemPhotos";
import formatCurrency from "../../helpers/formatCurrency";
import ItemOptionsOnStock from '../../components/buttons/itemOptionsOnStock';
import DotsMenu from '../dotsMenu';
import EditItem from '../buttons/editItem';
import useDebounce from '../../helpers/hooks/useDebounce';
import {getURLParameters} from "../../helpers/getURLParameters";
import {round10} from "../../helpers/round";
import roles from "../../helpers/roles";
import CraftItemDetails from "../craftItemDetails";
import EditCraftItem from "../editCraftItem";

export default (props) => {
    
    const urlParams = getURLParameters(location.href);
    
    const isAdmin = roles.is(roles.administrator);
    const isStorekeeper = roles.is(roles.storekeeper);
    const isPurchaseDepartment = roles.is(roles.purchaseDepartment);
    const isProduction = roles.is(roles.production);
    const isFinancialDepartment = roles.is(roles.financialDepartment);
    const isAs = roles.is(roles.as);
    
    const canCreateOrder = isStorekeeper || isAdmin;
    const canCreateProductionRequest = isProduction || isStorekeeper || isAdmin || isAs;
    const canViewPrice = isPurchaseDepartment || isFinancialDepartment || isAdmin;

    const dispatch = useDispatch()
    const stock = props.stock
    const stockState = useSelector(state => state.stocks)
    const [fetching, setFetching] = useState(false)
    const [error, setError] = useState(false)
    const [listOption, setListOption] = useState({
        stockId: stock.id,
        page: 1,
        onPage: stockState.onPage,
        desc: true,
        sortColumn: 'ItemNumber',
        drawdowns: false,
        ItemId: '',
        ItemName: '',
        SupplierId: '',
        SupplierName: '',
        query: ''
    })
    const [items, setItems] = useState([])
    const [count, setCount] = useState(0)

    const setSortColumn = (e, column) => {
        e.preventDefault()
        const desc = listOption.sortColumn !== column ? false : !listOption.desc
        setListOption(prevState => ({...prevState,
            sortColumn: column,
            desc: desc,
            page: 1
        }))
    }

    const setDrawdowns = (e) => {
        e.preventDefault();
        setListOption(prevState => ({
            ...prevState,
            page: 1,
            drawdowns: !listOption.drawdowns
        }))
    }

    const setFilterSupplierName = (data) => {
        if (data) {
              setListOption(prevState => ({
                  ...prevState,
                  page: 1,
                  SupplierId: data.id,
                  SupplierName: data.label
              }))
        }
    }

    const GetItems = () => {
        if (!fetching) {
            setFetching(true)
            setError(null)

            // Call api
            Api.post('/api/stocks/getItemsPerPage', listOption)
                .then(res => {
                    setItems(res.data.items)
                    setCount(res.data.count)
                })
                .catch(err => {
                    setError(err.message)
                })
                .finally(() => {
                    setFetching(false)
                })
        }
    };

    const onFilter = () => {
        if (listOption.query !== '') {
            if (!fetching) {
                setFetching(true)
                setError(null)

                Api.post('/api/stocks/FilterItemsByName', listOption)
                    .then(res => {
                        setItems(res.data.items)
                        setCount(res.data.count)
                    })
                    .catch(err => {
                        setError(err.message)
                    })
                    .finally(() => {
                        setFetching(false)
                    })
            }
        }
        else {
            GetItems()
        }
    };

    useMemo(() => {
        onFilter();
    }, [
        listOption.page,
        listOption.onPage,
        listOption.desc,
        listOption.sortColumn,
        listOption.drawdowns,
        listOption.ItemId,
        listOption.ItemName,
        listOption.SupplierId,
        listOption.SupplierName
    ]);

    const updatePagination = (data) => {
        setListOption(prevState => ({...prevState, page: data}));
    }

    const updateOnPage = (event, data) => {
        event.preventDefault();
        dispatch(itemsOnPageAction(data));
        setListOption(prevState => ({...prevState, page: 1, onPage: data}));
    }

    useEffect(() => {
        if(urlParams.query) {
            setListOption(prevState => ({...prevState, query: decodeURIComponent(urlParams.query)}));
        }
        else {
            GetItems()
        }
        
    }, [])

    useEffect(() => {
        if (stockState.added) {
            GetItems()
            dispatch(addToStockAction(false))
        }
    }, [stockState.added])

    const returnUpdateHandle = (id, value) => {
        const newList = items.map(x => {
            if (x.id === id) {
                return {
                    ...x,
                    amount: value
                }
            }
            return x;
        });
        setItems(newList);
    }

    const onItemOptionsOnStockHandle = () => {
        GetItems()
    }

    const onInputQuery = (e) => {
        setListOption({...listOption, query: e.target.value})
    }
    
    useDebounce(() => {
        onFilter()
    }, 1000, [listOption.query])

    return (
        <>
            {
                error && <Alert variant="danger">{error}</Alert>
            }

            <div className="table-responsive-x mt-3">
                <table className="table table-striped-columns">
                    <caption>
                        {
                            fetching ?
                            <div className="d-flex w-100 align-items-center">
                                <Spinner animation="border" variant="warning" size="sm"/>
                                <span className="text-muted ml-2">
                                    Подгрузка данных в таблицу
                                </span>
                            </div>
                            :
                            <>
                                Позиции по состоянию на {format(new Date(), 'H:mm, d MMMM yyyy', { locale: ru })}<br/>
                            </>
                        }
                        Всего позиций: {count}
                    </caption>
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    <a href="#"
                                       className="d-flex w-100 align-items-center sort-link"
                                       onClick={(e) => setSortColumn(e, 'ItemNumber')}>
                                        <span className="me-2 sort-link-label">
                                            ID:
                                        </span>
                                        {
                                            listOption.sortColumn === 'ItemNumber' ? listOption.desc ? <DownIcon/> : <UpIcon/> : <></>
                                        }
                                    </a>
                                </div>
                            </th>                    
                            <th>
                                <div>
                                    <a href="#"
                                       className="d-flex w-100 align-items-center sort-link"
                                       onClick={(e) => setSortColumn(e, 'Name')}>
                                        <span className="me-2 sort-link-label">
                                            Наименование:
                                        </span>
                                        {
                                            listOption.sortColumn === 'Name' ? listOption.desc ? <DownIcon/> : <UpIcon/> : <></>
                                        }
                                    </a>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <a href="#"
                                       className="d-flex w-100 align-items-center sort-link"
                                       onClick={(event) => setSortColumn(event, 'Supplier')}>
                                        <span className="me-2 sort-link-label">
                                            Поставщик:
                                        </span>
                                        {
                                            listOption.sortColumn === 'Supplier' ? listOption.desc ? <DownIcon/> : <UpIcon/> : <></>
                                        }
                                    </a>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <a href="#"
                                       className="d-flex w-100 align-items-center sort-link"
                                       onClick={(event) => setSortColumn(event, 'Amount')}>
                                        <span className="me-2 sort-link-label">
                                            На балансе:
                                        </span>
                                        {
                                            listOption.sortColumn === 'Amount' ? listOption.desc ? <DownIcon/> : <UpIcon/> : <></>
                                        }
                                    </a>
                                </div>
                            </th>
                            {
                                canViewPrice &&
                                <th>
                                    <div>
                                        <a href="#"
                                           className="d-flex w-100 align-items-center sort-link"
                                           onClick={(event) => setSortColumn(event, 'Price')}>
                                        <span className="me-2 sort-link-label">
                                            Цена:
                                        </span>
                                            {
                                                listOption.sortColumn === 'Price' ? listOption.desc ? <DownIcon/> : <UpIcon/> : <></>
                                            }
                                        </a>
                                    </div>
                                </th>
                            }
                            {
                                canViewPrice && <th>Стоимость:</th>
                            }
                            {
                                canCreateOrder &&
                                <th className="text-center">
                                    Закупить:
                                </th>
                            }
                            {
                                canCreateOrder &&
                                <th className="text-center">
                                    Возврат:
                                </th>
                            }
                            {
                                canCreateProductionRequest &&
                                <th className="text-center">
                                    Выдать:
                                </th>
                            }
                        </tr>
                        <tr>
                            <th />
                            <th>
                                <input
                                    type="text"
                                    placeholder={'Найти по наименованию или номеру'}
                                    value={listOption.query}
                                    className="form-control"
                                    onChange={onInputQuery}
                                />
                            </th>
                            <th>
                                <Autocomplete
                                    placeholder={'Найти по поставщику'}
                                    value={listOption.SupplierName}
                                    callback={setFilterSupplierName}
                                    extended={false}
                                    params={{stockId: listOption.stockId}}
                                    endpoint="/api/suggestions/SupplierByNameOnStock"
                                    inputClassName="form-control"/>
                            </th>
                            <th>
                                <div>
                                    <a href="#"
                                       onClick={setDrawdowns}
                                       className="d-flex w-100 align-items-center sort-link">
                                        <span className="me-2 sort-link-label">
                                            Срочно закупить
                                        </span>
                                        {
                                            listOption.drawdowns && <CheckIcon/>
                                        }
                                    </a>
                                </div>
                            </th>
                            {
                                canViewPrice && <th>&nbsp;</th>
                            }
                            {
                                canViewPrice && <th>&nbsp;</th>
                            }
                            {
                                canCreateOrder && <th/>
                            }
                            {
                                canCreateOrder && <th/>
                            }
                            {
                                canCreateProductionRequest && <th/>
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {
                        items.map(stockItem =>
                            <tr key={stockItem.id}>
                                <td>
                                    {stockItem.item.itemNumber}
                                </td>
                                <td>
                                    <div className="d-flex w-100">
                                        <div className="pe-3">
                                            {
                                                stockItem.item.name
                                            }
                                            {
                                                stockItem.item.isCrafted &&
                                                <sup className="ms-1"><span className="badge bg-dark badge-pill py-1 small">CRAFT</span></sup>
                                            }
                                            {
                                                stockItem.item.isCrafted &&
                                                <div className="mt-1">
                                                    <span className="text-muted">
                                                        
                                                        Состоит из <CraftItemDetails item={stockItem.item}>
                                                            {stockItem.item.craftChilds.length} позиций
                                                        </CraftItemDetails>
                                                    </span>
                                                </div>
                                            }
                                            <ItemPhotos
                                                itemPhotos={stockItem.item.itemPhotos}
                                                caption={stockItem.item.name} />
                                        </div>
                                        <div className="ms-auto">
                                            <DotsMenu>
                                                <ItemOptionsOnStock
                                                    classname={'dropdown-dots-item'}
                                                    stockItemId={stockItem.id}
                                                    callback={onItemOptionsOnStockHandle} />
                                                {
                                                    stockItem.item.isCrafted ?
                                                        <EditCraftItem
                                                            item={stockItem.item}
                                                            classname={'dropdown-dots-item'}
                                                            label={'Редактировать'}
                                                            callback={onItemOptionsOnStockHandle}
                                                        />
                                                        :
                                                        <EditItem
                                                            item={stockItem.item}
                                                            classname={'dropdown-dots-item'}
                                                            label={'Редактировать'}
                                                            callback={onItemOptionsOnStockHandle} />
                                                }
                                                <EditItemPhoto
                                                    classname={'dropdown-dots-item'}
                                                    item={stockItem.item}
                                                    callback={onItemOptionsOnStockHandle} />
                                            </DotsMenu>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <SupplierDetails supplier={stockItem.item.supplier}/>
                                </td>
                                <td>
                                    {round10(stockItem.amount, -1)}&nbsp;{getShortNameAmountType(stockItem.item.amountType)}

                                    {
                                        stockItem.minAmount !== 0 &&
                                        <div className={`small mt-1 ${round10(stockItem.amount, -1) <= stockItem.minAmount ? 'text-danger' : 'text-muted'}`}>
                                            Минимальный запас: {stockItem.minAmount}&nbsp;{getShortNameAmountType(stockItem.item.amountType)}
                                        </div>
                                    }
                                </td>
                                {
                                    canViewPrice &&
                                    <td>
                                        {
                                            formatCurrency(stockItem.item.price)
                                        }
                                    </td>
                                }
                                {
                                    canViewPrice &&
                                    <td>
                                        {formatCurrency(stockItem.item.price * stockItem.amount)}
                                    </td>
                                }
                                {
                                    canCreateOrder &&
                                    <td>
                                        <div className="d-flex w-100 justify-content-center">
                                            <AddOrderButton
                                                amountType={stockItem.amountType}
                                                product={stockItem.item}
                                                stock={stock} />
                                        </div>
                                    </td>
                                }
                                {
                                    canCreateOrder &&
                                        <td>
                                            <ReturnButton
                                                callback={returnUpdateHandle}
                                                stockItem={stockItem}/>
                                        </td>
                                }
                                {
                                    stockItem.amount > 0
                                        ? canCreateProductionRequest &&
                                        <td>
                                            <div className="d-flex w-100 justify-content-center">
                                                <AddProductionRequestButton
                                                    amountType={stockItem.item.amountType}
                                                    amount={stockItem.amount}
                                                    product={stockItem.item}
                                                    stock={stock} />
                                            </div>
                                        </td>
                                        :
                                        <td>
                                            <div className="small mt-1 text-center">
                                                <span className="badge bg-danger">Закончилось</span>
                                            </div>
                                        </td>
                                }
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>

            {
                !fetching &&
                <>
                    <Pagination
                        callback={updatePagination}
                        currentPage={listOption.page}
                        onPage={listOption.onPage}
                        count={count}/>
                </>
            }

            <div className="d-md-flex align-items-md-center mt-3 pb-5 text-center text-md-left">
                <div className="me-md-1 mt-2 mt-md-0">
                    Отображать по:
                </div>
                <div>
                    <a className={`onPage-link ${listOption.onPage === 10 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 10)}>10</a>
                    <a className={`onPage-link ${listOption.onPage === 30 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 30)}>30</a>
                    <a className={`onPage-link ${listOption.onPage === 50 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 50)}>50</a>
                    <a className={`onPage-link ${listOption.onPage === 100 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 100)}>100</a>
                    <a className={`onPage-link ${listOption.onPage === 150 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 150)}>150</a>
                </div>
            </div>
        </>
    )
}