import React, {useState} from "react"
import {Modal} from "react-bootstrap"
import Avatar from '../ui/avatar'
import {Link} from "react-router-dom"
import {formatPhoneNumber} from 'react-phone-number-input'
import {format} from "date-fns-tz";
import ru from "date-fns/locale/ru";

export default function UserDetails({
                                        user,
                                        showAvatar = true,
                                        showName = true,
                                        showJobPost = false,
                                        showModal = true,
                                        avatarSize = 26
                                    }) {
    
    const [showDetails, setShowDetails] = useState(false);

    const handleCloseDetailsModal = () => {
        showModal && setShowDetails(false);
    };
    const handleShowDetailsModal = (e) => {
        e.preventDefault();
        showModal && setShowDetails(true);
    };

    return (
        (user && user.id) ?
            <>
                <a href="#" onClick={handleShowDetailsModal} className="d-flex align-items-center">
                    {
                        showAvatar &&
                        <div className={`d-flex ${showName ? 'me-2' : ''}`} title={`${user.lastName} ${user.firstName}`}>
                            <Avatar path={user.avatar} size={avatarSize} />
                        </div>
                    }
                    {
                        showName &&
                        <span>
                            {`${user.lastName} ${user.firstName}`}
                            {
                                (showJobPost && user.jobPost) &&
                                <div className="text-muted small">{user.jobPost}</div>
                            }
                        </span>
                    }
                </a>
                <Modal show={showDetails} onHide={handleCloseDetailsModal}>
                    <Modal.Body>
                        <div className="d-flex flex-column justify-content-center">
                            <div className="d-flex justify-content-center w-100 mb-2">
                                <Avatar path={user.avatar} size={200} />
                            </div>
                            <div className="text-center">
                                <h4>
                                    {`${user.lastName} ${user.firstName}`}
                                </h4>
                                <div>
                                    <div className="text-muted mb-2 small">
                                        {user.gender === 0 ? "Был" : "Была"} в сети {format(new Date(user.lastActivity), 'dd.MM.yyyy в HH:mm', {locale: ru})}
                                    </div>
                                    <div>
                                        <strong>Должность:</strong> {user.jobPost || 'Не указано'}
                                    </div>
                                    <div>
                                        <strong>Email:</strong> {user.email || 'Не указано'}
                                    </div>
                                    <div>
                                        <strong>Телефон:</strong> {user.phoneNumber ?
                                        <a href={`tel:${user.phoneNumber}`}>{formatPhoneNumber(user.phoneNumber)}</a>
                                        : 'Не указано'}
                                    </div>
                                    <div className="mt-3 pb-3">
                                        <Link to={`/profile/${user.id}`}
                                              className={'btn btn-primary btn-sm'}
                                        >Профиль пользователя</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
            :
            <>
                &mdash;
            </>
    )
}