import React, {useState, useEffect} from "react";
import {Modal, Alert} from 'react-bootstrap';
import {getShortNameAmountType} from "../../helpers/amountTypes";
import roles from "../../helpers/roles";
import formatCurrency from "../../helpers/formatCurrency";

export default function CraftItemDetails({item, children}) {
    const [show, setShow] = useState(false);
    const onShow = (e) => {
        e.preventDefault();
        setShow(true);
    }
    const onHide = () => {
        setShow(false);
    }
    
    const canViewPrice =
        roles.is(roles.administrator) ||
        roles.is(roles.purchaseDepartment) ||
        roles.is(roles.financialDepartment);
    
    return (
        <>
            <a href="#" onClick={onShow}>
                {children}
            </a>
            <Modal show={show} onHide={onHide} size="xl">
                <Modal.Header closeButton>
                    <h5>Состав крафтовой позиции</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="display-18 mb-3">
                        {item.name}
                    </div>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Наименование:</th>
                                <th>Требуемое кол-во:</th>
                                { canViewPrice && <th>Цена:</th> }
                                { canViewPrice && <th>Стоимость:</th> }
                            </tr>
                        </thead>
                        <tbody>
                        {
                            item.craftChilds.map((x, i) => <tr key={i}>
                                <td>
                                    {x.child.itemNumber}
                                </td>
                                <td>
                                    {x.child.name}
                                </td>
                                <td>
                                    {x.amount} {getShortNameAmountType(x.child.amountType)}
                                </td>
                                { canViewPrice && <td>{formatCurrency(x.child.price)}</td> }
                                { canViewPrice && <td>{formatCurrency(x.amount * x.child.price)}</td> }
                            </tr>)
                        }
                        {
                            canViewPrice &&
                            <tr>
                                <td colSpan={4}>
                                    <div className="text-right">
                                        Итого:
                                    </div>
                                </td>
                                <td>
                                    {formatCurrency(item.price)}
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </>
    )
}