import React, {useState} from 'react'
import Api from '../../../helpers/axios'
import {ReactComponent as EditIcon} from "./edit.svg"
import {Modal, Spinner, Alert} from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field'

export default ({account, classNames, callback}) => {
    const initialDate = {
        id: account.id,
        name: account.name,
        startBalance: account.startBalance,
        orderNumber: account.orderNumber
    }
    const [data, setData] = useState(initialDate)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const onHideHandle = () => {
        setError(null)
        setData(initialDate)
        setShow(false)
    }
    const onShowHandle = () => {
        setShow(true)
    }
    
    const onChangeBalanceHandle = (val) => {
        if(!val) {
            val = 0
        }
        setData(prevState => ({...prevState, startBalance: val}));
    }

    const onChangeHandle = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' ? target.value - 0 : target.value;
        const {name} = target;
        setData(prevState => ({...prevState, [name]: value}));
    }

    const validate = () => {
        if (data.name === '') {
            setError('Укажите название счета')
            return false
        }

        return true
    }

    const onSaveHandle = () => {
        if(!loading && validate()) {
            setLoading(true)
            setError(null)

            Api.post('/api/bankAccount/update', data)
                .then(()=>{
                    callback()
                    onHideHandle()
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    return (
        <>
            <button
                title="Изменить"
                className={classNames}
                onClick={onShowHandle}
                disabled={show}>
                <EditIcon/>
            </button>
            <Modal show={show} onHide={onHideHandle}>
                <Modal.Body>
                    <div className="card-body">
                        <h5 className="mb-3">
                            Редактирование
                        </h5>
                        <div className="form-group">
                            <label htmlFor="orderNumber">Номер сортировки:</label>
                            <input
                                type="number"
                                id="orderNumber"
                                className="form-control"
                                name="orderNumber"
                                value={data.orderNumber}
                                onChange={onChangeHandle} />
                        </div>
                        <div className="form-group">
                            <label htmlFor={`edit-account-${data.id}`}>Название счета:</label>
                            <input
                                type="text"
                                id={`edit-account-${data.id}`}
                                className="form-control"
                                name="name"
                                value={data.name}
                                onChange={onChangeHandle}
                            />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor={`edit-startBalance-${data.id}`}>Начальный баланс счета:</label>
                            <CurrencyInput
                                id={`edit-startBalance-${data.id}`}
                                className="form-control"
                                name="startBalance"
                                allowNegativeValue={true}
                                suffix=" ₽"
                                decimalSeparator="."
                                defaultValue={data.startBalance}
                                decimalsLimit={2}
                                onValueChange={onChangeBalanceHandle}
                            />
                        </div>
                        
                        
                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }

                        <div>
                            <button
                                disabled={loading}
                                onClick={onSaveHandle}
                                className="btn btn-primary">
                                {
                                    loading ?
                                        <div className="d-flex align-items-center">
                                            <Spinner animation="border" size="sm" variant="light"/>
                                            <span className="ml-2">Сохранение</span>
                                        </div>
                                        :
                                        'Сохранить'
                                }
                            </button>
                            {
                                !loading &&
                                <button
                                    onClick={onHideHandle}
                                    className="btn btn-dark ml-2">Отмена</button>
                            }
                        </div>
                        
                        
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}