import React from "react";
import {useSelector} from 'react-redux';
import {Link} from "react-router-dom";
import roles from "../../helpers/roles";

export default () => {
    const order = useSelector(state => state.productionRequest);

    const canCreateOrder = roles.is(roles.production)
        || roles.is(roles.administrator)
        || roles.is(roles.as);

    return (
        (canCreateOrder && order.products.length > 0) &&
        <Link to='/request-cart' className="cart btn btn-sm btn-primary">
            <span className="cart-label">Запросить</span>
            {
                order.products.length > 0 &&
                <span className="cart-amount">{order.products.length}</span>
            }
        </Link>
    )
}