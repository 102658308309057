export const requestOrderStatus = {
    // Новая заявка
    new: 0,
    // На рассмотрении
    underConsideration: 1,
    // Полностью выдано
    completed: 2,
    // Выдано частично
    partiallyCompleted: 3,
    // Отменено
    canceled: 4,
    // Не принято заявителем
    notAccept: 5,
    
    getDisplayName: (currentStatus) => {
        switch (currentStatus) {
            case requestOrderStatus.new:
                return 'Новая заявка';
            case requestOrderStatus.underConsideration:
                return 'На рассмотрении';
            case requestOrderStatus.completed:
                return 'Исполнено';
            case requestOrderStatus.partiallyCompleted:
                return 'Выдано частично';
            case requestOrderStatus.canceled:
                return 'Отменено';
            case requestOrderStatus.notAccept:
                return 'Не принято заявителем';
            default:
                return 'unsupported status';
        }
    }
}

export default requestOrderStatus;