import React, {useEffect, useRef, useState} from 'react'
import Avatar from '../../components/ui/avatar'
import Api from '../../helpers/axios'
import {useParams} from "react-router-dom"
import {useSelector} from 'react-redux'
import {Alert, Spinner} from "react-bootstrap"
import {formatPhoneNumber} from 'react-phone-number-input'
import './style.scss'
import {useDispatch} from 'react-redux'
import {userUpdateAvatar} from '../../store/user/actions'

export default () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const [user, setUser] = useState(null)
    const currentUser = useSelector(state => state.user)
    const [isOwner, setIsOwner] = useState(false)
    const [error, setError] = useState({
        main: null,
        upload: null
    })
    const [fetch, setFetch] = useState({
        profile: false,
        upload: false
    })
    const inputFile = useRef()

    const updateUser = (filename) => {
        dispatch(userUpdateAvatar({
            avatar: filename
        }))

        setUser(prevState => ({...prevState, avatar: filename}))
    }

    const uploadAvatar = (file) => {
        if (!fetch.upload) {
            setFetch(prevState => ({...prevState, upload: true}))
            setError(prevState => ({...prevState, upload: null}))

            const formData = new FormData();
            formData.append('file', file, file.name);

            inputFile.current.value = null;

            Api.post('/api/user/uploadAvatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    updateUser(res.data.body)
                })
                .catch(err => {
                    setError(prevState => ({...prevState, upload: err.response.data.message}))
                })
                .finally(() => {
                    setFetch(prevState => ({...prevState, upload: false}))
                })
        }
    }

    const onChangeFileHandler = (event) => {
        uploadAvatar(event.target.files[0]);
    }

    const onDeletePhoto = () => {
        if (!fetch.upload) {
            setFetch(prevState => ({...prevState, upload: true}))
            setError(prevState => ({...prevState, upload: null}))

            Api.post('/api/user/DeleteAvatar', {id: currentUser.id})
                .then(() => {
                    updateUser('')
                })
                .catch(err => {
                    setError(prevState => ({...prevState, upload: err.response.data.message}))
                })
                .finally(() => {
                    setFetch(prevState => ({...prevState, upload: false}))
                })
        }
    }

    const selectFile = () => {
        inputFile.current.click();
    }

    const getProfile = () => {
        if (!fetch.profile) {
            setFetch(prevState => ({...prevState, profile: true}))
            Api.post('/api/user/getProfile', {id: id || currentUser.id})
                .then(res => {
                    const profile = res.data.body
                    setUser(profile)

                    if(profile.id === currentUser.id) {
                        setIsOwner(true)
                    }

                })
                .catch(err => setError(prevState => ({...prevState, main: err.response.data.message})))
                .finally(() => {
                    setFetch(prevState => ({...prevState, profile: false}))
                })
        }
    }

    useEffect(()=> {
        getProfile()
    }, [])


    return (
        <div className="container">
            {
                fetch.profile &&
                <div className="d-flex w-100 justify-content-center">
                    <Spinner animation="border" size="sm" />
                    <span className="ml-2">Загрузка данных</span>
                </div>
            }
            {
                error.main && <Alert variant="danger">{error.main}</Alert>
            }
            {
                user !== null &&
                    <div className="text-center">
                        <div className="profile-avatar">
                            <div>
                                <Avatar path={user.avatar} size={100} />
                            </div>
                            {
                                isOwner && <>
                                <div className="profile-avatar-controls d-flex w-100 justify-content-center">
                                    {
                                        fetch.upload ?
                                            <div className="d-flex align-items-center ml-2">
                                                <Spinner variant="warning" size="sm" animation="border" /><span className="ml-2">Обработка...</span>
                                            </div>
                                            :
                                            <div className="d-flex">
                                                <a href="#"
                                                   className="btn btn-outline-primary"
                                                   onClick={selectFile}>Загрузить фото</a>

                                                {
                                                    user.avatar &&
                                                    <a href="#"
                                                       className="ms-2 btn btn-outline-danger"
                                                       onClick={onDeletePhoto}>Удалить фото</a>
                                                }
                                            </div>
                                    }

                                    <input
                                        ref={inputFile}
                                        type="file"
                                        name="file"
                                        style={{display: 'none'}}
                                        onChange={onChangeFileHandler} />
                                </div>
                                    {
                                        error.upload && <Alert variant="danger">{error.upload}</Alert>
                                    }
                                </>
                            }
                        </div>

                        <h2>{`${user.firstName} ${user.lastName}`}</h2>

                        <div>
                            <div>
                                <strong>Должность:</strong> {user.jobPost || 'Не указано'}
                            </div>
                            <div>
                                <strong>Email:</strong> {user.email || 'Не указано'}
                            </div>
                            <div>
                                <strong>Телефон:</strong> {user.phoneNumber ?
                                <a href={`tel:${user.phoneNumber}`}>{formatPhoneNumber(user.phoneNumber)}</a>
                                : 'Не указано'}
                            </div>
                        </div>

                        {
                            isOwner &&
                            <div className="mt-3">
                                <h5>Уведомления Telegram Бота</h5>
                                {
                                    user.telegramChatId ?
                                        <div className="display-bold text-success">
                                            Уведомления подлючены
                                        </div>
                                        :
                                        <div>
                                            <div className="display-bold alert alert-danger mb-3">
                                                Уведомления не подлючены!
                                            </div>
                                            <div>
                                                <a
                                                    className="btn btn-primary btn-sm"
                                                    target="_blank"
                                                    href={`https://t.me/PromRezerv_Bot?start=${user.id}`}>
                                                    Включить уведомления
                                                </a>
                                            </div>
                                            <div className="mt-2 text-muted">
                                                Когда перейдете на бота - нажмите "Запустить" или "Start" в диалоге с ботом
                                            </div>
                                        </div>
                                }
                            </div>
                        }
                    </div>
            }
        </div>
    )
}