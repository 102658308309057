import React, {useState, useMemo, useEffect} from "react"
import Api from '../../../helpers/axios'
import {Modal, Alert, Spinner} from "react-bootstrap"
import PropTypes from 'prop-types'
import UploadItemPhoto from '../../uploadItemPhotos'
import CurrencyInput from 'react-currency-input-field'
import Autocomplete from "../../autocomplete";
import PhoneInput from "react-phone-number-input/input";
import roles from "../../../helpers/roles";

function EditItemButton(props) {
    const callback = props.callback;
    const initialData = props.item || {};
    const classname = props.classname || 'btn btn-sm btn-primary';
    const label = props.label || 'Изменить позицию';
    const [editedItem, setEditedItem] = useState(props.item);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isNewSupplier, setIsNewSupplier] = useState(false);

    const closeModalHandle = () => {
        setShowModal(false);
    };

    const changeItemHandle = (e) => {
        const {target} = e;
        const {name} = target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        setEditedItem(prevState => ({...prevState, [name]: value}));
    };

    // Обработка полей формы для поставщика
    const onChangeSupplierHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setEditedItem(prevState => ({ ...prevState, supplier: {...prevState.supplier, [name]: value} }));
    };

    // Change phoneNumber
    const onChangeSupplierPhone = (value) => {
        setEditedItem(prevState => ({ ...prevState, supplier: {...prevState.supplier, phone: value || ''} }));
    };

    // Get data from supplier autocomplete
    const updateSupplier = (selectedSupplier) => {
        
        if (selectedSupplier.id !== "") {
            setEditedItem(prevState => ({
                ...prevState,
                supplierId: selectedSupplier.id,
                supplier: {
                    ...prevState.supplier,
                    id: selectedSupplier.id,
                    name: selectedSupplier.label
                }
            }));
        }
        else {
            setEditedItem(prevState => ({
                ...prevState,
                supplierId: "",
                supplier: {
                    ...prevState.supplier,
                    id: "",
                    name: selectedSupplier.label,
                    brand: "",
                    address: "",
                    phone: "",
                    manager: "",
                    url: "",
                    note: "",
                    items: []
                }
            }));
        }
    };

    useMemo(()=>{
        if (editedItem.supplier && editedItem.supplier.name !== '') {
            setIsNewSupplier(editedItem.supplier.id === '');
        }
        else {
            setIsNewSupplier(false);
        }
    }, [editedItem.supplier]);
  
    const changePrice = (val) => {
        if (!val) {
            val = 0;
        }
        setEditedItem(prevState => ({...prevState, price: val}));
    };

    const validate = () => {
        if(editedItem.name === '') {
            setError('Укажите наименование позиции');
            return false;
        }
        
        return true;
    };
    
    const saveChangesHandle = () => {
        if (!loading && validate()) {
            setLoading(true);
            setError(null);

            Api.post('/api/items/update', editedItem)
                .then(() => {
                    setShowModal(false);
                    callback(editedItem);
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    
    const updatePhotosHandle = (item) => {
        setEditedItem(prevState => ({...prevState, itemPhotos: item.itemPhotos}));
    };

    const isAdmin = roles.is(roles.administrator);
    const isStorekeeper = roles.is(roles.storekeeper);
    const isPurchaseDepartment = roles.is(roles.purchaseDepartment);
    return <>
        <a
            href="#edit"
            onClick={(e) =>{
                e.preventDefault()
                setShowModal(true)
            }}
            className={classname}>
            {label}
        </a>

        <Modal show={showModal} onHide={closeModalHandle}>
            <Modal.Body>
                <h3>
                    Редактирование позиции
                </h3>
                <div className="mt-3">
                    
                    <UploadItemPhoto
                        currentItem={editedItem}
                        callback={updatePhotosHandle} />
                    
                    <hr/>
                    
                    <div className="mb-3">
                        <label htmlFor="item-name-edit">Наименование:</label>
                        <textarea
                            id="item-name-edit"
                            name="name"
                            className="form-control"
                            value={editedItem.name}
                            onChange={changeItemHandle}
                        />
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="item-brand-edit">Бренд:</label>
                        <input type="text"
                               id="item-brand-edit"
                               name="brand"
                               className="form-control"
                               value={editedItem.brand}
                               onChange={changeItemHandle}
                        />
                    </div>

                    {
                        (isAdmin || isPurchaseDepartment || editedItem.price === 0) &&
                        <div className="mb-3">
                            <label>Цена:</label>
                            <CurrencyInput
                                id="flow-contractPrice"
                                className="form-control"
                                name="contractPrice"
                                allowNegativeValue={true}
                                suffix=" ₽"
                                decimalSeparator="."
                                defaultValue={editedItem.price}
                                value={editedItem.price}
                                decimalsLimit={4}
                                onValueChange={changePrice} />
                        </div>
                    }

                    {
                        (isAdmin || isStorekeeper || isPurchaseDepartment) &&
                        <div className="pt-3">
                            <div>
                                <h5>
                                    Поставщик позиции
                                </h5>
                                <div className="mb-3">
                                    <label>Название:</label>
                                    <Autocomplete
                                        value={editedItem.supplier ? editedItem.supplier.name : ''}
                                        defaultId={editedItem.supplier ? editedItem.supplier.id : ''}
                                        callback={updateSupplier}
                                        endpoint="/api/suggestions/suppliers"
                                        inputClassName="form-control"/>
                                </div>
                                {
                                    isNewSupplier &&
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="supplier_brand">Бренд:</label>
                                            <input type="text"
                                                   id="supplier_brand"
                                                   className="form-control"
                                                   name="brand"
                                                   value={editedItem.supplier.brand}
                                                   onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="supplier_manager">Контактное лицо:</label>
                                            <input type="text"
                                                   id="supplier_manager"
                                                   className="form-control"
                                                   name="manager"
                                                   value={editedItem.supplier.manager}
                                                   onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="supplier_phone">Телефон:</label>
                                            <PhoneInput type="text"
                                                        international={true}
                                                        withCountryCallingCode={true}
                                                        defaultCountry="RU"
                                                        id="supplier_phone"
                                                        className="form-control"
                                                        name="phone"
                                                        value={editedItem.supplier.phone}
                                                        onChange={onChangeSupplierPhone}
                                                        placeholder="+7 ___ __ __"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="supplier_address">Адрес:</label>
                                            <textarea
                                                id="supplier_address"
                                                className="form-control"
                                                name="address"
                                                value={editedItem.supplier.address}
                                                onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="supplier_url">Сайт:</label>
                                            <input type="text"
                                                   id="supplier_url"
                                                   className="form-control"
                                                   name="url"
                                                   value={editedItem.supplier.url}
                                                   onChange={onChangeSupplierHandle} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="supplier_note">Дополнительная информация по поставщику:</label>
                                            <textarea
                                                id="supplier_note"
                                                className="form-control"
                                                name="note"
                                                value={editedItem.supplier.note}
                                                onChange={onChangeSupplierHandle} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    
                </div>
                {
                    error && <Alert variant="danger">{error}</Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                {!loading &&
                <button
                    onClick={() => {
                        setEditedItem(initialData);
                        setShowModal(false);
                    }}
                    className="btn btn-dark">Отмена</button>
                }


                <button
                    onClick={saveChangesHandle}
                    className="btn btn-primary">
                    {
                        loading ?
                            <div className="d-flex align-items-center">
                                <Spinner animation="border" variant="light" size="sm"/>
                                <span className="ms-2">Сохраняем</span>
                            </div>
                            :
                            'Сохранить'
                    }
                </button>
            </Modal.Footer>
        </Modal>
    </>
}

EditItemButton.propTypes = {
    item: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
}

export default EditItemButton