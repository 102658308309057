import React, {useState, useEffect, useMemo} from "react";
import Api from '../../helpers/axios';
import {Alert} from "react-bootstrap";
import Pagination from '../pagination';
import {useSelector, useDispatch} from 'react-redux';
import {itemCreateEventAction, itemsOnPageAction} from '../../store/items/actions';
import SupplierDetails from '../../components/supplierDetails';
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import userHaveRole from "../../helpers/userHaveRole";
import EditItemButton from "../buttons/editItem";
import ItemPhotos from "../itemPhotos";
import {getShortNameAmountType} from '../../helpers/amountTypes';
import {Link} from "react-router-dom";
import useDebounce from "../../helpers/hooks/useDebounce";
import FetchState from "../ui/fetchState";

export default () => {
    const canViewPrice = userHaveRole("Purchase Department")
        || userHaveRole("Financial Department")
        || userHaveRole("Administrator");

    const canEdit = userHaveRole("Purchase Department")
        || userHaveRole("Administrator");

    const dispatch = useDispatch();
    const itemsState = useSelector(state => state.items);
    const [items, setItems] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [listOption, setListOption] = useState({
        page: 1,
        onPage: itemsState.onPage,
        desc: false,
        orderBy: ''
    });
    const [filterQuery, setFilterQuery] = useState('');

    const getItems = () => {
        if (!loading) {
            setLoading(true);
            setError('');

            // Call api
            Api.post('/api/items/perPage', listOption)
                .then(res => {
                    setItems(res.data.items);
                    setCount(res.data.count);
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const updatePagination = (data) => {
        setListOption(prevState => ({...prevState, page: data}));
    };

    const updateOnPage = (event, data) => {
        event.preventDefault();
        setListOption(prevState => ({...prevState, page: 1, onPage: data}));
        dispatch(itemsOnPageAction(data));
    };

    useEffect(() => {
        getItems();
    }, []);

    useEffect(() => {
        if (itemsState.createEvent) {
            getItems();
            dispatch(itemCreateEventAction(false));
        }
    }, [itemsState]);

    useMemo(() => {
        if (items !== null) {
            getItems();
        }
    }, [listOption]);

    const updateItemHandle = (updatedItem) => {
        const data = items.map(item => {
            if (item.id === updatedItem.id) {
                item = updatedItem
            }

            return item;
        });

        setItems(data);
    }

    const onFilterItems = (e) => {
        
        if(e.target.value === '') {
            GetItems()
        }
        
        setFilterQuery(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            
            if (filterQuery != null) {
                onFilterHandle()
            } else {
                GetItems()
            }
        }
    }
    
    const onFilterHandle = () => {
        if (filterQuery != null) {
            if (!loading) {
                setLoading(true)
                setError(null)

                Api.post('/api/items/filterItemsByName', {query: filterQuery})
                    .then(res => {
                        setItems(res.data.items)
                        setCount(res.data.count)
                    })
                    .catch(err => {
                        setError(err.message)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } else {
            GetItems()
        }
    }

    useDebounce(() => {
        onFilterHandle()
    }, 1300, [filterQuery])
    
    const onDeleteHandle = (id) => {
        if (confirm('Уверены, что хотите безвозвратно удалить позицию? Она будет полностью удалена с базы.')) {
            if (!loading) {
                setLoading(true);
                setError('');
                
                Api.post('/api/items/delete', {id: id})
                    .then(() => {
                        getItems();
                    })
                    .catch((err) => {
                        setError(err.response.data.message)
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    }

    return (
        <>
            {
                error && <Alert variant="danger">{error}</Alert>
            }

            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="d-flex align-items-center mb-3 w-auto">
                        <input
                            type="text"
                            className="form-control"
                            onChange={onFilterItems}
                            onKeyDown={handleKeyDown}
                            value={filterQuery}
                            placeholder="Поиск по наименованию"
                        />
                        <button
                            onClick={onFilterHandle}
                            className="btn btn-primary btn-sm ms-1">
                            {
                                loading ? <FetchState loading={loading} label="Поиск" size="sm" variant="light" />
                                    :
                                    'Найти'
                            }
                        </button>
                    </div>
                </div>
            </div>

            <div className="table-responsive mt-1">
                <table className="table table-striped table-bordered">
                    <caption>
                        {
                            !loading &&
                            <div>
                                Позиции по состоянию на {format(new Date(), 'H:mm, d MMMM yyyy', { locale: ru })}<br/>
                            </div>
                        }
                        
                        Всего позиций: {count}
                    </caption>
                    <thead>
                        <tr>
                            <th>ID:</th>
                            <th>
                                Наименование:
                            </th>
                            <th>
                                Поставщик:
                            </th>
                            <th>
                                Наличие:
                            </th>
                            {
                                canViewPrice &&
                                <th>
                                    Цена:
                                </th>
                            }
                            {
                                canEdit && <th/>
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {
                        items &&
                        items.map(item => <tr key={item.id}>
                            <td>
                                {item.itemNumber}
                            </td>
                            <td>
                                {item.name}
                                <div className="text-muted mt-2">
                                    {item.brand ? <>{item.brand}</> : <>&mdash;</>}
                                </div>
                                <ItemPhotos
                                    caption={item.name}
                                    itemPhotos={item.itemPhotos} />
                            </td>
                            <td>
                                <SupplierDetails supplier={item.supplier}/>
                            </td>
                            <td>
                                {
                                    item.stockItems.length > 0 ?
                                        item.stockItems.map(s => 
                                            <div key={s.id}>
                                                <div className="mb-3">
                                                    <div className="mb-1">
                                                        {s.amount} {getShortNameAmountType(item.amountType)}&nbsp;
                                                        на <Link to={`/stock/${s.stockId}?query=${item.id}`}>{s.stock.name}</Link>
                                                    </div>
                                                    <div className="small text-muted">{s.stock.address}</div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <>&mdash;</>
                                }
                            </td>
                            {
                                canViewPrice &&
                                <td>
                                    {
                                        new Intl.NumberFormat('ru-RU', {
                                            style: 'currency',
                                            currency: 'RUB'
                                        }).format(item.price)
                                    }
                                </td>
                            }
                            {
                                canEdit &&
                                <td>
                                    <div className="d-flex">
                                        <EditItemButton
                                            callback={updateItemHandle}
                                            label={'Изменить'}
                                            item={item} />
                                        <div>
                                            <button
                                                onClick={() => onDeleteHandle(item.id)}
                                                className="btn btn-danger btn-sm ms-2">
                                                Удалить
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            }
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>

            {
                !loading &&
                <>
                    <Pagination
                        callback={updatePagination}
                        currentPage={listOption.page}
                        onPage={listOption.onPage}
                        count={count}/>
                </>
            }


            <div className="d-md-flex align-items-md-center mt-3 pb-5 text-center text-md-left">
                <div className="me-md-1 mt-2 mt-md-0">
                    Отображать по:
                </div>
                <div>
                    <a className={`onPage-link ${listOption.onPage === 10 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 10)}>10</a>
                    <a className={`onPage-link ${listOption.onPage === 30 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 30)}>30</a>
                    <a className={`onPage-link ${listOption.onPage === 50 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 50)}>50</a>
                    <a className={`onPage-link ${listOption.onPage === 100 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 100)}>100</a>
                    <a className={`onPage-link ${listOption.onPage === 150 ? 'onPage-link--active' : ''}`}
                       onClick={(e) => updateOnPage(e, 150)}>150</a>
                </div>
            </div>
        </>
    )
}