import React, {useState, useMemo} from "react"
import PropTypes from 'prop-types'
import {Modal, Alert} from "react-bootstrap"
import {getShortNameAmountType} from "../../../helpers/amountTypes"
import StockDetails from '../../stockDetails'
import UserDetails from '../../userDetails'
import Api from '../../../helpers/axios'
import {requestOrderStatus} from "../../../helpers/getStatusRequest"
import ItemPhotos from "../../itemPhotos";
import userHaveRole from "../../../helpers/userHaveRole";
import FetchState from "../../ui/fetchState";

function ConfirmRequest(props) {
    const initialOrder = props.order;
    const [order, setOrder] = useState(initialOrder);
    const callback = props.callback;

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    
    const canDelete = userHaveRole('Administrator');
    const canConfirm = userHaveRole('Storekeeper');

    const showModalHandle = () => {
        setShowModal(true)
    }
    const closeModalHandle = () => {
        setShowModal(false)
        setError(null)
        setSuccess(null)
    }

    function getOrder() {
        Api.post('/api/requestOrders/getOrder', {id: order.id})
            .then(res => {
                setOrder(res.data.body)
                callback(res.data.body)
            })
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoading(false))
    }
    
    const [deleting, setDeleting] = useState(false);
    const onDeleteHandle = () => {
        if (confirm('Уверены что хотите удалить заявку?')) {
            if (!deleting) {
                setDeleting(true);
                setError(null);

                Api.post('/api/requestOrders/delete', {id: order.id})
                    .then(() => {
                        setSuccess("Заявка удалена");
                        location.reload();
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setDeleting(false)
                    })
            }
        }
    }

    const onConfirmAllHandle = async () => {
        if (!loading) {
            setLoading(true)
            setError(null)
            setSuccess(null)

            Api.post('/api/requestOrders/confirmAll', {id: order.id})
                .then(() => {
                    getOrder()
                    setSuccess("Позиции полностью выданы")
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    const setUnderConsiderationHandle = () => {
        setLoading(true);
        
        Api.post('/api/requestOrders/SetUnderConsiderationState', {id: order.id})
            .then(res => {
                setOrder(res.data.body)
                callback(res.data.body)
            })
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoading(false));
    };
    
    const onDeletePositionHandle = (e, positionId) => {
        e.preventDefault();

        if (confirm('Уверены что хотите удалить позицию?')) {
            setLoading(true);
            
            Api.post('/api/requestOrders/DeletePosition', {
                id: positionId,
                requestOrderId: order.id
            })
                .then(() => {
                    getOrder();
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => setLoading(false));
        }
    }

    useMemo(() => {
        if(showModal && order.state === 0) {
            setUnderConsiderationHandle();
        }
    }, [showModal]);

    const displayAmount = (amount) => {

        let result = amount - 0;

        if (!Number.isInteger(result)) {
            result = result.toFixed(3);
        }

        return result;
    };

    return (
        <>
            {
                (canConfirm || canDelete) &&
                <button
                    disabled={showModal}
                    onClick={showModalHandle}
                    className={`btn btn-sm ${(order.state === 0 || order.state === 1) ? 'btn-primary' : 'btn-dark'}`}>
                    {
                        order.state === 0 ? 'Рассмотреть заявку' : order.state === 1 ? 'Выдать позиции' : 'Открыть заявку'
                    }
                </button>
            }
            
            <Modal
                size="xl"
                show={showModal}
                onHide={closeModalHandle}>
                <Modal.Body>
                    <h3>
                        Заявка на выдачу позиций
                    </h3>

                    <div className="mt-3">

                        <div className="mb-3">
                            <UserDetails user={order.author}/>
                        </div>

                        <h5>Куда / Проект:</h5>
                        <div className="mb-3">
                            {order.project ?
                                <div className="positions-item">
                                    <div className="project-name">{order.project.name}</div>
                                    <div className="project-description">{order.project.description}</div>
                                </div>
                                : 'Не указано'}
                        </div>

                        <h5>
                            Требуется:
                        </h5>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Наименование</th>
                                    <th>Запрошено</th>
                                    {
                                        (order.state === 2 || order.state === 3) &&
                                        <th>
                                            Выдано
                                        </th>
                                    }
                                    <th>
                                        На балансе
                                    </th>
                                    {
                                        (order.state === 0 || order.state === 1) && <th/>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {order.positions.map(position => <tr key={position.id}>
                                <td>
                                    <div>
                                        <ItemPhotos
                                            itemPhotos={position.item.itemPhotos}
                                            caption={position.item.name} />
                                    </div>
                                    <div>
                                        {position.item.name}
                                    </div>
                                    <div className="mt-1 small">
                                        Со склада: <StockDetails stock={position.stock}/>
                                    </div>
                                </td>
                                <td>
                                    {displayAmount(position.amount)} {getShortNameAmountType(position.amountType)}
                                </td>
                                {
                                    (order.state === 2 || order.state === 3) &&
                                    <td>
                                        {displayAmount(position.amountIssued)} {getShortNameAmountType(position.amountType)}
                                    </td>
                                }
                                <td>
                                    {
                                        (position.item.stockItems && position.item.stockItems[0])
                                            ? `${displayAmount(position.item.stockItems[0].amount)} ${getShortNameAmountType(position.item.amountType)}`
                                            : <span className="text-muted">&mdash;</span>
                                    }
                                </td>
                                {
                                    (order.state === 0 || order.state === 1) &&
                                    <td>
                                        <div className="text-center">
                                            <button
                                                onClick={e => onDeletePositionHandle(e, position.id)}
                                                className="btn btn-outline-danger small btn-sm">Удалить</button>
                                        </div>
                                    </td>
                                }
                            </tr>)}
                            </tbody>
                        </table>
                        </div>
                        <div>
                            <h6>
                                Статус заявки: <b>{requestOrderStatus.getDisplayName(order.state)}</b>
                            </h6>
                        </div>
                    </div>

                    {
                        error && <Alert variant="danger">{error}</Alert>
                    }

                    {
                        success && <Alert variant="success">{success}</Alert>
                    }

                    <div className="d-flex w-100 pt-4 pb-3">
                        {
                            ((canConfirm || canDelete) && order.state !== 2) &&
                            <div>
                                {!loading ?
                                    <button
                                        onClick={onConfirmAllHandle}
                                        className="btn btn-primary">
                                        Выдать все
                                    </button>
                                    :
                                    <FetchState loading={loading} label="Обработка запроса" size="sm" variant="warning" />
                                }
                            </div>
                        }
                        
                        {
                            (order.state > 1) &&
                                <div>
                                    <button
                                        onClick={setUnderConsiderationHandle}
                                        className="btn btn-secondary">
                                        Вернуть на рассмотрение
                                    </button>
                                    <div className="text-danger mt-1 small">
                                        Позиции будут автоматически возвращены на склад
                                    </div>
                                </div>
                        }

                        <div className="ms-auto">
                        <button
                            className="btn btn-dark"
                            onClick={closeModalHandle}>Закрыть карточку</button>

                        {
                            (canDelete) &&
                            <button
                                onClick={onDeleteHandle}
                                className="btn btn-danger ms-2">
                                {
                                    deleting ?
                                        <FetchState loading={deleting} label="Удаление" size="sm" variant="light" /> :
                                        'Удалить заявку'
                                }
                            </button>
                        }
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

ConfirmRequest.propTypes = {
    order: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
}

export default ConfirmRequest