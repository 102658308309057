import React from "react";
import './style.scss';
import {ReactComponent as LightIcon} from "./sun.svg";
import {ReactComponent as DarkIcon} from "./moon.svg";
import {useSelector, useDispatch} from 'react-redux';
import {changeAppThemeAction} from "../../store/theme/actions";

export default function ThemeSwitcher() {
    const dispatch = useDispatch();
    const themeState = useSelector(state => state.themes);

    const changeThemeHandle = (e) => {
        e.preventDefault();
        const themeName = themeState.currentTheme === 'theme-light' ? 'theme-dark': 'theme-light';
        dispatch(changeAppThemeAction(themeName));
    }

    return <div onClick={changeThemeHandle}
        className={`theme-switcher ${themeState.currentTheme}`}>
        {
            themeState.currentTheme === 'theme-dark' ? <LightIcon/> : <DarkIcon/>
        }
    </div>
}