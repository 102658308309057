import React, {useEffect, useState} from 'react';
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import MainRouter from './router';
import store from './store';
import './assets/css/main.scss';
import NetworkDetector from './networkDetector';
import {Notifications as NotificationsComponent} from "./components/notifications";
import {SignalContext} from './contexts/signalContext';
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {Connections} from './contexts/signalContext/types';

export function App() {
    const [isConnected, setIsConnected] = useState(true);

    /*
    Connections.Notifications = new HubConnectionBuilder()
        .withUrl('/notificationHub')
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        if (!isConnected) {
            Connections.Notifications.start()
                .then(() => {
                    setIsConnected(true);
                })
                .catch(err => console.log(`Error while starting connection: ${err}`));
        }
    }, []);
     */

        return (
        <Provider store={store}>
            <SignalContext.Provider value={Connections}>
                <Router>
                    <MainRouter/>
                    <NotificationsComponent/>
                </Router>
            </SignalContext.Provider>
        </Provider>
    );
}

export default NetworkDetector(App);