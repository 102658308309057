import React, {Suspense, lazy} from 'react';
import { Route, Switch } from 'react-router-dom';
import {useSelector} from "react-redux";
import Loader from '../components/ui/loader';

import {PrivateRoute} from './privateRoute';
import {AdminRoute} from './adminRoute';
import {PrintRoute} from './printRoute';

// pages
import CalcPage from '../pages/calc';
import Calc3mPage from '../pages/calc/calc3m';
import HomePage from '../pages/home';
import NotFound from '../pages/notFound';
import AccessDenied from '../pages/accessDenied';
import LoginPage from '../pages/login';
import LogoutPage from '../pages/logout';
import StocksPage from '../pages/stocks';
import StockPage from '../pages/stock';
import ItemsPage from '../pages/items';
import SuppliersPage from '../pages/suppliers';
import OrderCartPage from '../pages/orderCart';
import OrdersPage from '../pages/orders';
import OrderPage from '../pages/order';
import RequestCartPage from '../pages/requestCart';
import RequestsPage from '../pages/requests';
import RequestPage from '../pages/request';
import RequestPrintPage from '../pages/request/print';
import ProjectsPage from '../pages/projects';
import FlowPage from '../pages/finances/flow';
import BankAccountsPage from '../pages/finances/accounts';
import ReportOrdersPage from '../pages/reports/orders';
import ProfilePage from '../pages/profile';
const NewProjectPage = lazy(() => import('../pages/projects/new'));
const TasksPage = lazy(() => import('../pages/tasks'));
const TaskPage = lazy(() => import('../pages/tasks/task'));
const AddTaskPage = lazy(() => import('../pages/tasks/add'));
const EditTaskPage = lazy(() => import('../pages/tasks/edit'));
const DealsPage = lazy(() => import('../pages/deals'));
const AddClientPage = lazy(() => import('../pages/deals/addClient'));
const ClientPage = lazy(() => import('../pages/deals/client'));
const FilesPage = lazy(() => import('../pages/files'));
const EditClientProjectPage = lazy(() => import('../pages/deals/editProject'));
const SheetPage = lazy(() => import('../pages/sheet'));
const OptionalItemsPage = lazy (() => import('../pages/oe'));
const ClientsProjects = lazy(() => import('../pages/clients-projects'));
const ProjectPage = lazy(() => import('../pages/client-project'));
const ProjectEditPage = lazy(() => import('../pages/client-project/edit'));
const DocumentsPage = lazy(() => import('../pages/documents'));
const TasksManagerPage = lazy(() => import('../pages/tasks-manager'));
const FunnelPage = lazy(() => import('../pages/funnel'));
const FunnelsPage = lazy(() => import('../pages/funnels'));
const NotificationsPage = lazy(() => import('../pages/notifications'));

const AdminHomePage = lazy(() => import('../admin/pages/home'));
const AdminUsersPage = lazy(() => import('../admin/pages/users'));
const AdminStocksPage = lazy(() => import('../admin/pages/stocks'));
const AdminSuppliersPage = lazy(() => import('../admin/pages/suppliers'));
const AdminItemsPage = lazy(() => import('../admin/pages/items'));
const AdminParserPage = lazy(() => import('../admin/pages/parser'));
const AdminCalc = lazy(() => import('../admin/pages/calc'));
const AdminOptionPage = lazy(() => import('../admin/pages/options'));
const AdminDataPropsPage = lazy(() => import('../admin/pages/dataProps'));
const AdminContactTypePage = lazy(() => import('../admin/pages/contacts'));
const AdminFileGroup = lazy(() => import('../admin/pages/fileGroup'));
const AdminFunnelPage = lazy(() => import('../admin/pages/funnel'));
const AdminFunnelsPage = lazy(() => import('../admin/pages/funnels'));

export default () => {

    const themeState = useSelector(state => state.themes);
    document.documentElement.className = themeState.currentTheme;

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <PrintRoute exact path='/request/print/:id' component={RequestPrintPage} />
                
                <PrivateRoute exact path='/' component={TasksPage} />
                <PrivateRoute exact path='/calc_old' component={CalcPage} />
                <PrivateRoute exact path='/calc' component={Calc3mPage} />
                <PrivateRoute exact path='/stocks' component={StocksPage} />
                <PrivateRoute exact path='/stock/:id' component={StockPage} />
                <PrivateRoute exact path='/items' component={ItemsPage} />
                <PrivateRoute exact path='/suppliers' component={SuppliersPage} />
                <PrivateRoute exact path='/order-cart' component={OrderCartPage} />
                <PrivateRoute exact path='/orders' component={OrdersPage} />
                <PrivateRoute exact path='/order/:id' component={OrderPage} />
                <PrivateRoute exact path='/request-cart' component={RequestCartPage} />
                <PrivateRoute exact path='/requests' component={RequestsPage} />
                <PrivateRoute exact path='/request/:id' component={RequestPage} />
                <PrivateRoute exact path='/projects' component={ProjectsPage} />
                <PrivateRoute exact path='/new-project' component={NewProjectPage} />
                <PrivateRoute exact path='/finances/flow/:id' component={FlowPage} />
                <PrivateRoute exact path='/finances/accounts' component={BankAccountsPage} />
                <PrivateRoute exact path='/reports/orders' component={ReportOrdersPage} />
                <PrivateRoute exact path='/profile/:id?' component={ProfilePage} />
                <PrivateRoute exact path='/tasks' component={TasksPage} />
                <PrivateRoute exact path='/tasks/add/:parentId?' component={AddTaskPage} />
                <PrivateRoute exact path='/tasks/edit/:id' component={EditTaskPage} />
                <PrivateRoute exact path='/task/:id' component={TaskPage} />
                <PrivateRoute exact path='/deals' component={DealsPage} />
                <PrivateRoute exact path='/deals/add-client' component={AddClientPage} />
                <PrivateRoute exact path='/client/:id' component={ClientPage} />
                <PrivateRoute exact path='/docs' component={FilesPage} />
                <PrivateRoute exact path='/client-project/:id' component={ProjectPage} />
                <PrivateRoute exact path='/client-project/edit/:id' component={ProjectEditPage} />
                <PrivateRoute exact path='/project/edit/:id' component={EditClientProjectPage} />
                <PrivateRoute exact path='/sheet/:id' component={SheetPage} />
                <PrivateRoute exact path='/oe' component={OptionalItemsPage} />
                <PrivateRoute exact path='/clients-projects' component={ClientsProjects} />                
                <PrivateRoute exact path='/my-docs' component={DocumentsPage} />
                <PrivateRoute exact path='/funnels' component={FunnelsPage} />
                <PrivateRoute exact path='/funnel/:id?' component={FunnelPage}/>
                <PrivateRoute exact path='/tasks-manager/:group' component={TasksManagerPage} />
                <PrivateRoute exact path='/notifications' component={NotificationsPage} />

                <AdminRoute exact path='/admin' component={AdminHomePage} />
                <AdminRoute exact path='/admin/users' component={AdminUsersPage} />
                <AdminRoute exact path='/admin/stocks' component={AdminStocksPage} />
                <AdminRoute exact path='/admin/suppliers' component={AdminSuppliersPage} />
                <AdminRoute exact path='/admin/items' component={AdminItemsPage} />
                <AdminRoute exact path='/admin/parser' component={AdminParserPage} />
                <AdminRoute exact path='/admin/calc' component={AdminCalc} />
                <AdminRoute exact path='/admin/options' component={AdminOptionPage} />
                <AdminRoute exact path='/admin/dataProps' component={AdminDataPropsPage} />
                <AdminRoute exact path='/admin/contacts' component={AdminContactTypePage} />
                <AdminRoute exact path='/admin/fileGroup' component={AdminFileGroup} />
                <AdminRoute exact path='/admin/funnel/:id?' component={AdminFunnelPage} />
                <AdminRoute exact path='/admin/funnels' component={AdminFunnelsPage} />

                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/logout' component={LogoutPage} />
                <Route exact path='/accessDenied' component={AccessDenied} />
                <Route path='*' component={NotFound}/>
            </Switch>
        </Suspense>
    )
}