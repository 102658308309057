import React from 'react';
import {Spinner} from "react-bootstrap";

export default function FetchState({loading = false, label = 'Загрузка', size = 'sm', variant = ''}) {
    return (
        loading ?
            <div className="d-flex align-items-center">
                <Spinner
                    animation="border"
                    size={size}
                    variant={variant} />
                {
                    label &&
                    <span className="ms-2">{label}</span>
                }
            </div>
            :
            <React.Fragment/>
    )
}