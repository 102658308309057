export const orderStates = [
    {
        key: 0,
        label: 'Выставление счета'
    },
    {
        key: 1,
        label: 'На утверждении'
    },
    {
        key: 2,
        label: 'Утверждено'
    },
    {
        key: 3,
        label: 'На оплате'
    },
    {
        key: 4,
        label: 'Оплачено'
    },
    {
        key: 7,
        label: 'Ожидается поставка'
    },
    {
        key: 8,
        label: 'Оприходовано'
    },
    {
        key: 9,
        label: 'Оприходовано частично'
    },
    {
        key: 6,
        label: 'Отменено'
    },
    {
        key: 5,
        label: 'В архиве'
    }
]

export default function getStatusOrder (code) {
    switch (code) {
        case 0:
            return 'Выставление счета';
        case 1:
            return 'На утверждении';
        case 2:
            return 'Утверждено';
        case 3:
            return 'На оплате';
        case 4:
            return 'Оплачено';
        case 5:
            return 'В архиве';
        case 6:
            return 'Отменено';
        case 7:
            return 'Ожидается поставка';
        case 8:
            return 'Оприходовано';
        case 9:
            return 'Оприходовано частично';
        default:
            return `Неизвестный статус: ${code}`;

    }
}

export function getStatusOrderStyle (code) {
    switch (code) {
        case 0:
            return 'orderStateLight-red';
        case 7:
            return 'orderStateLight-yellow';
        case 8:
            return 'orderStateLight-green';
        case 9:
            return 'orderStateLight-yellow';
        default:
            return '';

    }
}