import React, {useEffect, useState} from 'react'
import {Spinner, Alert} from 'react-bootstrap'
import Api from '../../helpers/axios'
import formatCurrency from "../../helpers/formatCurrency"
import DebetOrganizations from "../buttons/debetOrganizations";

export default function BankAccountsState() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [stat, setStat] = useState({
        debet: 0
    })

    useEffect(() => {
        if (!loading) {
            setLoading(true)
            setError(null)

            Api.post('/api/flow/GetCommonDebet', {})
                .then(res => {
                    setStat(prevState => ({...prevState, debet: res.data.body}))
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    return <>
        {
            error && <Alert variant="danger">{error}</Alert>
        }

        Общая дебиторская задолжность:&nbsp;
        {
            loading ?
                <Spinner animation="border" size="sm"/> :
                <>
                    {
                        formatCurrency(stat.debet)
                    }
                </>
        }
        <br/>
        <DebetOrganizations bankAccountId={''}/>
    </>
}