import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Modal, Spinner, Alert} from 'react-bootstrap';
import Api from '../../helpers/axios';
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {requestOrderStatus} from "../../helpers/getStatusRequest";
import {getShortNameAmountType} from "../../helpers/amountTypes";
import formatCurrency from "../../helpers/formatCurrency";
import roles from "../../helpers/roles";

function ReportProjectItems({
                                projectId,
                                classNameBtn,
                                btnLabel
                            }) {

    const isAdmin = roles.is(roles.administrator);
    const [show, setShow] = useState(false);
    const onShow = () => {
        setShow(true);
    };
    
    const onHide = () => {
        setShow(false);
    };
    
    const [fetch, setFetch] = useState(false);
    const [error, setError] = useState(null);
    const [orders, setOrders] = useState([]);

    const getOrder = () => {
        if (!fetch) {
            setFetch(true)
            setError(null)

            Api.post('/api/projects/ReportItems', {id: projectId})
                .then(res => {
                    setOrders(res.data.body)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => setFetch(false))
        }
    };

    useEffect(() => {
        if (show) {
            getOrder();
        }
    }, [show]);
    
    const [totalPrice, setTotalPrice] = useState(0);
    const calculateTotalPrice = () => {
        let sum = 0;
        
        if (orders.length) {
            orders.map(order => {
                if (order.positions.length) {
                    order.positions.map(position => {
                        sum += (position.amount * position.item.price);
                    });
                }
            });
        }

        setTotalPrice(sum);
    }

    useMemo(() => {
        calculateTotalPrice();
    }, [orders]);
    
    const getPositionsPrice = (items) => {
        const price = items.reduce((a, b) => a + (b.amount * b.item.price), 0);
        return formatCurrency(price);
    };


    return (
        <>
            <a href="#"
               onClick={onShow}
               className={classNameBtn}>
                {btnLabel}
            </a>
            <Modal show={show} onHide={onHide} size={"xl"}>
                <Modal.Body>
                    <h4>
                        {btnLabel}
                    </h4>
                    {
                        error && <Alert variant={'danger'}>{error}</Alert>
                    }

                    {
                        fetch ?
                            <div className={'d-flex align-items-center pt-3 pb-3 justify-content-center'}>
                                <Spinner animation={'border'} size={'sm'}/>
                                <span className="ml-2">Получение заявок</span>
                            </div>
                            :
                            <div className="mt-3">
                                {
                                    isAdmin &&
                                    <div className={'mb-3'}>
                                        Общая стоимость: <span className="display-18">{ formatCurrency(totalPrice) }</span>
                                    </div>
                                }
                                {
                                    orders.length > 0 ?
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Заявка</th>
                                                <th>Позиции</th>
                                                {
                                                    isAdmin && 
                                                    <th>
                                                        Стоимость
                                                    </th>
                                                }
                                                <th>Статус</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                orders.map(order =>
                                                    <tr key={order.id}>
                                                        <td>
                                                            <div>№ {order.orderNumber}</div>
                                                            <div className="small">
                                                                от {format(new Date(order.dateCreate), 'd MMMM yyyy', {locale: ru})}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <table className="table table-sm">
                                                                <tbody>
                                                                {
                                                                    order.positions.map(position =>
                                                                        <tr key={position.id}>
                                                                            <td style={{width: "70%"}}>
                                                                                {position.item.name}
                                                                            </td>
                                                                            <td style={{width: "15%"}}>
                                                                                {position.amount} {getShortNameAmountType(position.item.amountType)}
                                                                            </td>
                                                                            {
                                                                                isAdmin &&
                                                                                <td style={{width: "15%"}}>
                                                                                    {formatCurrency(position.item.price)}
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        {
                                                            isAdmin &&
                                                            <td>
                                                                {getPositionsPrice(order.positions)}
                                                            </td>
                                                        }
                                                        <td>
                                                            {requestOrderStatus.getDisplayName(order.state)}
                                                        </td>
                                                    </tr>)
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="text-center text-muted pb-4">
                                            По данному проекту ничего не заказывалось
                                        </div>
                                }
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

ReportProjectItems.defaultProps = {
    classNameBtn: 'btn btn-dark',
    btnLabel: 'Заказанные позиции'
};

ReportProjectItems.propTypes = {
    classNameBtn: PropTypes.string,
    btnLabel: PropTypes.string,
    projectId: PropTypes.string.isRequired
};

export default ReportProjectItems;