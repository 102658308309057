import React, {useState} from "react";
import getStatusOrder from "../../helpers/getStatusOrder";
import UserDetails from "../userDetails";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import './style.scss';

export default function OrderHistory({items = [], showHistory = false}) {
    const [show, setShow] = useState(showHistory);
    const toggleShow = (e) => {
        e.preventDefault();
        setShow(!show);
    }

    return <>
        {
            items.length &&
            <div className="pb-5 pt-4">
                <h5>
                    История заявки
                </h5>

                {
                    show &&
                    items.map(item =>
                        <div className="card mb-2" key={item.id}>
                            <div className="card-body">
                                <div>
                                    Статус заявки: <b title={`Номер статуса: ${item.state}`}>{getStatusOrder(item.state)}</b>
                                </div>
                                <div className="small text-muted mb-1">
                                    Дата: {format(new Date(item.dateCreate), 'd MMMM yyyy в H:mm', {locale: ru})}
                                </div>
                                <div>
                                    <UserDetails user={item.user} showAvatar={false} />
                                </div>
                                {
                                    item.note &&
                                    <div className="history-comment">
                                        {item.note}
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
                {
                    !showHistory &&
                    <div className="text-center text-md-left mt-3">
                        <a href="#"
                           className="btn btn-sm btn-outline-primary"
                           onClick={toggleShow}>{show ? 'Скрыть ' : 'Показать'} историю заявки</a>
                    </div>
                }
            </div>
        }
    </>
}