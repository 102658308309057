import React, {useState} from "react";
import Api from "../../helpers/axios";
import {Modal, Alert} from "react-bootstrap";
import FetchState from "../ui/fetchState";
import GetResponseError from "../../helpers/getResponseError";

export const ExportStockData = ({stockId}) => {
    const [fetch, setFetch] = useState(false);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const onHide = () => {
        setShow(false);
    };
    const onShow = () => {
        setShow(true);
    };
    
    const [filename, setFilename] = useState('');
    const initialData = {
        stockId: stockId,
        periodStart: "",
        periodEnd: ""
    };
    const [data, setData] = useState(initialData);

    const onChangeHandle = e => {
        setData({...data, [e.target.name] : e.target.value});
    };
    
    const onStartExport = () => {
        if (!fetch) {
            setFetch(true);
            setError("");
            setFilename("");
            
            Api.post("/api/stocks/ExportData", data)
                .then(res => {
                    setFilename(res.data.body);
                })
                .catch(err => setError(GetResponseError(err)))
                .finally(() => {
                   setFetch(false); 
                });
        }
    }
    
    return (
        <>
            <button
                onClick={onShow}
                className="btn btn-secondary">
                Экспорт в Excel
            </button>
            
            <Modal show={show} onHide={onHide} size={"lg"}>
                <Modal.Header closeButton>
                    <h5>Экспорт данных склада</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center pt-2 pb-3">
                        <div>
                            Запустите экспорт, чтобы получить ссылку на excel-файл с актуальными данными по складу.
                        </div>
                        <div className="mt-3">
                            <div className="text-center mb-2">
                                <h5>
                                    Выберите период:
                                </h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="form-group">
                                    <label htmlFor="periodStart" className="form-label">
                                        Начало периода:
                                    </label>
                                    <input type="date"
                                           className="form-control"
                                           onChange={onChangeHandle}
                                           name="periodStart"
                                           id="periodStart"
                                           value={data.periodStart} />
                                </div>

                                <span className="px-2">
                                    &mdash;
                                </span>

                                <div className="form-group">
                                    <label htmlFor="periodStart" className="form-label">
                                        Конец периода:
                                    </label>
                                    <input type="date"
                                           className="form-control"
                                           onChange={onChangeHandle}
                                           name="periodEnd"
                                           id="periodEnd"
                                           value={data.periodEnd} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <button
                                disabled={fetch}
                                onClick={onStartExport}
                                className="btn btn-primary">
                                {
                                    fetch
                                        ? <FetchState loading={true} size={"sm"} label={"Обработка данных"} variant={"light"} />
                                        : 'Запустить'
                                }
                            </button>
                            {
                                error &&
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                            }
                        </div>
                    </div>
                    {
                        filename &&
                        <div className="mt-3 text-center">
                            <hr/>
                            <a href={`/exports/${filename}`} className="btn btn-link" target="_blank">
                                Скачать Excel-файл
                            </a>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ExportStockData;