import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import './style.scss';
import roles from "../../helpers/roles";
import { Menu } from '@headlessui/react';

export default () => {
    // Администратор
    const isAdmin = roles.is(roles.administrator);
    // Производство
    const isProduction = roles.is(roles.production);
    // Кладовщик
    const isStorekeeper = roles.is(roles.storekeeper);
    // Снабжение
    const isPurchaseDepartment = roles.is(roles.purchaseDepartment);
    // Финансовый отдел
    const isFinancialDepartment = roles.is(roles.financialDepartment);
    // Отдел продаж
    const isSales = roles.is(roles.sales);
    // Руководитель отдела продаж
    const isChiefSales = roles.is(roles.chiefSales);
    // Отдел проектирования
    const isProject = roles.is(roles.project);
    // Инженеры
    const isEngineer = roles.is(roles.engineer);
    // Логисты
    const isLogisticDepartment = roles.is(roles.logisticDepartment);
    // Административная служба
    const isAs = roles.is(roles.as);
    // Менеджер проектов
    const isProjectManager = roles.is(roles.projectManager);
    
    // Просмотр заявок
    const isViewOrder = roles.is(roles.viewOrders);

    const { pathname } = useLocation();

    return (
        <div className='userMenu'>

            <div className="mb-1">
                <div>
                    <Menu.Item>
                        <NavLink exact
                                 to={'/tasks'}
                                 isActive={() => ['/', '/tasks'].includes(pathname)}
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Мои задачи
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                        <NavLink exact
                                 to={'/funnels'}
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Доски
                        </NavLink>
                    </Menu.Item>
                    {
                        // <NavLink exact
                        //          to={'/my-docs'}
                        //          activeClassName='userMenu-item--active'
                        //          className='userMenu-item'>
                        //     Мои документы
                        // </NavLink>
                    }
                </div>
            </div>

            {
                (isAdmin
                    || isSales
                    || isChiefSales
                    || isProject
                    || isEngineer
                    || isProduction
                    || isLogisticDepartment
                    || isFinancialDepartment
                    || isProjectManager
                ) &&
                <div className="mb-1">
                    <div className="userMenu-block-title">
                        CRM:
                    </div>    
                    <div>
                        {
                            (isAdmin
                                || isSales
                                || isChiefSales
                                || isFinancialDepartment
                            ) &&
                            <Menu.Item>
                                <NavLink exact
                                         to='/deals'
                                         activeClassName='userMenu-item--active'
                                         className='userMenu-item'>
                                    Клиенты
                                </NavLink>
                            </Menu.Item>
                        }

                        {
                            (isAdmin
                                || isSales
                                || isChiefSales
                                || isProject
                                || isEngineer
                                || isProduction
                                || isLogisticDepartment
                                || isFinancialDepartment
                                || isProjectManager
                            ) &&
                            <Menu.Item>
                                <NavLink exact
                                         to='/clients-projects'
                                         activeClassName='userMenu-item--active'
                                         className='userMenu-item'>
                                    Реестр проектов
                                </NavLink>
                            </Menu.Item>
                        }

                        {
                            (isAdmin
                                || isProject
                                || isEngineer
                                || isSales) &&
                            <Menu.Item>
                            <NavLink exact
                                     to='/calc'
                                     activeClassName='userMenu-item--active'
                                     className='userMenu-item'>
                                Калькулятор ангара
                            </NavLink>
                            </Menu.Item>
                        }
                    </div>
                </div>                
            }            

            <div className="mb-1">
                <div className="userMenu-block-title">
                    Склад:
                </div>
                <div className="">
                    {
                        (isAdmin
                            || isStorekeeper
                            || isPurchaseDepartment
                            || isProduction
                            || isProject
                            || isAs
                        ) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/stocks'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Склады
                        </NavLink>
                        </Menu.Item>
                    }

                    {
                        (isAdmin
                            || isStorekeeper
                            || isPurchaseDepartment
                            || isFinancialDepartment
                            || isViewOrder
                        ) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/orders'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Заказы позиций
                        </NavLink>
                        </Menu.Item>
                    }

                    {
                        (isAdmin
                            || isStorekeeper
                            || isProduction) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/requests'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Выдача позиций
                        </NavLink>
                        </Menu.Item>
                    }

                    {
                        (isAdmin
                            || isSales
                            || isProduction
                            || isStorekeeper
                            || isProject) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/projects'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Проекты
                        </NavLink>
                        </Menu.Item>
                    }

                    {
                        (isAdmin || isFinancialDepartment) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/finances/accounts'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Учет финансов
                        </NavLink>
                        </Menu.Item>
                    }

                    {
                        (isAdmin || isStorekeeper || isPurchaseDepartment || isProduction) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/items'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Поиск позиций
                        </NavLink>
                        </Menu.Item>
                    }

                    {
                        (isAdmin || isPurchaseDepartment || isStorekeeper) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/suppliers'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Поставщики
                        </NavLink>
                        </Menu.Item>
                    }

                    {
                        (isAdmin || isSales || isPurchaseDepartment) &&
                        <Menu.Item>
                        <NavLink exact
                                 to='/oe'
                                 activeClassName='userMenu-item--active'
                                 className='userMenu-item'>
                            Дополнительное оборудование
                        </NavLink>
                        </Menu.Item>
                    }
                </div>
            </div>
            
            <div className="mb-1">
                <div className="userMenu-block-title">
                    Прочее:
                </div>
                <div className="">
                    <Menu.Item>
                    <NavLink exact
                             to='/docs'
                             activeClassName='userMenu-item--active'
                             className='userMenu-item'>
                        Документы
                    </NavLink>
                    </Menu.Item>
                </div>
            </div>
            
            <div className="mt-auto py-3 px-2 text-muted text-center">
                <div className="small">
                    &copy; ИС REZERV
                </div>
                <div className="small">
                    Версия {process.env.REACT_APP_VERSION}
                </div>
            </div>
        </div>
    );
}