import React, {useState, useEffect, useMemo} from "react"
import userHaveRole from "../../../helpers/userHaveRole"
import {Alert, Spinner} from "react-bootstrap"
import CreateBankAccount from "../../../components/buttons/createBankAccount"
import Api from '../../../helpers/axios'
import Pagination from '../../../components/pagination'
import {Link} from 'react-router-dom'
import './style.scss'
import {getBankAccountType} from '../../../helpers/bankAccountType'
import EditBtn from '../../../components/buttons/editBankAccount'
import BankAccountsState from "../../../components/bankAccountsState";
import formatCurrency from "../../../helpers/formatCurrency";

export default () => {
    const isAdmin = userHaveRole("Administrator")
    const isHasAccess = isAdmin || userHaveRole("Financial Department")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [items, setItems] = useState([])
    const [count, setCount] = useState(0)
    const [option, setOption] = useState({
        currentPage: 1,
        onPage: 30,
        desc: false
    })
    
    const getItems = () => {
        if(!loading) {
            setLoading(true)
            Api.post('/api/bankAccount/perPage', option)
                .then(res => {
                    setItems(res.data.accounts)
                    setCount(res.data.count)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    const updateCurrentPage = (page) => {
        setOption({...option, currentPage: page});
    };
    
    useEffect(() => {
        getItems();
    }, []);
    
    useMemo(() => {
        getItems()
    }, [option])
    
    return (
        <div className="container">
            {
                isHasAccess ? 
                    <>
                        
                        <div className="card mb-4">
                            <div className="card-body">
                                <BankAccountsState/>
                            </div>
                        </div>
                        
                        <div className="d-md-flex w-100 text-center text-md-left mb-3">
                            <div>
                                <h2>
                                    Выберите счет
                                </h2>
                            </div>
                            
                            {
                                isAdmin && <div className="ms-md-auto mt-3 mt-md-0">
                                    <CreateBankAccount callback={getItems}/>
                                </div>
                            }
                        </div>
                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }
                        {
                            loading ?
                                <div className="d-flex align-items-center">
                                    <Spinner animation="border" size="sm" variant="warning"/>
                                    <span className="ms-2">Загрузка данных</span>
                                </div>
                                :
                                <div>
                                    {
                                        items.length>0 ?
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                <tr>
                                                    <th>
                                                        #
                                                    </th>
                                                    <th>
                                                        Название счета:
                                                    </th>
                                                    <th>
                                                        Начальный баланс:
                                                    </th>
                                                    <th>
                                                        Текущий баланс:
                                                    </th>
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    items.map(item =>
                                                        <tr key={item.id}>
                                                            <td>
                                                                {item.orderNumber}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex w-100">
                                                                    <div>
                                                                        {
                                                                            item.type === 1 &&
                                                                            <div className="text-muted mb-1">
                                                                                Личный счет
                                                                            </div>
                                                                        }
                                                                        <Link
                                                                            className="display-18"
                                                                            to={`/finances/flow/${item.id}`}>
                                                                            {item.name}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    { formatCurrency(item.startBalance) }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={item.balance < 0 ? 'text-danger text-strong': '' }>
                                                                    { formatCurrency(item.balance) }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="text-center">
                                                                    <EditBtn
                                                                        account={item}
                                                                        callback={getItems}
                                                                        classNames="btn btn-icon"/>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <div className="text-muted">
                                                Счета не найдены
                                            </div>
                                    }
                                
                                    <Pagination
                                        callback={updateCurrentPage}
                                        count={count}
                                        onPage={option.onPage}
                                        currentPage={option.currentPage}
                                    />
                                </div>
                        }
                    </>
                    :
                    <div>
                        <Alert variant="danger">Нет прав на просмотр данного раздела</Alert>
                    </div>
            }
        </div>
    )
}