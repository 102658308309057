import React, {useState, useEffect} from "react";
import Api from '../../../../../helpers/axios';
import FetchState from "../../../../../components/ui/fetchState";
import './style.scss';
import {Alert} from "react-bootstrap";
import GetResponseError from "../../../../../helpers/getResponseError";
import CreateComment from "../create";
import CommentItem from "../item";

export const ProjectComments = ({clientProjectId}) => {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const [comments, setComments] = useState([]);

    const getComments = () => {
        if (!fetching) {
            setFetching(true);
            setError('');

            Api.post('/api/projectComments/GetComments', {id: clientProjectId})
                .then(res => {
                    setComments(res.data.body)
                })
                .catch(err => setError(GetResponseError(err)))
                .finally(() => {
                   setFetching(false); 
                });
        }        
    };
    
    const onNewComment = (comment) => {
        setComments([...comments, comment]);
    }

    useEffect(() => {
        getComments();
    }, []);
    
    return (
        <div className="comments">
            <div className="comments-header">
                <div className="display-20">Комментарии</div>
                <div className="comments-header-summary d-flex align-items-center">
                    {
                        fetching ?
                            <div className="ms-2">
                                <FetchState loading={fetching} label="" variant="warning"/>
                            </div>
                            :
                            <span>Всего {comments.length}</span>
                    }
                </div>
            </div>

            {
                error && <Alert variant="danger">{error}</Alert>
            }

            {
                comments.length > 0 &&
                <div className="comments-list">
                    {
                        comments.map(comment => <CommentItem key={comment.id} comment={comment} />)
                    }
                </div>
            }

            <CreateComment
                clientProjectId={clientProjectId}
                onCreated={onNewComment}
            />
            
        </div>
    )
}

export default ProjectComments;