import React from "react";
import {useSelector} from 'react-redux';
import {Link} from "react-router-dom";
import userHaveRole from "../../helpers/userHaveRole";

export default () => {
    const order = useSelector(state => state.order);

    const canCreateOrder = userHaveRole("Storekeeper")
        || userHaveRole("Administrator");

    return (
        (canCreateOrder && order.products.length > 0) &&
        <Link to='/order-cart' className="cart btn btn-sm btn-primary">
            <span className="cart-label">Закупить</span>
            {
                order.products.length > 0 &&
                <span className="cart-amount">{order.products.length}</span>
            }
        </Link>
    )
}