import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {Link, NavLink} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import OrderCard from '../orderCart';
import RequestProdCart from '../requestProductionCart';
import {useSelector} from "react-redux";
import Avatar from "../ui/avatar";
import AlarmBell from '../notice_bell';
import ThemeSwitcher from "../themeSwitcher";
import { Menu } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import roles from "../../helpers/roles";
import Sidebar from "../sidebar";

export default () => {
    const location = useLocation();
    const [useHeader, setUseHeader] = useState(true);
    const isAdmin = roles.is(roles.administrator);

    // Changes location
    useEffect(() => {
            setUseHeader(location.pathname !== '/login');
    }, [location]);

    const user = useSelector(state => state.user);

    return (
        useHeader &&
        <div className={styles.header}>
            <div className={styles.headerWrapper}>
                <Sidebar />
                <div className="ms-auto d-flex align-items-center">
                    <div className="me-2">
                        <OrderCard/>
                        <RequestProdCart/>
                    </div>
                    
                    <AlarmBell/>
                    
                    <div className={styles.userMenu}>
                        <Menu as={"div"}>
                            {({ open }) => (
                                <>
                                    <Menu.Button className={styles.userMenuButton}>
                                        <Avatar path={user.avatar} size={36} />
                                        {
                                            !open ?
                                                <ChevronDownIcon className="icon-20-20 ms-1" aria-hidden="true" />
                                                :
                                                <ChevronUpIcon className="icon-20-20 ms-1" aria-hidden="true" />
                                        }
                                    </Menu.Button>
                                    <Menu.Items className={styles.userMenuItems}>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    to={'/profile'}
                                                    className={`${styles.userMenuItem} ${active ? styles.userMenuItemActive : ''}`}>
                                                    Мой профиль
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        {
                                            isAdmin &&
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to={'/admin'}
                                                        className={`${styles.userMenuItem} ${active ? styles.userMenuItemActive : ''}`}>
                                                        Администрирование
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        }
                                        <div className={styles.userMenuItemSeparator}/>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    to={'/logout'}
                                                    className={`${styles.userMenuItem} ${styles.userMenuItemDanger} ${active ? styles.userMenuItemActive : ''}`}>
                                                    Выйти
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </>
                            )}
                        </Menu>
                    </div>
                    
                    <ThemeSwitcher/>
                </div>
            </div>
        </div>
    )
}