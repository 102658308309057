import React from "react";
import {Link} from "react-router-dom";

export default () => {
    return (
        <div className="container text-center pt-5 pb-2">
            <h2>
                Error 404
            </h2>
            <p>
                Запрашиваемая страница не найдена.<br/>
                Возможно вы перешли по неправильной ссылке или она устарела.
            </p>
            <p>
                <Link to="/">Перейти на главную</Link>
            </p>
        </div>
    )
}