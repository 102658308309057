export const ADD_TO_PROD_REQUEST = 'ADD_TO_PROD_REQUEST';
export const REMOVE_FROM_PROD_REQUEST = 'REMOVE_FROM_PROD_REQUEST';
export const CHANGE_AMOUNT_ITEM_PROD_REQUEST = 'CHANGE_AMOUNT_ITEM_PROD_REQUEST';
export const REMOVE_PROD_REQUEST = 'REMOVE_PROD_REQUEST';
export const CHANGE_PROJECT_REQUEST = 'CHANGE_ABOUT_REQUEST';

export const addToProdRequestAction = (payload) => ({ type: ADD_TO_PROD_REQUEST, payload });
export const removeFromProdRequestAction = (payload) => ({type: REMOVE_FROM_PROD_REQUEST, payload});
export const changeAmountItemProdRequestAction = (payload) => ({type: CHANGE_AMOUNT_ITEM_PROD_REQUEST, payload});
export const removeProdRequest = () => ({type: REMOVE_PROD_REQUEST});
export const changeProjectRequestAction = (payload) => ({type:CHANGE_PROJECT_REQUEST, payload});