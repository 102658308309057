import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {rootReducer} from './reducers';
import {saveToStorage, loadFromStorage} from '../helpers/stateStorage';

const persistedState = loadFromStorage();
const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

store.subscribe(() => saveToStorage(store.getState()));

export default store;