export const roles = {
  administrator: 'Administrator',
  purchaseDepartment: 'Purchase Department',
  storekeeper: 'Storekeeper',
  production: 'Production',
  financialDepartment: 'Financial Department',
  sales: 'Sales',
  chiefSales: 'ChiefSales',
  worker: 'Worker',
  project: 'Project',
  engineer: 'Engineer',
  logisticDepartment: 'LogisticDepartment',
  programmer: 'Programmer',
  installer: 'Installer',
  as: 'AS',
  projectManager: 'ProjectManager',
  viewOrders: 'ViewOrders',
  
  is: (roleName) => {
    let result = false;

    if (localStorage.state) {
      const state = JSON.parse(localStorage.state);
      const roles = state.user.roles;
      if (roles.length) {
        result = roles.some((role) => {
          return role.name.toLowerCase() === roleName.toLowerCase();
        });
      }
    }
    return result;
  }
};

export default roles;