import React, {useState, useEffect, useRef} from 'react'
import './style.scss'
import Tag from '../flowTag'
import Api from '../../helpers/axios'
import {getRandomColor} from '../../helpers/colors'
import {Alert} from "react-bootstrap";
import EditFlowTag from "../buttons/editFlowTag";

export default ({tagsList, callback}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [tags, setTags] = useState(tagsList)
    const [suggestions, setSuggestions] = useState([])
    const [editable, setEditable] = useState(false)
    const [hasSuggestions, setHasSuggestion] = useState(true)
    const inputRef = useRef(null)
    const ref = useRef()
    
    const initialTag = {
        id: '',
        title: '',
        color: getRandomColor()
    }
    const [inputTag, setInputTag] = useState(initialTag)
    
    const getAllTags = () => {
        Api.post('/api/flowTag/getAll')
            .then(res => {
                setSuggestions(res.data)
            })
            .catch(err => setError(err.status))
    }
    
    const setFocus = () => {
        inputRef.current && inputRef.current.focus()
    }
    
    const findTag = (query) => {
        if(!loading) {
            setLoading(true)
            Api.post('/api/flowTag/findFlowTags', {query: query})
                .then(res => {
                    if (res.data.length > 0) {
                        setHasSuggestion(true)
                        setSuggestions(res.data)
                    } else {
                        setHasSuggestion(false)
                    }
                })
                .catch(err => setError(err.status))
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    const onChangeInputTag = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        
        if (value === '') {
            setHasSuggestion(true)
            getAllTags()
        }
        else {
            if (!hasSuggestions) {
                setSuggestions([])
            }
            else {
                findTag(value)    
            }
        }
        
        setInputTag(prevState => ({...prevState, title: value}));
    }
    
    const onAddOrCreateHandle = () => {
        if (!loading) {
            setLoading(true)
            
            Api.post('/api/flowTag/create', inputTag)
                .then(res => {
                    onAddTagHandle(res.data.body)
                    getAllTags()
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    const onAddTagHandle = (tag) => {
        const newTags = tags.filter(x => x.id !== tag.id)
        
        setTags(newTags)
        
        setTags(prevState => ([...prevState, tag]))
        
        setInputTag(initialTag)
        
        setHasSuggestion(true)
        
        getAllTags()
        
        setFocus()
    }
    
    const onEditableHandle = () => {
        setEditable(true)
    }
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputTag.title !== '') {
            e.preventDefault()
            onAddOrCreateHandle()
        }
    }
    
    const onRemoveTagHandle = (tag) => {
        const newTags = tags.filter(x => x.id !== tag.id)
        setInputTag(initialTag)
        setTags(newTags)
        setFocus()
    }
    
    const onUpdateTag = (tag, isDelete = false) => {
        if (!isDelete) {
            const newTags = tags.map(x => {
                if (x.id === tag.id) {
                    x = tag
                }
                return x
            })
            setTags(newTags)

            const suggestionsTags = suggestions.map(x => {
                if (x.id === tag.id) {
                    x = tag
                }
                return x
            })
            setSuggestions(suggestionsTags)
        }
        else {
            const newTags = tags.filter(x => x.id !== tag.id)
            setTags(newTags)

            const suggestionsTags = suggestions.filter(x => x.id !== tag.id)
            setSuggestions(suggestionsTags)
        }
    }

    useEffect(() => {
        if(editable) {
            setFocus()    
        }

        const checkIfClickedOutside = e => {
            if (editable && ref.current && !ref.current.contains(e.target)) {
                setEditable(false)
                e.stopPropagation()
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
        
    }, [editable])
    
    useEffect(() => {
        getAllTags()
    }, [])
    
    useEffect(() => {
        callback(tags)
    }, [tags])
   
    return (
        <div className="tags-control">
            <div className="tags-control-list" onClick={onEditableHandle}>
                {
                    tags.length > 0 &&
                    tags.map(tag => <React.Fragment key={tag.id}>
                        <Tag tag={tag}/>
                    </React.Fragment>)
                }
            </div>
            {
                editable &&
                <div className="tags-control-wrapper" ref={ref}>
                    <div className="tags">
                        {
                            tags.length > 0 &&
                            tags.map(tag => <React.Fragment key={tag.id}>
                                <Tag
                                    tag={tag}
                                    canRemove={true}
                                    removeCallback={onRemoveTagHandle} />
                            </React.Fragment>)
                        }
                        <div className="tags-input-wrapper">
                            <input
                                size="1"
                                ref={inputRef}
                                onChange={onChangeInputTag}
                                value={inputTag.title}
                                onKeyDown={handleKeyDown}
                                type="text" />
                        </div>
                    </div>
                    <div className="tags-all-list">
                        
                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }

                        <div className="tags-all-list-header">
                            Выберите тег или создайте новый
                        </div>
                        
                        {
                            (!hasSuggestions && inputTag.title) &&
                            <div
                                onClick={onAddOrCreateHandle}
                                className="create-tag-btn">
                                <span className="mr-2">Создать тег:</span>
                                <Tag tag={inputTag} canRemove={false} removeCallback={()=>{}}/>
                            </div>                                
                         
                        }
                        
                        <div className="tags-suggestions">
                            {
                                suggestions.length > 0 &&
                                suggestions.map(t =>
                                    <div
                                        onClick={() => onAddTagHandle(t)}
                                        key={t.id}
                                        className="tags-suggestions-item d-flex w-100">
                                        <Tag
                                            tag={t}
                                            canRemove={false}
                                            removeCallback={()=>{}}
                                        />
                                        
                                        <div className="ms-auto">
                                            <EditFlowTag
                                                tag={t}
                                                callback={onUpdateTag}/>
                                        </div>
                                    </div>
                                )
                            }
                        </div>                        
                    </div>
                </div>
            }
        </div>
    )
}