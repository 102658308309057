import React, {useMemo, useState} from "react";
import './style.scss';

export default function Pagination ({
                                        currentPage = 1,
                                        onPage = 50,
                                        count = 0,
                                        offset = 3,
                                        callback = null }) {
    
    const totalPages = Math.ceil(count/onPage);
    const [startRange, setStartRange] = useState(1);
    const [endRange, setEndRange] = useState(totalPages);
    
    const updateRange = (page) => {
        const start = page - offset <= 1 ? 1 : page - offset;
        const end = page + offset >= totalPages ? totalPages : page + offset;
        setStartRange(start);
        setEndRange(end);
    };

    useMemo(() => {
        updateRange(currentPage);
    }, [currentPage, count]);
    
    const onChangeCurrentPage = (e, page) => {
        e.preventDefault();
        
        if (callback) {
            callback(page);
        }
        
        window.scrollTo(0, 0);
    };

    const renderPages = () => {
        const pages = [];
        let key = 1;
        
        if (startRange > 1) {
            pages.push(
                <li className="page-item" key={key}>
                    <a className={`page-link`} onClick={(e) => onChangeCurrentPage(e, 1)}>1</a>
                </li>);
            
            if (startRange > 2) {
                key = key + 1;
                pages.push(
                    <li className="page-item" key={key}>
                        <a className={`page-link`} onClick={(e) => onChangeCurrentPage(e, startRange - 1)}>...</a>
                    </li>);
            }
        }

        for (let i = startRange; i <= endRange; i++) {
            key = key + 1;
            pages.push(
                <li className={`page-item ${i === currentPage ? 'active' : ''}`}  key={key}>
                    <a className={`page-link`} onClick={(e) => onChangeCurrentPage(e, i)}>{i}</a>
                </li>);
        }
        
        if (endRange < totalPages - 1) {
            key = key + 1;
            pages.push(
                <li className="page-item" key={key}>
                    <a className={`page-link`} onClick={(e) => onChangeCurrentPage(e, endRange + 1)}>...</a>
                </li>)

            key = key + 1;
            pages.push(
                <li className="page-item" key={key}>
                    <a className={`page-link`} onClick={(e) => onChangeCurrentPage(e, totalPages)}>{totalPages}</a>
                </li>)
        } else if (endRange !== totalPages) {
            key = key + 1;
            pages.push(
                <li className="page-item" key={key}>
                    <a className={`page-link`} onClick={(e) => onChangeCurrentPage(e, totalPages)}>{totalPages}</a>
                </li>)
        }
       
        return(pages)
    }

    return (
        <ul className="pagination pagination-sm">
            {
                totalPages > 1 && renderPages()
            }
        </ul>
    )
}