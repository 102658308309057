import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Modal, Spinner, Alert} from 'react-bootstrap'
import Api from '../../../helpers/axios'
import {flowTypes} from '../../../helpers/flowType'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {registerLocale} from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import {ReactComponent as EditIcon} from "./edit.svg"
import CurrencyInput from "react-currency-input-field"
import FlowTags from '../../flowTags'
import Autocomplete from '../../autocomplete'

registerLocale('ru', ru)

export default function EditFlow({classNames, flow, managers, callback}) {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const initialData = {
        id: flow.id,
        bankAccountId: flow.bankAccountId,
        type: flow.type,
        value: flow.value,
        userId: flow.userId,
        counterparty: flow.organization ? flow.organization.fullName : '',
        contractPrice: flow.contract ? flow.contract.price : 0,
        contractName: flow.contract ? flow.contract.name : '',
        contractDebet: flow.contract ? flow.contract.debet : '',
        organizationId: flow.organization ? flow.organization.id : '',
        contractId: flow.contract ? flow.contract.id : '',
        description: flow.description,
        billDate: new Date(flow.billDate + 'Z'),
        tags: flow.flowTags.map(t => t.tags)
    }

    const [data, setData] = useState(initialData)

    const onShowHandle = () => {
        setShow(true)
    }
    const onHideHandle = () => {
        setData(initialData)
        setError(null)
        setShow(false)
    }

    const onChangeHandle = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' ? target.value - 0 : target.value;
        const {name} = target;
        setData(prevState => ({...prevState, [name]: value}));
    }

    const onChangeValueHandle = (val) => {
        if (!val) {
            val = 0
        }
        setData(prevState => ({...prevState, value: val}));
    }

    const onUpdateTags = (tags) => {
        setData(prevState => ({...prevState, tags: tags}))
    }

    const validate = () => {
        if (data.value === 0) {
            setError('Сумма должна быть больше 0')
            return false
        }

        if (data.description === '') {
            setError('Укажите описание')
            return false
        }

        if (data.counterparty === '') {
            setError('Укажите контрагента')
            return false
        }

        return true
    }

    const [contractFiles, setContractFiles] = useState([])
    const [oldFiles, setOldFiles] = useState(flow.contract ? flow.contract.files : [])
    const suggestionContracts = (suggestion) => {
        setData(prevState => ({
            ...prevState,
            contractId: suggestion.id,
            contractName: suggestion.label,
            contractPrice: suggestion.body.price
        }))
        setContractFiles(suggestion.body.files)
    }

    const [upload, setUpload] = useState(false)
    const inputFile = useRef()
    const [formData, setFormData] = useState(null)
    const [uploadFiles, setUploadFiles] = useState([])

    const selectFiles = () => {
        inputFile.current.click()
    }

    const onSelectFilesHandler = (e) => {
        const files = e.target.files
        const filesData = new FormData()

        for (let i = 0; i < files.length; i++) {
            filesData.append('files', e.target.files[i])
        }

        inputFile.current.value = null
        setFormData(filesData)

        let object = {}

        filesData.forEach((value, key) => {
            // Reflect.has in favor of: object.hasOwnProperty(key)
            if (!Reflect.has(object, key)) {
                object[key] = value.name;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value.name);
        })

        setUploadFiles(object)
    }

    const onUpdateHandle = () => {
        if (!loading && validate()) {
            setLoading(true)
            setError(null)

            Api.post('/api/flow/update', data)
                .then((res) => {

                    if (formData) {
                        const contractId = res.data.body.contractId

                        let fd = formData;
                        fd.append('contractId', contractId)

                        if (!upload) {
                            setLoading(true)
                            setUpload(true)

                            Api.post('/api/flow/upload', fd)
                                .then(() => {
                                })
                                .catch(err => setError(err.response.data.message))
                                .finally(() => {
                                    setLoading(false)
                                    setUpload(false)
                                    setFormData(null)
                                    setUploadFiles([])
                                })
                        }
                    }
                    
                    onHideHandle()

                    if (typeof callback === "function") {
                        callback()
                    }

                })
                .catch(err => {
                    setError(err.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onUpdateBillDate = (date) => {
        setData(prevState => ({...prevState, billDate: date}))
    }

    const onChangeContractPriceHandle = (val) => {
        if (!val) {
            val = 0
        }
        setData(prevState => ({...prevState, contractPrice: val}));
    }

    const updateCounterparty = (suggection) => {
        setData(prevState => ({
            ...prevState,
            organizationId: suggection.id,
            counterparty: suggection.label
        }))
    }

    const [additionalProps, setAdditionalProps] = useState(false)

    const removeAttachedFiles = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setFormData(null)
        setUploadFiles([])
    }

    const [deleteFile, setDeleteFile] = useState(null)

    const onDeleteContractFile = (event, file) => {
        if (deleteFile === null) {
            if (confirm('Уверены, что хотите удалить файл? Файл будет удален сразу.')) {

                setDeleteFile(file.id)

                Api.post('/api/flow/deleteContractFile', {
                    contractId: file.contractId,
                    fileId: file.id
                })
                    .then(() => {
                        SetContractFiles(contractFiles.filter(x => x.id !== file.id))
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setDeleteFile(null)
                    })

            }
        }
    }

    useEffect(() => {
        setAdditionalProps(data.type === 0)
    }, [data.type])

    useMemo(() => {
        if(error) {
            setError(null)
        }
    }, [data])

    return (
        <>
            <button
                title="Изменить"
                disabled={show}
                onClick={onShowHandle}
                className={classNames}>
                <EditIcon/>
            </button>

            <Modal show={show} onHide={() => {
            }}>
                <Modal.Body>
                    <div className="card-body">
                        <h5 className="mb-3">
                            Редактирование
                        </h5>

                        <div className="mt-3">
                            <div className="mb-3">
                                <label>Тип:</label>
                                {
                                    flowTypes.map((type, i) =>
                                        <div key={i} className="form-check fake-checkbox">
                                            <input type="radio"
                                                   className="form-check-input"
                                                   id={`type_${type.value}_${flow.id}`}
                                                   name="type"
                                                   value={type.value}
                                                   checked={data.type === type.value}
                                                   onChange={onChangeHandle}
                                            />
                                            <label className="form-check-label"
                                                   htmlFor={`type_${type.value}_${flow.id}`}>
                                                {type.label}
                                            </label>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="mb-3">
                                <label>Дата:</label>
                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={data.billDate}
                                        onChange={onUpdateBillDate}
                                        locale="ru"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        timeCaption="время"
                                        dateFormat="d MMMM yyyy в HH:mm"
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label>Контрагент:</label>
                                <Autocomplete
                                    value={data.counterparty}
                                    defaultId={data.organizationId}
                                    defaultBody={flow.organization}
                                    callback={updateCounterparty}
                                    endpoint="/api/suggestions/organizations"
                                    inputClassName="form-control"
                                    placeholder="Например, ООО ХИМПРОЕКТ"
                                />
                            </div>

                            {
                                additionalProps &&
                                <div className="mb-3">
                                    <label htmlFor="manager">Менеджер:</label>
                                    <select
                                        onChange={onChangeHandle}
                                        value={data.userId}
                                        name="userId"
                                        id="manager"
                                        className="form-control">
                                        <option value={''}>Я</option>
                                        <option
                                            value={flow.user.id}>{`${flow.user.firstName} ${flow.user.lastName}`}</option>
                                        {
                                            managers.map((manager, i) => {
                                                    if (manager.id !== flow.userId) {
                                                        return (<option key={i} value={manager.id}>
                                                            {`${manager.firstName} ${manager.lastName}`}
                                                        </option>)
                                                    }
                                                }
                                            )
                                        }
                                    </select>
                                </div>
                            }

                            <div className="mb-3">
                                <label>Сумма {data.type === 0 ? 'прихода' : 'расхода'}:</label>
                                <CurrencyInput
                                    id="flow-value"
                                    className="form-control"
                                    name="value"
                                    suffix=" ₽"
                                    allowNegativeValue={false}
                                    decimalSeparator="."
                                    defaultValue={data.value}
                                    decimalsLimit={2}
                                    onValueChange={onChangeValueHandle}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="flow-description">Описание
                                    ({data.type === 0 ? 'за что' : 'на что'}):</label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    id="flow-description"
                                    value={data.description}
                                    onChange={onChangeHandle}
                                />
                            </div>

                            <div className="mb-3">
                                <label>Теги:</label>
                                <FlowTags
                                    tagsList={data.tags}
                                    callback={onUpdateTags}/>
                            </div>
                        </div>

                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }

                        <div>
                            <button
                                disabled={loading}
                                onClick={onUpdateHandle}
                                className="btn btn-primary">
                                {
                                    loading ?
                                        <div className="d-flex align-items-center">
                                            <Spinner animation="border" size="sm" variant="light"/>
                                            <span className="ms-2">Сохранение</span>
                                        </div>
                                        :
                                        'Сохранить'
                                }
                            </button>
                            {
                                !loading &&
                                <button
                                    onClick={onHideHandle}
                                    className="btn btn-dark ms-2">Отмена</button>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}