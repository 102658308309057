export const amountTypes = [
    {
        key: 0,
        label: 'Штуки',
        za: 'штуку',
        value: 'Things',
        short: 'шт.'
    },
    {
        key: 2,
        label: 'Килограммы',
        za: 'киллограмм',
        value: 'Kilograms',
        short: 'кг.'
    },
    {
        key: 3,
        label: 'Тонна',
        za: 'тонну',
        value: 'Tons',
        short: 'т.'
    },
    {
        key: 4,
        label: 'Литры',
        za: 'литр',
        value: 'Liters',
        short: 'л.'
    },
    {
        key: 5,
        label: 'Метры',
        za: 'метр',
        value: 'Meters',
        short: 'м.'
    },
    {
        key: 6,
        label: 'Квадратные метры',
        za: 'квадратный метр',
        value: 'SquareMeters',
        short: 'кв.м.'
    },
    {
        key: 1,
        label: 'Погонные метры',
        za: 'погонный метр',
        value: 'LinearMeters',
        short: 'пог. м.'
    },
    {
        key: 7,
        label: 'Кубические метры',
        za: 'кубический метр',
        value: 'CubicMeters',
        short: 'куб.м.'
    }
];

export function getLabelAmountType(key) {
    const type = amountTypes.find(x => x.key === key);

    if (type) {
        return type.label
    }

    return '';
}

export function getShortNameAmountType(key) {
    const type = amountTypes.find(x => x.key === key);

    if (type) {
        return type.short
    }

    return '';
}

export function getZaAmountType(key) {
    const type = amountTypes.find(x => x.key === key);

    if (type) {
        return type.za
    }

    return '';
}