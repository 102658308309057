import React, {useState} from "react"
import {Modal, Alert, Spinner} from 'react-bootstrap'
import InputNumber from "../../ui/inputNumber"
import {getShortNameAmountType} from "../../../helpers/amountTypes"
import Api from '../../../helpers/axios'

function ReturnButton (props) {
    const stockItem = props.stockItem || null
    const [open, setOpen] = useState(false)
    const callback = props.callback

    const openModalHandle = () => setOpen(true)
    const closeModalHandle = () => setOpen(false)

    const initialData = {
        itemId: stockItem.itemId,
        stockId: stockItem.stockId,
        returnAmount: 1,
        reason: ''
    }

    const [data, setData] = useState(initialData)

    const changeReturnAmount = (val) => {
        setData(prevState => ({...prevState, returnAmount: val}))
    }

    const changeReturnReason = (e) => {
        setData(prevState => ({...prevState, reason: e.target.value}))
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const sendReturnHandle = () => {
        if(!loading) {
            setLoading(true)
            setError(null)

            Api.post('/api/stocks/returnItems', data)
                .then(() => {
                    const resultAmount = stockItem.amount + data.returnAmount
                    callback(stockItem.id, resultAmount)
                    setData(initialData)
                    setOpen(false)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <>
            <button
                disabled={open}
                onClick={openModalHandle}
                className="btn btn-sm btn-dark">
                Возврат
            </button>

            <Modal show={open} onHide={closeModalHandle}>
                <Modal.Body>
                    <h4>
                        Возврат позиции на склад
                    </h4>

                    <div className="mt-3">
                        <div className="mb-3">
                            <div>
                                {stockItem.item.name}
                                {stockItem.item.brand &&
                                <div className="text-muted mt-2">
                                    Бренд: {stockItem.item.brand}
                                </div>
                                }
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">*Сколько возвращается ({getShortNameAmountType(stockItem.amountType)}):</label>
                            <div className="d-flex">
                                <InputNumber
                                    value={data.returnAmount}
                                    onChange={changeReturnAmount} />
                            </div>
                        </div>
                        {
                            data.returnAmount > 0 &&
                            <>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="return-reason">*Причина возврата:</label>
                                    <textarea
                                        id="return-reason"
                                        className="form-control"
                                        value={data.reason}
                                        onChange={changeReturnReason}
                                    />
                                </div>
                                <div className="pt-3">
                                    <button
                                        disabled={!data.reason}
                                        onClick={sendReturnHandle}
                                        className="btn btn-primary">
                                        {
                                            loading ?
                                                <div className="d-flex align-items-center">
                                                    <Spinner animation="border" size="sm" variant="light" />
                                                    <span className="ms-2">Обработка</span>
                                                </div>
                                                :
                                                'Отправить'
                                        }
                                    </button>

                                    {!loading &&
                                    <button
                                        onClick={closeModalHandle}
                                        className="btn btn-dark ms-2">Отмена</button>
                                    }
                                </div>
                            </>
                        }
                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default ReturnButton