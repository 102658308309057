import React from 'react';
import AdminMenu from '../../admin/components/adminMenu';

export default ({children}) => {
    return (
        <div className="admin-page">
            <AdminMenu/>
            <div className='container'>
                {children}
            </div>
        </div>
    )
};