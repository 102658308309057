import React, {useState, useRef, useEffect} from 'react'
import {ReactComponent as DotsIcon} from './vertical-dots.svg'
import './style.scss'

export default ({children}) => {
    const [show, setShow] = useState(false)
    const onOpen = () =>setShow(true)
    const onClose = () => setShow(false)
    const ref = useRef()

    useEffect(() => {

        const checkIfClickedOutside = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                onClose()
                e.stopPropagation()
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }

    }, [show])
    
    return <div className="dropdown-dots">
        <div
            onClick={onOpen}
            className="dropdown-dots-btn">
            <DotsIcon/>
        </div>
        <div
            ref={ref}
            className={`dropdown-dots-container dropdown-dots-container--${show ? 'open' : 'close'}`}>
            {children}
        </div>
    </div>
}