import React, {useState, useEffect, useMemo} from "react";
import Api from "../../helpers/axios";
import {Alert, Modal} from "react-bootstrap";
import FetchState from "../ui/fetchState";
import CurrencyInput from "react-currency-input-field";
import {amountTypes, getShortNameAmountType} from "../../helpers/amountTypes";
import useDebounce from '../../helpers/hooks/useDebounce';
import './style.scss';
import formatCurrency from "../../helpers/formatCurrency";
import Autocomplete from "../autocomplete";
import PhoneInput from "react-phone-number-input/input";

export default function CreateCraftItemComponent (
    {
        stockId = null,
        classname = 'btn btn-primary',
        label = 'Добавить позицию',
        callback = () => {}
    }) {
    const initialData = {
        stockId: stockId,
        item: {
            name: '',
            brand: '',
            isCrafted: true,
        },
        craftItems: [],
        amount: 0,
        amountType: "0",
        minAmount: 0,
        price: 0,
        supplier: {
            id: '',
            name: '',
            brand: '',
            address: '',
            url: '',
            note: '',
            manager: '',
            phone: ''
        }
    };
    const [error, setError] = useState('');
    const [data, setData] = useState(initialData);
    const [show, setShow] = useState(false);
    const onShow = () => {
        setShow(true);
    };
    const onHide = () => {
        setShow(false);
        setData(initialData);
    };
    
    const onChangeHandle = (e) => {
        const {target} = e;        
        setData({...data, [target.name]: target.value});
    };
    
    const onChangeNameHandle = e => {
        setData({...data, item: {...data.item, name: e.target.value}});
    };
    
    const [search, setSearch] = useState({
        fetch: false,
        query: ''
    });
    
    const [searchResults, setSearchResults] = useState({
        results: null,
        count: 0
    });
    
    const onSearchHandle = e => {
        setSearch({...search, query: e.target.value});
    };
    
    const convertToCraftItem = (item) => {
      return {
          child: item,
          childId: item.id,
          amount: 0
      }  
    };
    
    const getCraftItems = (items) => {
        return items.map(x => convertToCraftItem(x));
    };
    
    const onFilterHandle = () => {
        if (!search.fetch) {
            
            setSearch({
                ...search,
                fetch: true
            });
            
            setError('');

            if (search.query) {
                Api.post('/api/items/filterItemsByName', {query: search.query})
                    .then(res => {
                        const data = res.data;
                        const craftItems = data.items.length > 0 ? getCraftItems(data.items) : [];

                        setSearchResults({
                            ...searchResults,
                            results: craftItems,
                            count: data.count
                        });
                    })
                    .catch(err => {
                        setError(err.response.data.message);
                    })
                    .finally(() => {
                        setSearch({...search, fetch: false});
                    });
            }
            else {
                setSearch({...search, fetch: false});
                setSearchResults({
                    ...searchResults,
                    results: null,
                    count: 0
                });
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (search.query) onFilterHandle();
        }
    };

    useDebounce(onFilterHandle, 600, [ search.query ]);
    
    const [errorCraft, setErrorCraft] = useState('');
    const addItemToCraftHandle = (e, craftItem) => {
        e.preventDefault();
        
        if (craftItem.amount <= 0) {
            setErrorCraft('Укажите количество');
            return;
        }

        const findItem = data.craftItems.find(x => x.childId === craftItem.childId);

        if (findItem) {
            const temp = data.craftItems.map(x => {
                if (x.childId === craftItem.childId) {
                    x.amount = (x.amount - 0) + (craftItem.amount - 0);
                }
                return x;
            });

            setData({...data, craftItems: temp});
        }
        else {
            setData({...data, craftItems: [...data.craftItems, craftItem]});
        }
        
        setSearchResults({
            ...searchResults,
            results: searchResults.results.filter(x => x.childId !== craftItem.childId),
            count: searchResults.count - 1
        });
    };
    const removeItemFromCraftHandle = (e, craftItem) => {
        e.preventDefault();
        setData({...data, craftItems: data.craftItems.filter(x => x.childId !== craftItem.childId)});
        
        if (searchResults.results !== null) {
            setSearchResults({
                ...searchResults,
                results: [...searchResults.results, craftItem],
                count: searchResults.count + 1
            });
        }
    };
    
    const changeCraftItemAmount = (e, itemId) => {
        const items = searchResults.results.map(x => {
            if (x.childId === itemId) {
                x.amount = e.target.value;
            }
            return x;
        });

        errorCraft && setErrorCraft('');

        setSearchResults({...searchResults, results: items, count: items.length});
    };
    
    const calculatePrice = () => {
        let price = 0;
        if (data.craftItems.length > 0) {
            data.craftItems.map(x => {
                price = price + (x.child.price * x.amount);
            });
        }
        
        setData({...data, price: price});
    };
    
    useMemo(calculatePrice, [data.craftItems]);
    
    const validate = () => {
      
        if (data.item.name === '') {
            setError('Укажите наименование крафтовой позиции');
            return false;
        }
        
        if (data.craftItems.length === 0) {
            setError('Укажите состав крафтового изделия');
            return false;
        }
        
        return true;
    };
    
    const [fetch, setFetch] = useState(false);
    const onSaveHandle = () => {
        if (validate() && !fetch) {
            setFetch(true);
            setError('');
            
            Api.post('/api/stocks/addItem', data)
                .then(() => {
                    onHide();
                    callback();
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setFetch(false);
                });
        }
    };

    // Обработка полей формы для поставщика
    const onChangeSupplierHandle = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setData(prevState => ({ ...prevState, supplier: {...prevState.supplier, [name]: value} }));
    };

    // Change phoneNumber
    const onChangeSupplierPhone = (value) => {
        setData(prevState => ({ ...prevState, supplier: {...prevState.supplier, phone: value || ''} }));
    };

    // Get data from supplier autocomplete
    const updateSupplier = (selectedSupplier) => {
        setData(prevState => ({...prevState,
            supplier: {...prevState.supplier,
                id: selectedSupplier.id,
                name: selectedSupplier.label} }));
    };

    const [isNewSupplier, setIsNewSupplier] = useState(false);

    useMemo(()=>{
        if (data.supplier.name !== '') {
            setIsNewSupplier(data.supplier.id === '');
        }
        else {
            setIsNewSupplier(false);
        }
    }, [data.supplier]);
    
    return <>
        <button
            onClick={onShow}
            className={classname}>
            {label}
        </button>
        <Modal show={show} onHide={onHide} size={"xl"}>
            <Modal.Header closeButton>
                <h5>
                    {label}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="name">*Наименование:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                value={data.name}
                                onChange={onChangeNameHandle}
                            />
                        </div>
                        <div className="mb-3">
                            <label>*Поставщик:</label>
                            <Autocomplete
                                value={data.supplier.name}
                                callback={updateSupplier}
                                endpoint="/api/suggestions/suppliers"
                                inputClassName="form-control"/>
                        </div>
                        {
                            isNewSupplier &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="supplier_manager">Контактное лицо:</label>
                                    <input type="text"
                                           id="supplier_manager"
                                           className="form-control"
                                           name="manager"
                                           value={data.supplier.manager}
                                           onChange={onChangeSupplierHandle} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="supplier_phone">Телефон:</label>
                                    <PhoneInput type="text"
                                                international={true}
                                                withCountryCallingCode={true}
                                                defaultCountry="RU"
                                                id="supplier_phone"
                                                className="form-control"
                                                name="phone"
                                                value={data.supplier.phone}
                                                onChange={onChangeSupplierPhone}
                                                placeholder="+7 ___ __ __"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="supplier_address">Адрес:</label>
                                    <textarea
                                        id="supplier_address"
                                        className="form-control"
                                        name="address"
                                        value={data.supplier.address}
                                        onChange={onChangeSupplierHandle} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="supplier_url">Сайт:</label>
                                    <input type="text"
                                           id="supplier_url"
                                           className="form-control"
                                           name="url"
                                           value={data.supplier.url}
                                           onChange={onChangeSupplierHandle} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="supplier_note">Дополнительная информация по поставщику:</label>
                                    <textarea
                                        id="supplier_note"
                                        className="form-control"
                                        name="note"
                                        value={data.supplier.note}
                                        onChange={onChangeSupplierHandle} />
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5>Состав изделия</h5>
                        {
                            data.craftItems.length === 0 ?
                                <div className="text-muted py-3">
                                    Состав крафтовой позиции пустой
                                </div>
                                :
                                <div className="table-responsive-x mb-4">
                                    <table className="table table-bordered table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Наименование:</th>
                                                <th>Кол-во:</th>
                                                <th/>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.craftItems.map((x, i) =>
                                                <tr key={i}>
                                                    <td className="text-center">
                                                        {x.child.itemNumber}
                                                    </td>
                                                    <td>
                                                        {x.child.name}
                                                        {
                                                            x.child.supplier &&
                                                            <div className="small text-muted">
                                                                Поставщик: {x.child.supplier.name}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {x.amount} {getShortNameAmountType(x.child.amountType)}
                                                    </td>
                                                    <td className="text-center">
                                                        <a href="#"
                                                           className="btn btn-sm btn-danger small"
                                                           onClick={e => removeItemFromCraftHandle(e, x)}>
                                                            Убрать
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                        }
                        
                        {
                            searchResults.count === 0 &&
                                <div className="text-muted py-3">
                                    Добавьте позиции, из которых состоит изделие
                                </div>
                        }
                        
                        <div className="mb-3 d-flex align-items-center">
                            <input
                                id="search_item4craft"
                                className="form-control flex-grow-1"
                                type="search"
                                placeholder="Найти по названию или номеру"
                                onChange={onSearchHandle}
                                onKeyDown={handleKeyDown}
                                value={search.query}
                            />
                        </div>

                        {
                            search.fetch &&
                            <div className="mb-3">
                                <FetchState loading={search.fetch} label="поиск позиций" size="sm" />
                            </div>
                        }
                        {
                            errorCraft &&
                            <div>
                                <Alert variant="danger">{errorCraft}</Alert>
                            </div>
                        }
                        {
                            searchResults.results !== null &&
                            (
                                searchResults.results.length > 0 ?
                                    <div>
                                        <div className="text-strong mb-2">
                                            Найденые позиции {`(${searchResults.count} шт.)`}:
                                        </div>
                                        <div className="table-responsive-x" style={{maxHeight: 260, overflowY: 'auto'}}>
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Наименование:</th>
                                                        <th>Кол-во:</th>
                                                        <th/>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    searchResults.results.map((itemResult) => {
                                                        return (
                                                            <tr key={itemResult.childId}>
                                                                <td className="text-center">
                                                                    {itemResult.child.itemNumber}
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        {itemResult.child.name}
                                                                    </div>
                                                                    {
                                                                        itemResult.child.supplier &&
                                                                        <div className="small text-muted">
                                                                            Поставщик: {itemResult.child.supplier.name}
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <input
                                                                            className="form-control"
                                                                            type="number"
                                                                            style={{width: 120}}
                                                                            value={itemResult.amount}
                                                                            onChange={e => changeCraftItemAmount(e, itemResult.childId)}
                                                                        />
                                                                        <span className="ms-2">
                                                                            {getShortNameAmountType(itemResult.child.amountType)}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <a href="#"
                                                                       className="btn btn-sm small btn-dark"
                                                                       onClick={e => addItemToCraftHandle(e, itemResult)}>
                                                                        Добавить
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    search.query &&
                                    <div className="text-center">
                                        <Alert variant="info">
                                            По запросу <b>"{search.query}"</b> ничего больше не найдено
                                        </Alert>
                                    </div>
                            )
                        }
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-body">
                        <h5>Параметры позиции на складе</h5>
                        <div className="d-md-flex mt-3">
                            <div className="mb-3">
                                <label htmlFor="amount">*На балансе:</label>
                                <input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    className="form-control"
                                    value={data.amount}
                                    onChange={onChangeHandle}
                                />
                            </div>
                            <div className="mb-3 ms-0 ms-md-2">
                                <label htmlFor="amountType">*Ед. измерения:</label>
                                <select name="amountType"
                                        className="form-control"
                                        id="amountType"
                                        value={data.amountType}
                                        onChange={onChangeHandle}>
                                    {
                                        amountTypes.map(type =>
                                            <option key={type.key} value={type.key}>{type.label} ({type.short})</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="minAmount">Минимальный запас:</label>
                            <input
                                type="number"
                                id="minAmount"
                                name="minAmount"
                                className="form-control"
                                value={data.minAmount}
                                onChange={onChangeHandle}
                            />
                            <div className="form-text text-muted small">
                                Количество позиции, которое всегда должно быть на складе.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Стоимость: {formatCurrency(data.price)}</label>
                            <div className="text-muted">
                                Стоимость расчитывается автоматически на основе позиций используемых в крафтовом изделии
                            </div>
                        </div>
                    </div>
                </div>
                {
                    error && <Alert variant="danger">{error}</Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={onSaveHandle}
                    disabled={fetch}
                    className="btn btn-primary">
                    {
                        fetch ? <FetchState loading={fetch} label="Сохранение" size="sm" variant="light" />
                            : 'Сохранить'
                    }
                </button>
                <button
                    onClick={onHide}
                    className="btn btn-dark">
                    Отмена
                </button>
            </Modal.Footer>
        </Modal>
    </>
}