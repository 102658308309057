import React, {useState, useEffect} from "react";
import Api from "../../helpers/axios";
import {Modal, Alert} from "react-bootstrap";
import FetchState from "../ui/fetchState";
import GetResponseError from "../../helpers/getResponseError";

export const ExportProjectItemsData = ({project}) => {
    const [fetch, setFetch] = useState(false);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const onHide = () => {
        setShow(false);
    };
    const onShow = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const [filename, setFilename] = useState('');

    const onStartExport = () => {
        if (!fetch) {
            setFetch(true);
            setError("");
            setFilename("");

            Api.post("/api/projects/ExportToExcel", {id:project.id})
                .then(res => {
                    setFilename(res.data.body);
                })
                .catch(err => setError(GetResponseError(err)))
                .finally(() => {
                    setFetch(false);
                });
        }
    }
    
    return (
        <div className="text-center">
            <button onClick={onShow} className="btn btn-dark">
                Export в Excel (Позиции)
            </button>
            <Modal show={show} onHide={onHide} size={"lg"}>
                <Modal.Header closeButton>
                    <h5>Экспорт выданных позиции по проекту</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center pt-2 pb-3">
                        <div className="text-strong display-20 mb-3">
                            Проект: {project.name}
                        </div>
                        <div>
                            Запустите экспорт, чтобы получить ссылку на excel-файл с выданными позициями на проект.
                        </div>
                        <div className="mt-3">
                            <button
                                disabled={fetch}
                                onClick={onStartExport}
                                className="btn btn-primary">
                                {
                                    fetch
                                        ? <FetchState loading={true} size={"sm"} label={"Обработка данных"} variant={"light"} />
                                        : 'Запустить'
                                }
                            </button>
                            {
                                error &&
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                            }
                        </div>
                    </div>
                    {
                        filename &&
                        <div className="mt-3 text-center">
                            <hr/>
                            <a href={`/exports/${filename}`} className="btn btn-link" target="_blank">
                                Скачать Excel-файл
                            </a>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};

export default ExportProjectItemsData;