import React, {useRef, useState, useEffect} from 'react'
import {Spinner, Alert} from 'react-bootstrap'
import Api from '../../helpers/axios'
import userHaveRole from '../../helpers/userHaveRole'
import roles from "../../helpers/roles";

export default ({currentItem, callback}) => {
    const [item, setItem] = useState(currentItem);
    const [error, setError] = useState(null);
    const [upload, setUpload] = useState(false);
    const inputFile = useRef();
    const selectItemPhotosFiles = () => {
        inputFile.current.click();
    };

    const onUploadHandler = (event) => {
        const files = event.target.files;
        if (files.length === 0) {
            setError("Выберите хотя бы одино изображение");
            return;
        }
        if (!upload) {
            setUpload(true);
            setError(null);

            const formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                formData.append('files', event.target.files[i]);
            }

            formData.append('itemId', item.id);

            inputFile.current.value = null;

            Api.post('/api/items/UploadItemPhotos', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                    setItem(prevState => ({...prevState, itemPhotos: res.data.body}));
                })
                .catch(err => {
                    setError(err.response.data.message);
                })
                .finally(() => {
                    setUpload(false);
                })
        }
    }

    const [deleting, setDeleting] = useState(false);
    const [deletingPhotoId, setDeletingPhotoId] = useState(null);

    const onRemovePhoto = (event, photo) => {
        event.preventDefault();

        if (confirm('Уверены, что хотите удалить фотографию позиции?')) {
            if (!deleting) {
                setDeletingPhotoId(photo.id);
                setDeleting(true);

                Api.post('/api/items/DeletePhoto', photo)
                    .then(() => {
                        setItem(prevState => ({
                            ...prevState,
                            itemPhotos: prevState.itemPhotos.filter(x => x.id !== photo.id)
                        }));
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setDeletingPhotoId(null);
                        setDeleting(false);
                    });

            }
        }
    };

    const canEdit = roles.is(roles.purchaseDepartment)
        || roles.is(roles.storekeeper) || roles.is(roles.administrator);

    const [f, setF] = useState(false);
    useEffect(()=>{
        if(f) {
            callback(item);
        }
        else {
            setF(true);
        }
    }, [item]);
    
    return (
        canEdit &&
        <div className="form-group">
            <label>Фотографии:</label>
            <div className="itemPhotos d-flex flex-wrap justify-content-center">
                {
                    item.itemPhotos.length !== 0 &&
                    <>
                        {
                            item.itemPhotos.map(photo =>
                                <div key={photo.id} className="text-center mb-2 mr-2">
                                    <div
                                        style={{backgroundImage: `url(/upload/photo/${photo.fileName})`}}
                                        className="itemPhoto-mini"/>
                                    <div>
                                        {
                                            (deleting && photo.id === deletingPhotoId) ?
                                                <Spinner animation="border" size="sm" variant="warning"/>
                                                :
                                                <a
                                                    onClick={e => {
                                                        onRemovePhoto(e, photo)
                                                    }}
                                                    href="#">Удалить</a>
                                        }

                                    </div>
                                </div>
                            )
                        }
                    </>
                }
            </div>
            <div className="text-left">
                {
                    item.itemPhotos.length === 0 &&
                    <div className="text-muted">
                        Нет фотографии
                    </div>
                }
                <div className="mt-3">
                    <button
                        onClick={selectItemPhotosFiles}
                        className="btn btn-sm btn-dark">
                        {
                            upload ?
                                <div className="d-flex align-items-center">
                                    <Spinner animation="border" size="sm" variant="light"/>
                                    <span className="ml-2">Загрузка</span>
                                </div>
                                :
                                'Добавить фото'
                        }
                    </button>
                    <input
                        style={{display: 'none'}}
                        multiple={true}
                        accept="image/*"
                        type="file"
                        onChange={onUploadHandler}
                        ref={inputFile}/>
                </div>

                {
                    error && <Alert variant="danger">{error}</Alert>
                }
            </div>
        </div>
    )
}
