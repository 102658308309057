import React, {useState, useMemo} from "react";
import {useSelector, useDispatch} from 'react-redux';

import {
    addToProdRequestAction,
    removeFromProdRequestAction,
    changeAmountItemProdRequestAction
} from '../../../store/request/actions';

import InputNumber from "../../ui/inputNumber";

export default (props) => {
    const prodReqState = useSelector(state => state.productionRequest);
    const dispatch = useDispatch();
    const product = props.product || null;
    const stock = props.stock || null;
    const amountType = props.amountType || 0;
    const amount = props.amount || null;
    const [visibleControl, setVisibleControl] = useState(false);
    const [productAdded, setProductAdded] = useState(null);
    const newProduct = {
        stock: stock,
        product: product,
        availableAmount: amount,
        amount: 1,
        amountType: amountType
    }

    const setControlIsVisibleHandle = (e) => {
        e.preventDefault();
        setVisibleControl(true);
        // add to store
        dispatch(addToProdRequestAction(newProduct));
        setProductAdded(newProduct);
    };

    const onChangeAmountHandle = (val) => {
        if (val <= 0) {
            // remove from store
            dispatch(removeFromProdRequestAction({
                productId: productAdded.product.id,
                stockId: stock.id
            }));
            setVisibleControl(false);
            return;
        }

        setProductAdded(prevState => ({...prevState, amount: val}));

        // change amount from store
        dispatch(changeAmountItemProdRequestAction({
            id: productAdded.product.id,
            stockId: productAdded.stock.id,
            value: val
        }));
    }

    useMemo(() => {
        if (prodReqState.products.length) {

            const findProduct = prodReqState.products.find(x =>
                x.product.id === product.id && x.stock.id === stock.id);

            if (findProduct) {
                setProductAdded(findProduct);
                setVisibleControl(true);
            }
        }
    }, []);


    return !visibleControl ?
        <button
           onClick={setControlIsVisibleHandle}
           className="btn btn-sm btn-primary">
            Запросить со&nbsp;склада
        </button>
        :
        <div>
            <InputNumber
                label={'Запросить со склада'}
                value={productAdded.amount}
                onChange={onChangeAmountHandle}
                min={0}
                max={newProduct.availableAmount}
            />
        </div>
}