import React, {useState, useMemo, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import AddProductionRequestButton from "../../components/buttons/addProductionRequest"
import {Link} from "react-router-dom"
import {Alert, Spinner} from "react-bootstrap"
import {removeProdRequest, changeProjectRequestAction} from "../../store/request/actions"
import Api from '../../helpers/axios'
import {getLabelAmountType} from "../../helpers/amountTypes"
import ItemPhotos from "../../components/itemPhotos";


export default () => {
    const requestState = useSelector(state => state.productionRequest)
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)

    const cancelRequestHandle = () => {
        if (confirm('Запрос позиций будет полность удален. Продолжить?')) {
            dispatch(removeProdRequest())
        }
    }

    const onCreateHandle = () => {
        if (!creating) {
            setCreating(true)
            setError(null)

            Api.post('/api/requestOrders/create', requestState)
                .then(() => {
                    setSuccess("Заявка успешно отправлена")
                    dispatch(removeProdRequest())
                    setTimeout(()=>{
                        setSuccess(null);
                    }, 5000)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setCreating(false);
                })

        }
    }
    
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(false)
    const getProjects = () => {
        if (!loading) {
            setLoading(true)
            setError(null)
            
            Api.post('/api/projects/GetAllActive', {})
                .then(res => {
                    setProjects(res.data.body)
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    
    const onSelectProject = (e) => {
        dispatch(changeProjectRequestAction(e.target.value))
    }

    useMemo(() => {
        setIsDisabled(requestState.projectId === '')
    }, [requestState.projectId])

    useEffect(() => {
        getProjects()
    }, [])
    

    return <div className="container">
        <h2>
            Заявка на выдачу
        </h2>
        <div className="mt-3">
            {
                success && <Alert variant="success">{success}</Alert>
            }
            {
                requestState.products.length ?
                    <>
                        <h5>
                            Список позиций
                        </h5>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered">
                                <caption>
                                    Всего позиций: {requestState.products.length}
                                </caption>
                                <thead>
                                <tr>
                                    <th>
                                        Наименование:
                                    </th>
                                    <th>
                                        Склад:
                                    </th>
                                    <th className="text-center">
                                        Количество:
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    requestState.products.map(item =>
                                        <tr key={`${item.product.id}_${item.stock.id}`}>
                                            <td>
                                                {item.product.name}
                                                <div>
                                                    <ItemPhotos
                                                        itemPhotos={item.product.itemPhotos}
                                                        caption={item.product.name} />
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    item.stock ? <>
                                                            <div>
                                                                <Link to={`/stock/${item.stock.id}`}>
                                                                    <strong>
                                                                        {item.stock.name}
                                                                    </strong>
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                {item.stock.address}
                                                            </div>
                                                        </>
                                                        : <>&mdash;</>
                                                }
                                            </td>
                                            <td>
                                                <div className="d-flex w-100 justify-content-center">
                                                    <AddProductionRequestButton
                                                        amountType={item.amountType}
                                                        amount={item.availableAmount}
                                                        product={item.product}
                                                        stock={item.stock} />
                                                </div>
                                                <div className="mt-2 small text-center">
                                                    Ед. изм.: {getLabelAmountType(item.product.amountType)}
                                                </div>
                                            </td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                        </div>

                        {
                            loading ?
                                <div className="d-flex align-items-center">
                                    <Spinner animation="border" size="sm" />
                                    <span className="ml-2">Загрузка списка проектов</span>
                                </div>
                                :
                                <div className="form-group">
                                    <label htmlFor="project-name">
                                        Проект:
                                    </label>
                                    <select
                                        name="projectId"
                                        id="projects-list"
                                        className="form-control"
                                        onChange={onSelectProject}
                                        value={requestState.projectId}>
                                        <option value={''}>Выберите проект</option>
                                        {
                                            projects.map(project =>
                                                <option
                                                    key={project.id}
                                                    value={project.id}>
                                                    {project.name}
                                                </option>)
                                        }
                                    </select>
                                </div>
                        }
                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }
                        <Alert variant="warning">
                            Внимательно проверьте сформированную заявку:
                            <ol className="mt-1">
                                <li>позиции на выдачу и их количество,</li>
                                <li>выберите правильный проект из выпадающего списка.</li>
                            </ol>
                        </Alert>
                        {
                            (!loading && projects.length > 0) ?
                                <div className="mt-3 d-flex w-100 align-items-center pb-3">
                                    <button disabled={isDisabled}
                                            onClick={onCreateHandle}
                                            className="btn btn-primary">
                                        {
                                            creating ?
                                                <div className="d-flex align-items-center">
                                                    <Spinner animation="border" variant="light" size="sm" />
                                                    <span className="ms-2">
                                                    Отправляем
                                                </span>
                                                </div>
                                                :
                                                <>Отправить заявку</>
                                        }
                                    </button>

                                    {
                                        !creating &&
                                        <button className="btn btn-danger ms-2" onClick={cancelRequestHandle}>Отменить заявку</button>
                                    }
                                </div>
                                :
                                <div>
                                    Нет активных проектов, создать заявку нельзя
                                </div>
                        }
                    </>
                    :
                    <div className="text-center p-5 text-muted">
                        <p>
                            Не выбраны позиции для оформления новой завки на выдачу позиций со склада.
                        </p>
                        <Link to="/requests" className="btn btn-sm btn-primary">Выдача позиций</Link>
                    </div>
            }
        </div>
    </div>
}