import {TOGGLE_SHOW_MENU} from './actions'

const initialState = {
    show: false
}

export const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SHOW_MENU:
            return {
                ...state,
                show: action.show
            }
        default:
            return state
    }
}

export default menuReducer