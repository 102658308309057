import React from 'react'
import {ReactComponent as RemoveIcon} from './remove.svg';

export default ({tag, canRemove, removeCallback}) => {
    canRemove = canRemove || false
    
    const onRemoveHandle = () => {
        removeCallback(tag)
    }
    
    return (
        <div className={`tag ${canRemove ? 'tag-canRemove' : ''}`} style={{backgroundColor: tag.color}}>
            <div className="tag-title">
                {tag.title}
            </div>
            {
                canRemove &&
                    <div
                        onClick={onRemoveHandle}
                        className="tag-remove-btn">
                        <RemoveIcon/>
                    </div>
            }
        </div>
    )
}