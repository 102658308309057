import React, {useState, useEffect, useMemo} from 'react';
import Api from '../../../helpers/axios';
import {Spinner, Alert} from 'react-bootstrap';
import userHaveRole from '../../../helpers/userHaveRole';
import CreateBtn from '../../../components/buttons/createFlow';
import {getLabelFlowType} from "../../../helpers/flowType";
import {format} from "date-fns-tz";
import Pagination from '../../../components/pagination';
import EditFlow from '../../../components/buttons/editFlow';
import {ReactComponent as DeleteIcon} from "./remove.svg";
import {ReactComponent as DownIcon} from './arrow_down.svg';
import {ReactComponent as UpIcon} from './arrow_up.svg';
import DatePicker from "react-datepicker";
import {registerLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import {Link, useParams} from "react-router-dom";
import Tag from '../../../components/flowTag';
import SelectTags from '../../../components/flowTags';
import FetchState from "../../../components/ui/fetchState";

registerLocale('ru', ru);

export default () => {
    const {id} = useParams();

    const [option, setOption] = useState({
        bankAccountId: id,
        currentPage: 1,
        onPage: 50,
        desc: true,
        sortColumn: 'BillDate',
        startDate: null,
        endDate: null,
        tags: [],
        counterparty: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const isHasAccess = userHaveRole("Administrator") || userHaveRole("Financial Department");
    const [flows, setFlows] = useState([]);
    const [count, setCount] = useState(0);
    const [bank, setBank] = useState({
        name: '...',
        balance: 0
    });
    const [incomingSum, setIncomingSum] = useState(0);
    const [consumptionSum, setConsumptionSum] = useState(0);
    const [debetSum, setDebetSum] = useState(0);
    const [managers, setManagers] = useState([]);

    const setSortColumn = (e, column) => {
        e.preventDefault();
        const desc = option.sortColumn !== column ? false : !option.desc;
        setOption(prevState => ({...prevState,
            sortColumn: column,
            desc: desc,
            currentPage: 1
        }));
    };

    const getItems = () => {
        if (!loading) {
            setLoading(true);
            setError(null);

            Api.post('/api/flow/perPage', option)
                .then(res => {
                    setBank(res.data.bankAccount);
                    setFlows(res.data.flows);
                    setCount(res.data.count);
                    setIncomingSum(res.data.incomingSum);
                    setConsumptionSum(res.data.consumptionSum);
                    setDebetSum(res.data.debetSum);
                    setManagers(res.data.managers);
                })
                .catch(err => setError(err.status))
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const onDeleteHandle = (flow) => {
        setError(null);
        if (confirm('Уверены что хотите удалить эту запись?')) {

            Api.post('/api/flow/delete', flow)
                .then(() => {
                    getItems();
                })
                .catch(err => setError(err.response.data.message))
                .finally();
        }
    };

    const updatePagination = (data) => {
        setOption(prevState => ({...prevState, currentPage: data}));
    };

    const updateItemFlow = () => {
        getItems();
    };
    
    const onCounterpartyFiltring = (e) => {
        setOption(prevState => ({...prevState, counterparty: e.target.value}));
    };

    const onUpdateTags = (tags) => {
        setOption(prevState => ({...prevState, tags: tags}));
    };
    
    useEffect(() => {
        getItems();
    }, []);

    useMemo(() => {
        getItems();
    }, [option]);
    
    const [calculating, setCalculating] = useState(false);
    
    const recalculate = (e) => {
        e.preventDefault();
        
        if (confirm('Проверьте на тот ли счет вы запускаете перерасчет, все верно?')) {
            setCalculating(true);
            Api.post('/api/flow/ReCalculateBalance', {id: id})
                .then(() => {
                    getItems();
                })
                .catch(err => setError(err.response.data.message))
                .finally(() => {
                    setCalculating(false);
                });
        }
    };
    
    const [reseting, setReseting] = useState(false);
    const onReseting = (e) => {
        e.preventDefault();
        
        if (confirm('Уверены что хотите сбросить счет?')) {
             {
                 setReseting(true);
                 
                Api.post('/api/BankAccount/ResetAccount', {id: option.bankAccountId})
                    .then(() => {
                        getItems();
                    })
                    .catch(err => setError(err.response.data.message))
                    .finally(() => {
                        setReseting(false);
                    });
            }
        }
    }

    return (
        <div className="container">
            {
                isHasAccess ?
                    <>
                        <p>
                            <Link to={'/finances/accounts'}>Другие счета</Link>
                        </p>
                        <div className="d-md-flex align-items-center text-center text-md-left">
                            <h2>
                                {bank.name}
                            </h2>
                            <div className="ms-md-auto mt-2 mt-md-0">
                                <CreateBtn bankAccountId={option.bankAccountId} managers={managers} callback={getItems}/>
                            </div>
                        </div>

                        {
                            error && <Alert variant="danger">{error}</Alert>
                        }

                        <div className="text-muted mt-3 mt-md-0">
                            {
                                loading ?
                                    <div className="d-flex w-100 align-items-center">
                                        <Spinner animation="border" variant="warning" size="sm"/>
                                        <span className="text-muted ms-2">
                                                Подгрузка данных в таблицу
                                            </span>
                                    </div>
                                    :
                                    <>
                                        Данные по состоянию
                                        на {format(new Date(), 'H:mm, d MMMM yyyy', {locale: ru})}<br/>
                                        Записей за период: {count}
                                    </>
                            }
                        </div>

                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="d-md-flex justify-content-center w-100">
                                    <div className="account-info">
                                        <div className="account-info-title">
                                            Баланс:
                                        </div>
                                        {
                                            (!loading) ?
                                            <div className={`account-info-value ${bank.balance < 0 && 'text-danger'}`}>
                                                {
                                                    new Intl.NumberFormat('ru-RU', {
                                                        style: 'currency',
                                                        currency: 'RUB'
                                                    }).format(bank.balance)
                                                }
                                            </div> : <Spinner animation="border" variant="warning" size="sm"/>
                                        }
                                    </div>
                                    <div className="account-info">
                                        <div className="account-info-title">
                                            Расход:
                                        </div>
                                        <>
                                            {
                                                !loading ?
                                                    <div className="account-info-value">
                                                        {
                                                            new Intl.NumberFormat('ru-RU', {
                                                                style: 'currency',
                                                                currency: 'RUB'
                                                            }).format(consumptionSum)
                                                        }
                                                    </div> : <Spinner animation="border" variant="warning" size="sm"/>
                                            }
                                        </>
                                    </div>
                                    <div className="account-info">
                                        <div className="account-info-title">
                                            Приход:
                                        </div>
                                        <>
                                            {
                                                !loading ?
                                                    <div className="account-info-value">
                                                        {
                                                            new Intl.NumberFormat('ru-RU', {
                                                                style: 'currency',
                                                                currency: 'RUB'
                                                            }).format(incomingSum)
                                                        }
                                                    </div> : <Spinner animation="border" variant="warning" size="sm"/>
                                            }
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>              
                        
                        <div className="mt-3">
                            <div className="filters mt-3">
                                <h5>Фильтры</h5>
                                <div>
                                    <div className="filters-active">
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">Показать за период:&nbsp;</div>
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control"
                                                    selected={option.startDate}
                                                    onChange={(date) => setOption({...option, startDate: date})}
                                                    selectsStart
                                                    locale="ru"
                                                    dateFormat="d MMMM yyyy"
                                                    startDate={option.startDate}
                                                    endDate={option.endDate}
                                                />
                                                <span>&nbsp;-&nbsp;</span>
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control"
                                                    selected={option.endDate}
                                                    onChange={(date) => setOption({...option, endDate: date})}
                                                    selectsEnd
                                                    dateFormat="d MMMM yyyy"
                                                    locale="ru"
                                                    startDate={option.startDate}
                                                    endDate={option.endDate}
                                                    minDate={option.startDate}
                                                />
                                                <div className="me-2 ms-4">
                                                    По тегам:
                                                </div>
                                                <div className="flex-grow-1">
                                                    <SelectTags
                                                        tagsList={option.tags}
                                                        callback={onUpdateTags}/>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            option.counterparty &&
                                            <div className="mt-2">
                                                По контрагенту: {option.counterparty}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        <div>
                                            <a href="#"
                                               className="d-flex w-100 align-items-center sort-link"
                                               onClick={(event) => setSortColumn(event, 'BillDate')}>
                                        <span className="me-2 sort-link-label">
                                            Дата&nbsp;платежа:
                                        </span>
                                                {
                                                    option.sortColumn === 'BillDate' ? option.desc ? <DownIcon/> : <UpIcon/> : ''
                                                }
                                            </a>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="text-left">
                                            Контрагент:
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <a href="#"
                                               className="d-flex w-100 align-items-center sort-link"
                                               onClick={(event) => setSortColumn(event, 'Consumption')}>
                                        <span className="me-2 sort-link-label">
                                            {getLabelFlowType(1)}:
                                        </span>
                                                {
                                                    option.sortColumn === 'Consumption' ? option.desc ? <DownIcon/> : <UpIcon/> : ''
                                                }
                                            </a>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <a href="#"
                                               className="d-flex w-100 align-items-center sort-link"
                                               onClick={(event) => setSortColumn(event, 'Incoming')}>
                                        <span className="me-2 sort-link-label">
                                            {getLabelFlowType(0)}:
                                        </span>
                                                {
                                                    option.sortColumn === 'Incoming' ? option.desc ? <DownIcon/> : <UpIcon/> : ''
                                                }
                                            </a>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="text-left">
                                            Сальдо:
                                        </div>
                                    </th>
                                    <th/>
                                </tr>
                                <tr>
                                    <th/>
                                    <th>
                                        <input
                                            id="filter-counterparty"
                                            name="counterparty"
                                            className="form-control"
                                            placeholder="Найти по контрагенту"
                                            value={option.counterparty}
                                            onChange={onCounterpartyFiltring}
                                            type="text"/>
                                    </th>
                                    <th/>
                                    <th/>
                                    <th/>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    flows.map(flow =>
                                        <tr key={flow.id}>
                                            <td title={format(new Date(flow.billDate + 'Z'), 'в H:mm', {locale: ru})}>
                                                {format(new Date(flow.billDate + 'Z'), 'd MMMM yyyy', {locale: ru})}
                                            </td>
                                            <td>
                                                {
                                                    flow.organization
                                                        ? <>
                                                            <div className="pb-2 display-16">
                                                                {
                                                                    flow.organization
                                                                        ? flow.organization.fullName
                                                                        : <>Не указано</>
                                                                }
                                                            </div>
                                                            {
                                                                flow.contract &&
                                                                <div className="text-truncate">
                                                                    {
                                                                        flow.contract.name &&
                                                                        <div>
                                                                            Договор: {flow.contract.name}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        flow.contract.price !== 0 &&
                                                                        <div>
                                                                            На сумму: {
                                                                            new Intl.NumberFormat('ru-RU', {
                                                                                style: 'currency',
                                                                                currency: 'RUB'
                                                                            }).format(flow.contract.price)
                                                                        }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        flow.contract.files &&
                                                                        <>
                                                                            {
                                                                                flow.contract.files.map(file =>
                                                                                    <div key={file.id}>
                                                                                        <a href={`/upload/contracts/${file.filename}`} target="_blank">
                                                                                            {file.currentFilename}
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                            </>
                                                        : <>&mdash;</>
                                                }

                                                <div className="border-top mt-2">
                                                    <div className="text-strong py-2">
                                                        Описание платежа:
                                                    </div>
                                                    <div className={flow.invoiceFile ? "invoice-hasInvoice" : ""}>
                                                        {
                                                            flow.parsingNumber &&
                                                            <div className="text-muted small mb-1">
                                                                Синхронизировано с банковской выпиской
                                                            </div>
                                                        }
                                                        {flow.description}

                                                        {
                                                            flow.invoiceFile &&
                                                            <div className="mt-2">
                                                                <a href={`${process.env.PUBLIC_URL}/upload/invoices/${flow.invoiceFile}`}
                                                                   target="_blank"
                                                                   rel="noopener noreferrer">
                                                                    Счет №{flow.invoiceNumber} от {format(new Date(flow.invoiceDate), 'd MMMM yyyy г.', {locale: ru})}
                                                                </a>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mt-2 border-top pt-2">
                                                    {
                                                        flow.flowTags.length > 0 &&
                                                            <div className="d-flex flex-wrap w-100">
                                                                {
                                                                    flow.flowTags.map((item, index) =>
                                                                        <React.Fragment key={index}>
                                                                            <Tag tag={item.tags}
                                                                                 removeCallback={()=>{}}
                                                                                 canRemove={false} />
                                                                        </React.Fragment>)
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    flow.type === 1 ? new Intl.NumberFormat('ru-RU', {
                                                        style: 'currency',
                                                        currency: 'RUB'
                                                    }).format(flow.value) : <>&mdash;</>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    flow.type === 0 ? new Intl.NumberFormat('ru-RU', {
                                                        style: 'currency',
                                                        currency: 'RUB'
                                                    }).format(flow.value) : <>&mdash;</>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    new Intl.NumberFormat('ru-RU', {
                                                        style: 'currency',
                                                        currency: 'RUB'
                                                    }).format(flow.currentBalance)
                                                }
                                            </td>
                                            <td>
                                                <div className="d-flex w-100 justify-content-center">
                                                    <EditFlow
                                                        classNames={'btn btn-icon'}
                                                        flow={flow}
                                                        managers={managers}
                                                        callback={updateItemFlow}/>

                                                    <button
                                                        title="Удалить запись"
                                                        onClick={() => onDeleteHandle(flow)}
                                                        className="btn btn-icon btn-icon--danger ms-2">
                                                        <DeleteIcon/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>

                      
                        {
                            !loading &&
                            <Pagination
                                callback={updatePagination}
                                currentPage={option.currentPage}
                                onPage={option.onPage}
                                count={count}
                            />
                        }
                    </>
                    :
                    <div>
                        <Alert variant="danger">Нет прав на просмотр данного раздела</Alert>
                    </div>
            }
            {
                (userHaveRole("Administrator") || userHaveRole("Financial Department")) &&
                <div className="mt-3 d-flex justify-content-end">
                    <div>
                        {
                            calculating ?
                                <FetchState loading={calculating} label="Перерасчет" />
                                :
                                <a href="#" onClick={recalculate}>Пересчитать данные</a>
                        }
                    </div>
                    {
                        userHaveRole("Administrator") &&
                        <div className="ms-3">
                            {
                                reseting ?
                                    <FetchState loading={reseting} label="Сброс и удаление данных" />
                                    :
                                    <a href="#" className="text-danger" onClick={onReseting}>Сбросить счет и удалить данные</a>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}