import React, {useState, useEffect} from 'react'
import {Modal, Alert, Spinner} from 'react-bootstrap'
import Api from '../../../helpers/axios'

export default ({
                    project, 
                    callback, 
                    classNameBtn = 'btn btn-primary', 
                    btnLabel = 'Изменить проект'}) => {
    
    const [data, setData] = useState({
        id: project.id,
        name: project.name,
        description: project.description
    })
    const [show, setShow] = useState(false)
    const onShowHandle = (e) => {
        e.preventDefault()
        setShow(true)
    }
    const onHideHandle = () => {
        setError(null)
        setShow(false)
    }
    
    useEffect(() => {
        setData({...data, ...project})
    }, [project])
    

    const onChangeHandle = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;
        setData(prevState => ({...prevState, [name]: value}));
    }

    const validate = () => {
        if (data.name === '') {
            setError('Укажите наименование проекта')
            return false
        }
        if (data.description === '') {
            setError('Укажите описание проекта')
            return false
        }

        return true
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSaveHandle = () => {
        if (!loading && validate()) {
            setLoading(true)
            setError(null)

            Api.post('/api/projects/update', data)
                .then(() => {
                    onHideHandle()

                    if (typeof callback === "function") {
                        callback()
                    }

                })
                .catch(err => {
                    setError(err.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div>
            <a href={'#'}
                onClick={e => onShowHandle(e)}
                className={classNameBtn}>{btnLabel}
            </a>

            <Modal show={show} onHide={onHideHandle}>
                <Modal.Body>
                    <form onSubmit={e => {
                        e.preventDefault()
                    }}>
                        <div className="card-body">
                            <h5 className="mb-3">
                                {btnLabel}
                            </h5>
                            <div className="text-muted small mb-3">
                                * - Обязательные поля для заполнения<br/>
                                Дополнительную информацию о проекте вы можете изменить в карточке проекта
                            </div>

                            <div className="form-group">
                                <label htmlFor="project-name">*Наименование проекта:</label>
                                <input
                                    id="project-name"
                                    className="form-control"
                                    name="name"
                                    value={data.name}
                                    onChange={onChangeHandle}
                                    type="text"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="project-name">*Описание проекта:</label>
                                <textarea
                                    id="project-description"
                                    className="form-control"
                                    name="description"
                                    value={data.description}
                                    onChange={onChangeHandle}
                                />
                            </div>

                            {
                                error && <Alert variant="danger">{error}</Alert>
                            }

                            <div>
                                <button
                                    disabled={loading}
                                    onClick={onSaveHandle}
                                    className="btn btn-primary">
                                    {
                                        loading ?
                                            <div className="d-flex align-items-center">
                                                <Spinner animation="border" size="sm" variant="light"/>
                                                <span className="ml-2">Сохранение</span>
                                            </div>
                                            :
                                            'Сохранить'
                                    }
                                </button>
                                {
                                    !loading &&
                                    <button
                                        onClick={onHideHandle}
                                        className="btn btn-dark ml-2">Отмена</button>
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}