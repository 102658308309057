import React, {useEffect, useMemo, useState} from "react";
import Api from '../../helpers/axios';
import AddSupplierButton from '../../components/buttons/addSupplier';
import {Alert, Spinner} from "react-bootstrap";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import Pagination from "../../components/pagination";
import EditSupplier from "../../components/buttons/editSupplier";
import useDebounce from '../../helpers/hooks/useDebounce';
import FetchState from "../../components/ui/fetchState";
import roles from "../../helpers/roles";
import {ExcelReport} from "./components/excelReport";
import GetResponseError from "../../helpers/getResponseError";

export default () => {
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [listOption, setListOption] = useState({
        page: 1,
        onPage: 30,
        query: null
    });
    const isAdmin = roles.is(roles.administrator);
    const isPurchaseDepartment = roles.is(roles.purchaseDepartment);
    const isStoreKeeper = roles.is(roles.storekeeper);
    
    const canEditSupplier = isAdmin || isPurchaseDepartment;
    const canAddSupplier = canEditSupplier;

    const getItems = () => {
        if (!loading) {
            setLoading(true);
            setError(null);

            // Call api
            Api.post('/api/suppliers/perPage', listOption)
                .then(res => {
                    setItems(res.data.suppliers);
                    setCount(res.data.count);
                })
                .catch(err => {
                    setError(err.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const refreshTable = () => getItems();

    const updatePagination = (data) => {
        setListOption(prevState => ({...prevState, page: data}));
    };

    const updateOnPage = (event, data) => {
        event.preventDefault();
        setListOption(prevState => ({...prevState, page: 1, onPage: data}));
    };

    const [projects, setProjects] = useState([]);
    const onGetProjects = () => {
        Api.post('/api/projects/getAll', {})
            .then(res => {
                setProjects(res.data.body)
            })
            .catch(err => GetResponseError(err))
            .finally();
    }

    useEffect(() => {
        getItems();
        onGetProjects();
    }, []);

    useMemo(() => {
        getItems();
    }, [listOption.page, listOption.onPage]);

    const onUpdateSupplier = (supp) => {
        const data = items.map(item => {
            if (item.id === supp.id) {
                item = supp
            }
            return item
        })

        setItems(data)
    }
    
    const [deleting, setDeleting] = useState(false)
    const onDeleteSupplier = (id) => {
        if (confirm('Вы уверены, что хотите удалить этого поставщика?')) {
            if (!deleting) {
                setDeleting(true);
                setError(null);
                
                Api.post('/api/suppliers/delete', {id: id})
                    .then(() => {
                        getItems()
                    })
                    .catch(err => {
                        setError(err.message);
                    })
                    .finally(() => {
                        setDeleting(false);
                    });
            }
        }
    }
    
    const onFilterHandle = (e) => {setListOption({...listOption, query: e.target.value})}
    
    const onFilter = () => {
        if (listOption.query !== '') {
            if (!loading) {
                setLoading(true)
                setError(null)

                Api.post('/api/suppliers/filterItemsByName', {query: listOption.query})
                    .then(res => {
                        setItems(res.data.suppliers);
                        setCount(res.data.count);
                    })
                    .catch(err => {
                        setError(err.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            }
        }
        else {
            getItems()
        }
    }

    useDebounce(() => onFilter(), 1000, [listOption.query])

    return (
        <>
            <div className='container'>
                <div className='d-md-flex align-items-start w-100'>
                    <div>
                        <h2>
                            Поставщики
                        </h2>
                    </div>
                    {
                        canAddSupplier &&
                        <div className='ms-md-auto'>
                            <AddSupplierButton callback={refreshTable} />
                        </div>
                    }
                </div>
                <div className="mt-3">
                    {
                        error && <Alert variant="danger">{error}</Alert>
                    }

                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <caption>
                                {
                                    loading ?
                                        <div className="d-flex w-100 align-items-center">
                                            <Spinner animation="border" variant="warning" size="sm"/>
                                            <span className="text-muted ms-2">
                                        Подгрузка данных в таблицу
                                    </span>
                                        </div>
                                        :
                                        <>
                                            Данные по состоянию на {format(new Date(), 'H:mm, d MMMM yyyy', { locale: ru })}<br/>
                                        </>
                                }
                                Всего поставщиков: {count}
                                {
                                    deleting && <FetchState
                                        loading={deleting}
                                        label="Удаление поставщика"
                                        size="sm"
                                        variant="danger" />
                                }
                            </caption>
                            <thead>
                                <tr>
                                    <th>
                                        Наименование:
                                    </th>
                                    <th>
                                        Бренд:
                                    </th>
                                    <th>
                                        Контактное лицо:
                                    </th>
                                    <th>
                                        Телефон:
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <input
                                            className="form-control"
                                            placeholder="Найти по наименованию"
                                            onChange={onFilterHandle}
                                            value={listOption.query}
                                            type="text" />
                                    </th>
                                    <th/>
                                    <th/>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                items.map(item => <tr key={item.id}>
                                    <td>
                                        <h5>
                                            {item.name}
                                        </h5>
                                        <div className="mb-2">
                                            {item.address && <div>Адрес: {item.address}</div>}
                                            {item.url && <div className="mt-1">Сайт: <a href={item.url} target="_blank">{item.url}</a></div>}
                                            {item.note && <div className="mt-3 text-muted"><strong>Примечание:</strong> {item.note}</div>}
                                        </div>
                                        <div className="d-flex">
                                            {
                                                canEditSupplier &&
                                                <div>
                                                    <EditSupplier
                                                        supplier={item}
                                                        callback={onUpdateSupplier}
                                                    />
                                                    <button
                                                        onClick={() => onDeleteSupplier(item.id)}
                                                        className="btn btn-sm btn-outline-danger ms-2">Удалить</button>
                                                </div>
                                            }
                                            {
                                                (isAdmin || isPurchaseDepartment || isStoreKeeper) &&
                                                <div className="ms-auto">
                                                    <ExcelReport supplier={item} projects={projects} />
                                                </div>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        {item.brand ? <>{item.brand}</> : <>&mdash;</>}
                                    </td>
                                    <td>
                                        {item.manager ? <>{item.manager}</> : <>&mdash;</>}
                                    </td>
                                    <td>
                                        {item.phone ? <>{item.phone}</> : <>&mdash;</>}
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </div>

                    {
                        !loading &&
                        <>
                            <Pagination
                                callback={updatePagination}
                                currentPage={listOption.page}
                                onPage={listOption.onPage}
                                count={count}/>
                        </>
                    }


                    <div className="d-md-flex align-items-md-center mt-3 pb-5 text-center text-md-left">
                        <div className="me-md-1 mt-2 mt-md-0">
                            Отображать по:
                        </div>
                        <div>
                            <a className={`onPage-link ${listOption.onPage === 10 ? 'onPage-link--active' : ''}`}
                               onClick={(e) => updateOnPage(e, 10)}>10</a>
                            <a className={`onPage-link ${listOption.onPage === 30 ? 'onPage-link--active' : ''}`}
                               onClick={(e) => updateOnPage(e, 30)}>30</a>
                            <a className={`onPage-link ${listOption.onPage === 50 ? 'onPage-link--active' : ''}`}
                               onClick={(e) => updateOnPage(e, 50)}>50</a>
                            <a className={`onPage-link ${listOption.onPage === 100 ? 'onPage-link--active' : ''}`}
                               onClick={(e) => updateOnPage(e, 100)}>100</a>
                            <a className={`onPage-link ${listOption.onPage === 150 ? 'onPage-link--active' : ''}`}
                               onClick={(e) => updateOnPage(e, 150)}>150</a>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}